import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Tooltip, Skeleton, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin, Avatar   } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import sys from '../system'

import UserModel from './../models/User'

const Component = (props) => {

  const [person, setPerson] = useState()
  useAsyncEffect(async () => {
    const user = await UserModel.get(props.id);
    if (user != null) {
      setPerson(await user.person)
    }
  }, []);

  return (
    <div 
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
        padding: 4
      }}
    >
      {
        person &&
          <Avatar
            style={{
              backgroundColor: sys.getUser()?.id == props.id ? '#1677ff' : undefined,
            }}
            size={props.size}
          >
            {person?.initials}
          </Avatar>
      }
      { 
        props.showName 
        ? <div>
        {person?.display_name}
        </div>
        : null
      }
      {props.children}
    </div>
  )
}
export default Component;