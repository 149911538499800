import sys, { type } from '../system';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime, Boolean } = type;





export default Table({
    name: 'Simulation',
    table: 'simulation',
    columns: {
        name: Text({
            onChange: (value, row) => {
                if (value != null) {
                    value = value.replace(/[^a-z0-9\-\._]/ig,'');
                }
                return value;
            }
        }),
        description: Text(),
        created_at: DateTime({
            defaultsTo: () => { return sys.now() }
        }),
        start: DateTime(),
        finish: DateTime(),
        process_id: Uuid({
            values: (row) => {
                return sys.orm({
                    select: `select id as value, name || ' - ' || description as label from process where status = 'ACTIVE'`
                })
            }
        }),
        process: Relation({
            to: 'Process',
            cardinality: '1',
            where: "id = $process_id"
        }),
        source_id: Uuid({
            values: (row) => {
                return sys.orm({
                    select: `select id as value, name || ' - ' || description as label from source`
                })
            }
        }),
        source: Relation({
            to: 'Source',
            cardinality: '1',
            where: "id = $source_id"
        }),
        status: Upper({
            values: ['ACTIVE', 'INACTIVE'],
            defaultsTo: () => { return 'ACTIVE' }
        }),
        state: Json({
            title: 'State',
            defaultsTo: () => {
                return ({});
            }
        }),
        real_mode: Boolean({
            title: 'Real Mode',
            defaultsTo: () => { return false }
        }),
        use_draft: Boolean({
            title: 'Real Mode',
            defaultsTo: () => { return false }
        }),
    }
})