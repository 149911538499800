import React from 'react';



export const stringToColor = (str) => {
  if (str == null) {
    return 'gray'
  }
  str = str.toUpperCase()
  if (str == 'UNDEFINED') {
    return "#ff0000";
  }
  if (str === 'COCO') {
    return "#0000ff";
  }
  //color by strcode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}


export const toReactImageAnnotation = (annotation) => {
  if (annotation == null) {
    return annotation;
  }
  const a = {
    key: annotation.id,
    id: annotation.id,
    type: annotation.type, 
    cls: annotation.name,
    color: annotation.color || stringToColor(annotation.name), 
    locked: annotation.locked || false,
    visible: annotation.visible || true,
  }
  if (annotation.type === 'line') {
    a.x1 = annotation.points[0];
    a.x2 = annotation.points[1];
    a.y1 = annotation.points[2];
    a.y2 = annotation.points[3];
  } else if (annotation.type === 'point') {
    a.x = annotation.points[0];
    a.y = annotation.points[1];
  } else if (annotation.type === 'box') {
    a.x = annotation.points[0];
    a.y = annotation.points[1];
    a.w = annotation.points[2];
    a.h = annotation.points[3];
  } else if (annotation.type === 'polygon') {
    a.points = annotation.points;
  }
  return a
}

export const toReactImageAnnotations = (imageAnnotation) => {
  const annotations = [];
  if (imageAnnotation != null) {
    imageAnnotation.forEach((annotation) => {
      const a = toReactImageAnnotation(annotation)
      annotations.push(a);
    })
  }
  return annotations
}


export const fromReactImageAnnotations = (reactImageAnnotatorRegions) => {
  const regions = reactImageAnnotatorRegions;
  return regions.map(region => {
    let points;
    if (region.type === 'line') {
      points = [region.x1, region.x2, region.y1, region.y2]
    } else if (region.type === 'point') {
      points = [region.x, region.y]
    } else if (region.type === 'box') {
      points = [region.x, region.y, region.w, region.h]
    } else if (region.type === 'polygon') {
      points = region.points;
    }
    return {
      id: region.id,
      type: region.type,
      name: region.cls || 'UNDEFINED',
      locked: region.locked || false,
      visible: region.visible || true,
      points
    };
  })
}

export const toSvgBoundingBox = async (image, geometry, options = {}) => {
  const nodes = [];
  geometry.forEach((geo) => {
    if (geo.type === 'box') {
      if (options.showBoxes !== true) {
        return;
      }
      const points = geo.points;
      const name = geo.name;
      let confidence = geo.confidence;
      if (geo.confidence) {
        confidence = (confidence * 100).toFixed(0);
      }
      let x = points[0] * image.width;
      let y = points[1] * image.height;
      let w = points[2] * image.width;
      let h = points[3] * image.height;
      nodes.push(
        <g key={'b-box-'+geo.id}>
          <title
            key={'b-label-'+geo.id}
          >
            {name} - {confidence}
          </title>
          <rect 
            key={'box-'+geo.id}
            x={`${x.toFixed(2)}`}
            y={`${y.toFixed(2)}`}
            width={`${w.toFixed(2)}`}
            height={`${h.toFixed(2)}`}
            fill={geo?.style?.fillColor || stringToColor(name) + '70'}
            stroke={geo?.style?.strokeColor || stringToColor(name)}
          />
          { 
            options.showLabels === true && name !== null
            ? <text
              key={'b-text-'+geo.id}
              x={`${(x+2).toFixed(2)}`}
              y={`${(y+12).toFixed(2)}`}
              fill='white'
            >
              {name}
            </text>
            : null
          }
          {
            options.showConfidence && confidence !== null ?
            <text
              key={'b-confidence-'+geo.id}
              x={`${(x+w-16).toFixed(2)}`}
              y={`${(y+h-2).toFixed(2)}`}
              fill='white'
            >
              {confidence}
            </text>
            : null
          }
        </g>
      )

    } else if (geo.type === 'polygon') {
      if (options.showPolygons !== true) {
        return;
      }
      const points = geo.points;
      const name = geo.name;
      const confidence = geo.confidence;

      let polyMinX, polyMinY, polyW, polyH;

      const poly = points.map(([x, y]) => {
        x = x * image.width;
        y = y * image.height;

        if (polyMinY === undefined || y < polyMinY) {
          polyMinY = y;
          if (polyMinX === undefined || x < polyMinX) {
            polyMinX = x;
          }
        }

        return `${x},${y}`
      }).join(' ')

      nodes.push(
        <g key={'p-polygon-'+geo.id}>
          <title
            key={'p-label-'+geo.id}
          >
            {geo.name}
          </title>
          <polygon
            key={'polygon-'+geo.id}
            points={poly}
            fill={geo?.style?.fillColor || stringToColor(name) + '70'}
            stroke={geo?.style?.strokeColor || stringToColor(name)}
          />
          { 
            options.showLabels === true && name !== null
            ? <text
              key={'b-text-'+geo.id}
              x={`${(polyMinX+2).toFixed(2)}`}
              y={`${(polyMinY+12).toFixed(2)}`}
              fill='white'
            >
              {name}
            </text>
            : null
          }
          {
            options.showConfidence && confidence !== null ?
            <text
              key={'b-confidence-'+geo.id}
              x={`${(polyMinX+polyW-16).toFixed(2)}`}
              y={`${(polyMinY+polyH-2).toFixed(2)}`}
              fill='white'
            >
              {confidence}
            </text>
            : null
          }
        </g>
      )
    }

 
    
  });
  return nodes;
}

const regions = {
  area: (a) => {
    if (a == null) {
      return NaN
    }
    return a.w * a.h;
  },
  intersection: (a, b) => {
    if (a == null || b == null) {
      return null;
    }
    const leftX   = Math.max(a.x, b.x);
    const topY    = Math.max(a.y, b.y);
    const rightX  = Math.min(a.x + a.w, b.x + b.w);
    const bottomY = Math.min(a.y + a.h, b.y + b.h);
    if (leftX < rightX && topY < bottomY) { 
      return ({
        x: leftX,
        y: topY,
        w: rightX - leftX, 
        h: bottomY - topY
      })
    }
    return null; // no intersection
  },
  intersectionPercentage: (a, b) => {
    if (a == null || b == null) {
      return 0.0;
    }
    const inter = regions.intersection(a, b);
    if (inter == null) {
      return 0.0;
    }
    const area = regions.area(a);
    const interArea = regions.area(b);
    return interArea / area;
  },
  similar: (a, b, errorX = 0, errorY = 0) => {
    if (a == b) {
      return true;
    }
    if (a == null) {
      return false;
    }
    if (b == null) {
      return false;
    }
    if (a.cls !== b.cls) {
      return false;
    }
    if (a.type !== b.type) {
      return false;
    }
    if (Math.abs(a.x - b.x) > errorX) {
      return false;
    }
    if (Math.abs(a.y - b.y) > errorY) {
      return false;
    }
    if (Math.abs(a.w - b.w) > errorX) {
      return false;
    }
    if (Math.abs(a.h - b.h) > errorY) {
      return false;
    }
    return true;
  },
  equals: (a, b) => {
    if (a == b) {
      return true;
    }
    if (a == null) {
      return false;
    }
    if (b == null) {
      return false;
    }
    return a.cls === b.cls && a.type === b.type && a.x === b.x && a.y === b.y && a.w === b.w && a.h === b.h;
  }
}

export default {
  regions,
  toSvgBoundingBox,
  stringToColor,
  fromReactImageAnnotations,
  toReactImageAnnotations,
  toReactImageAnnotation,
}