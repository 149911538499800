import sys, { type } from '../system.js';
const { Text, Integer, Table, Uuid, DateTime, Relation, Attribute, File } = type;

import Geometry from './Geometry.js';

export default Table({
    name: 'Image',
    table: 'image',
    columns: {
        src: File(),
        width: Integer(),
        height: Integer(),
        size: Integer(),
        source_id: Uuid(),
        source: Relation({
            to: 'Source',
            cardinality: '1',
            where: "id = $source_id"
        }),
        now: DateTime(),
        created_at: DateTime(),
        updated_at: DateTime(),
        geometry: Relation({
            to: 'Geometry',
            cardinality: '*',
            where: "image_id = $id"
        }),
        annotations: Relation({
            to: 'Annotation',
            cardinality: '*',
            where: "image_id = $id"
        }),
        tags: Relation({
            to: 'Tag',
            cardinality: '*',
            where: "ref_id = $id"
        }),
        getUrl: Attribute({
            persistent: false,
            onInitialize: (value, row) => {
                return (resize) => {
                    const url = sys.image.url(row.src, resize)
                    return url
                }
            }
        }),
        url: Text({
            persistent: false,
            onInitialize: (value, row) => {
                const url = sys.image.url(row.src)
                return url
            }
        }),
        toImage: Attribute({
            persistent: false,
            onInitialize: (value, row) => {
                return () => {
                    return new Promise(resolve => {
                        const image = new Image();
                        image.addEventListener('load', () => {
                            resolve(image);
                        });
                        image.src = row.url; 
                    });
                }
            }
        })
    },
    beforeSave: (row) => {
        row.updated_at = new Date();
    }
});
