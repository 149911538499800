import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu, Input, Collapse, Dropdown, Modal  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { EyeOutlined, SettingOutlined } from '@ant-design/icons'
import Uuid from './../../components/Uuid'

import './NodeCard.less'

const Component = (props) => {

  const [title, setTitle] = useState(props?.data?.title);
  const [open, setOpen] = useState(props.children && props.data?.open);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const items = props.settings || [];

  items.push({
    key: 'node-info',
    label: 'Details',
    onClick: () => {
      setDetailsModalOpen(true);
    }
  })
  items.push({
    key: 'node-duplicate',
    label: 'Duplicate',
    onClick: () => {
      props.duplicateNode(props.id);
    }
  })
  items.push({
    type: 'divider'
  })
  items.push({
    key: 'node-delete',
    danger: true,
    label: 'Delete',
    onClick: () => {
      props.deleteNode(props.id);
    }
  })

  let dependencies = 0
  for (let handle of props.handles) {
    if (handle.props?.accepts?.length > 0) {
      dependencies++;
    }
  }

  return (
    props.isRef 
    ? <div>
        {props.handles.filter(h => h?.props?.produces?.length > 0)}
        {props.children}
      </div>
    : <div className='node-card'>
        <div>
          {props.handles}
        </div>
        <Collapse
          className={props.children ? "" : 'ant-collapse-expand-icon-hidden'}
          expandIconPosition="start"
          collapsible="icon"
          style={{ minWidth: '50px' }}
          onChange={key => {
            if (props.data != null) {
              if (key[0] == null) {
                props.data.open = false;
                setOpen(false);
              } else {
                props.data.open = true;
                setOpen(true);
              }
            }
          }}
          defaultActiveKey={open ? ['1'] : []}
          items={[
            {
              key: '1',
              forceRender: true,
              label:
              <div style={{cursor: 'grab'}}>
                <Typography.Text
                  style={{ marginRight: 20 }}
                  editable={{
                    triggerType: "icon",
                    onChange: value => {
                      setTitle(value)
                      if (props.data != null) {
                        props.data.title = value;
                      }
                    }
                  }}  
                >
                  {title}
                </Typography.Text>
              </div>,
              extra:
                items != null
                ? <Dropdown menu={{items}} trigger='click' >
                    <SettingOutlined/>
                  </Dropdown>
                : null,
              children: 
                <div className='nodrag' style={{ cursor: 'auto'}}>
                  {props.children}
                </div >
            }

          ]}
        >
        </Collapse>
        <Modal
          open={detailsModalOpen}
          onCancel={() => setDetailsModalOpen(false)}
          title="Node Details"
          okButtonProps={{ style:{display: 'none'}}}
          cancelText="Close"
        >
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignContent: 'center', alignItems: 'center', gap: 20}}>
            <div style={{flex: 1, textAlign: 'right'}}>
              <div>
                Node Id
              </div>
              <div>
                Node Type
              </div>
              <div>
                Node Title
              </div>
              <div>
                Dependencies
              </div>
            </div>
            <div style={{flex: 1}}>
              <div>
                <Uuid value={props.id}></Uuid>
              </div>
              <div>
                {props.type}
              </div>
              <div>
                {props.data?.title}
              </div>
              <div>
                {dependencies}
              </div>
            </div>
          </div>
        </Modal>
      </div>
  )
};

Component.displayName = 'NodeCard';
export default Component;