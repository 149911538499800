import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { renderToString } from 'react-dom/server';

import { BsAlignStart as StartFlag } from "react-icons/bs";
import { BsAlignEnd as FinishFlag } from "react-icons/bs";

import useAsyncEffect from 'use-async-effect';
import { Typography, Form, Skeleton, Select, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin, Tooltip } from 'antd';
import ImageViewer from '../components/ImageViewer3';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)
import { BsCameraFill as CaptureIcon } from 'react-icons/bs'

import { MdSkipNext as NextIcon } from "react-icons/md";
import { MdSkipPrevious as PrevIcon } from "react-icons/md";
import { TfiTarget as Target } from "react-icons/tfi";

import Input from '../components/Input';

import { BsLayoutSplit as Horizontal } from "react-icons/bs";





import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined, SettingOutlined  } from '@ant-design/icons';
import { BsPlayFill as PlayIcon } from 'react-icons/bs'
import { BsPauseFill as PauseIcon } from 'react-icons/bs'
import { PiSquareSplitVerticalFill as SplitVertical, PiSquareSplitHorizontalFill as SplitHorizontal   } from "react-icons/pi";

import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';


import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';
import PageHeader from '../components/PageHeader'
import Timeline from '../components/Timeline';
import moment from 'moment';
import Table from '../components/Table';

import sys from '../system'
import imageUtils from '../utils/image';
import ImageModel from '../models/Image';
import SourceModel from '../models/Source';
import ConfigModel from '../models/Config'
import ProcessModel from '../models/Process'

const Component = (props) => {

  const sourcePlayerRef = useRef()
  const [image, setImage] = useState()
  const [sizes, setSizes] = useState([]);
  const [split, setSplit] = useState('vertical')

  const [sourceId, setSourceId] = useState(null)
  const [sources, setSources] = useState([])

  
  const [processId, setProcessId] = useState(null)
  const [processs, setProcesss] = useState([])

  useAsyncEffect(async () => {
    const [sources, processs] = await Promise.all([
      sys.orm("select id as value, name as label from source where status = 'ACTIVE'"),
      sys.orm("select id as value, name as label from process"),
    ])
    setSources(sources)
    setProcesss(processs)
  }, [])

  return (
    <div style={{
      width: '100%',
      height: 'calc(100vh - 100px)'
    }}>
      <PageHeader>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', gap: 20}}>
          <Tooltip title='Refresh' placement='bottom'>
            <Button
              type='text'
              icon={<Horizontal style={{transform: (split === 'horizontal' ? null : 'rotate(90deg)') }}/>}
              onClick={() => {
                if (split === 'horizontal') {
                  setSplit('vertical')
                } else {
                  setSplit('horizontal')
                }
                sourcePlayerRef.current.fit()
              }}
            />
          </Tooltip>
          <Typography.Title style={{margin: 0}} level={5} >Demo</Typography.Title>

          <div style={{width:'300px', marginLeft: '100px'}}>
            <Input
              type={sys.type.Id}
              value={sourceId} 
              values={sources} 
              onChange={(id) => {
                setSourceId(id)
              }}
              allowClear
            />
          </div>
          <div style={{width:'300px'}}>
            <Input 
              type={sys.type.Id}
              value={processId} 
              values={processs} 
              onChange={(id) => {
                setProcessId(id)
              }}
              allowClear
            />
          </div>

        </div> 
      </PageHeader>
      
      <SplitPane 
        split={split}
        sizes={sizes}
        performanceMode={false}
        onChange={(sizes) => {        
          setSizes(sizes)
          sourcePlayerRef.current.fit()
        }}
      >
        <Pane>

          <Timeline
            ref={sourcePlayerRef}
            time={new Date()}
            sourceId={sourceId}
            simulationId={null}
            processId={processId}
            onRangeChange={(range) => {
              //console.log('onRangeChange callback', range)
            }}
            onChangeImage={(image) => {
              //console.log('onChangeImage callback', image)
              setImage(image)
            }}
          />
        </Pane>
        <Pane>
          <ImageViewer
              showTimestamp={true}

              image={image}
              showPolygons={true}
              showBoxes={true}
              showLabels={false}
              showConfidence={false}
              showTags={false}

              showGeometry={true}

              //geometry={geometry}

            ></ImageViewer>
        </Pane>

      </SplitPane>





    </div>
  );
};
export default Component;