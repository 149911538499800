import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from './../../../components/ProcessEditor/Handle'

import { Space, Form, Card, Select, InputNumber } from 'antd';
import NodeCard from './../../../components/ProcessEditor/NodeCard'

const Component = props => {

  const [priority, setPriority] = useState(props.data.priority || 0);
  const [fromExitId, setFromExitId] = useState(props.data.exitId);
  

  const exits = []
  props.process._state.subprocess.forEach(sp => sp.nodes.forEach(n => {
    if (n.type === 'Exit') {
      exits.push({label: n.data.title, value: n.id, node: n});
    }
  }));


  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='start-1'
          name='out'
          title="Produces a [Activation]"
          produces={['any', 'poly', 'polyset', 'image']}
          activation
          process={props.process}
          subprocess={props.subprocess}
       />
      ]}
    >
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        style={{width: 260}}
      >
        <Form.Item label="Priority" required labelCol={{ span: 8 }}>
          <InputNumber
            value={priority}
            onChange={value => {
              props.data.priority = value;
              setPriority(props.data.priority || 0);
              props.onChange();
            }}
            min={0}
            step={1}
          />
        </Form.Item>
        <Form.Item label="Exit">
          <Select
              allowClear={true}
              placeholder="Select a exit"
              style={{width: 200}}
              value={fromExitId}
              onSelect={(value) => {
                props.data.exitId = value;
                setFromExitId(props.data.exitId);
                props.onChange();
              }}
              onClear={() => {
                props.data.exitId = null;
                setFromExitId(props.data.exitId);
                props.onChange();
              }}
              options={exits}
            />
        </Form.Item>
      </Form>
    </NodeCard>
  );
};

Component.displayName = 'StartNode';
export default Component;

