import { Button, Card, Radio, Checkbox, Switch, Form, Divider, Layout, Row, Col, Select, Upload, TimePicker, Typography, Popconfirm, Modal, Space, Transfer, Tabs, List, Menu, Input, DatePicker, InputNumber, Skeleton  } from 'antd';
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { Cron } from 'react-js-cron'
import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';



import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import 'react-quill/dist/quill.snow.css';
import './Input.less'
import sys from './../system';

const subscribe = (uuid, model, columnName, setValue) => {
  const rowSubs = model.meta.subscriptions = model.meta.subscriptions || {};
  const columnSubs = rowSubs[columnName] = rowSubs[columnName] || {};
  columnSubs[uuid] = (value) => setValue(value);
}
const unSubscribe = (uuid, model, columnName) => {
  return () => {
    if (model != null && model.meta != null && model.meta.subscriptions != null) {
      if (model.meta.subscriptions[columnName] != null) {
        delete model.meta.subscriptions[columnName][uuid];
      }
    }
  }
}

const parseDuration = (str) => {
  if (str == null || typeof str !== 'string') {
    return null
  }
  str = str.trim()
  if (str === '') {
    return null;
  }
  if (str.match(/^-?\d{1,2}:\d{1,2}$/)) {
    return str;
  } else if (str.match(/^-?\d{1,2}$/)) {
    return str + ':00'
  } else if (str.match(/^-?:\d{1,2}$/)) {
    return '00:' + str;
  }
  throw new Error('Invalid Duration format')
}

const parseJson = (str) => {
  if (str == null) {
    return str
  }
  str = str.trim()
  if (str === '') {
    return null;
  }
  if (str === 'true') {
    return true;
  }
  if (str === 'false') {
    return false;
  }
  let value
  try {
    return JSON.parse(str);
  } catch (e) {
    value = parseInt(str)
    if (!isNaN(value)) {
      return value;
    }
    return str;
  }
}

const stopPropagations = {
  onKeyDown: ev => {
    ev.stopPropagation();
  },
  onKeyUpCapture: ev => {
    ev.stopPropagation();
  }
}

const valuesToOptions = async (values, row) => {
  
  if (typeof values === 'function') {
    values = await values();
  } else if (Array.isArray(values)) {

  }

  const options = [];
  if (values == null) {
    return options;
  }
  values.forEach(v => {
    let value;
    let label;
    if (typeof v === 'string') {
      value = label = v;
    } else if (typeof v === 'object') {
      if (v.value != null) {
        value = label = v.value;
      }
      if (v.label != null) {
        label = v.label;
      }
      if (v.value === undefined && v.label === undefined) {
        value = label = v;
      }
    }
    options.push({ key: value, value, label });
  });
  return options;
};

const Component = (props) => {

  const uuid = useRef(sys.uuid());
  
  const [showHelp, setShowHelp] = useState(false);
  const [value, setValue] = useState();
  const propagatedValue = useRef();
  const [model, setModel] = useState();
  const [columnName, setColumnName] = useState();
  const [columnType, setColumnType] = useState();
  const [options, setOptions] = useState([]);
  const [danger, setDanger] = useState(props.danger);
  const [warning, setWarning] = useState(props.warning);


  const propagateValue = async (value, e) => {
    console.log('new value:', value, 'old value:', propagatedValue.current, 'propagation', propagatedValue.current !== value);
    if (propagatedValue.current !== value) {
      if (typeof props.onChange === 'function') {
        //console.log('propagateValue', value, propagatedValue.current, propagatedValue.current !== value);
        props.onChange(value, e);
        setValue(value);
      } else if (model != null && columnName != null) {
        //console.log('propagateValue', value, propagatedValue.current, propagatedValue.current !== value);
        model[columnName] = value;
        value = model[columnName];
        setValue(value);
      }
      propagatedValue.current = value;
    }
  }

  useEffect(() => {
    if (props.model instanceof sys.type.Row && props.column != null) {
      const model = props.model;
      const columnName = props.column;
      const columnType = props.type || props.model.meta.config.columns[columnName];
      if (columnType != null) {
        setModel(model);
        setColumnName(columnName);
        setColumnType(columnType);
        const v = model[columnName];
        propagatedValue.current = v;
        setValue(model[columnName]);
        subscribe(uuid.current, model, columnName, setValue);
        return unSubscribe(uuid.current, model, columnName);
      }
    } else {
      setModel(null)
      setColumnName(null);
      setColumnType(props.type);
      const v = props.value;
      setValue(v);
      propagatedValue.current = v;
    }
  }, [props.model, props.column])

  useEffect(() => {
    const v = props.value;
    setValue(v);
    propagatedValue.current = v;
  }, [props.value])


  let title = null;
  if (props.title === undefined && columnType != null) {
    title = columnType.title;
  } else {
    title = props.title;
  }

  const values = props.values || columnType?.values;
  let inputElement;
  if (value === undefined) {
    inputElement = <Skeleton.Input active={true} style={{height: (props.lines > 1) ? (props.lines || 1) * 24 : undefined}} block={true} />
  } else 
  if (values != null) {
    valuesToOptions(values, model).then(o => {
      if (!_.isEqual(options, o)) {
        setOptions(o);
      }
    });

    if (props.optionType != null) {
      console.log('bzlol', props.radio)
      inputElement = <Radio.Group 
        value={value}
        options={options}
        onChange={(e) => {
          setValue(e.target.value);
          propagateValue(e.target.value);
        }}
        style={{width: '100%'}}
        allowClear={props.allowClear}
        disabled={props.disabled}
        optionType={props.optionType}
        buttonStyle={props.buttonStyle}
      />
    } else {
      inputElement = <Select
        value={value}
        options={options}
        onChange={(value) => {
          setValue(value);
          propagateValue(value);
        }}
        style={{width: '100%'}}
        allowClear={props.allowClear}
        onClear={() => {
          setValue(null);
          propagateValue(null);
        }}
        disabled={props.disabled}
      />;
    }


  } else if (columnType instanceof sys.type.Uuid || columnType instanceof sys.type.Text || columnType instanceof sys.type.Upper || columnType instanceof sys.type.Lower) {
    if (props.html != null) {
      inputElement = 
      <div tabIndex="0" style={{width:'100%'}} onBlur={() => propagateValue(value)}>
        <ReactQuill
          style={props.style}
          modules={{
            imageDrop: true,
            toolbar: [
              [{ 'align': [] }],
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline','strike', 'blockquote'],
              [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
              ['link', 'image'],
              ['clean']
          ]}}
          formats={[
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image',
            'align', 'float'
          ]}
          theme="snow" 
          value={value} 
          onChange={(content, delta, source, editor) => {
            console.log(content, delta, source, editor)
            console.log(editor.getHTML())
            const value = editor.getHTML();
            setValue(value);
          }} 
        />
      </div>
    } else {
      inputElement = <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          propagateValue(value)
        }}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />
    }

  } else if (columnType instanceof sys.type.Number || columnType instanceof sys.type.Integer || columnType instanceof sys.type.Stamp) {
    inputElement = <InputNumber
      style={{ width: '100%' }}
      value={value}
      addonAfter={props.addonAfter}
      onChange={(value) => {
        setValue(value);
      }}
      onBlur={() => {
        propagateValue(value)
      }}
      disabled={props.disabled}
      onKeyDownCapture={(e) => { if (e.code === 'F1') { setShowHelp(true); e.preventDefault(); }}}
    />
  } else if (columnType instanceof sys.type.DateTime || columnType instanceof sys.type.Date) {
    inputElement = <DatePicker
      style={{ width: '100%' }}
      showTime={columnType instanceof sys.type.DateTime}
      value={value == null ? null : dayjs(value)}
      onChange={(value) => {
        console.log('onChange', value)
        setValue(value)
        propagateValue(value)
      }}
      allowClear={props.allowClear}
      onKeyDownCapture={(e) => { if (e.code === 'F1') { setShowHelp(true); e.preventDefault(); }}}
    />
  } else if (columnType instanceof sys.type.Cron) {
    inputElement = 
      <Popconfirm
        icon={null}
        placement='bottom'
        description={
          <div style={{display: 'flex', flexDirection: 'column' }}>
            <Cron
              value={value}
              setValue={(value) => {
                setValue(value)
                propagateValue(value)
              }}
            />
          </div>
        }
        okButtonProps={{style: {display: 'none'}}}
        cancelText='Close'
      >
        <Input
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onBlur={() => {
            propagateValue(value)
          }}
          readOnly={props.readOnly}
          disabled={props.disabled}
          onKeyDownCapture={(e) => { if (e.code === 'F1') { setShowHelp(true); e.preventDefault(); }}}
        />
      </Popconfirm>
  } else if (columnType instanceof sys.type.Boolean) {
    if (props.switch) {
      inputElement = <Switch
        style={{ marginTop: '0.3rem'}}
        checked={value}
        onChange={(checked) => {
          setValue(checked);
          propagateValue(checked)
        }}
        readOnly={props.readOnly}
        disabled={props.disabled}
        onKeyDownCapture={(e) => { if (e.code === 'F1') { setShowHelp(true); e.preventDefault(); }}}
      />
    } else {
      inputElement = <Checkbox
        style={{ marginTop: '0.3rem'}}
        checked={value}
        onChange={(e) => {
          setValue(e.target.checked);
          propagateValue(e.target.checked)
        }}
        readOnly={props.readOnly}
        disabled={props.disabled}
        onKeyDownCapture={(e) => { if (e.code === 'F1') { setShowHelp(true); e.preventDefault(); }}}
      />
    }
  } else if (columnType instanceof sys.type.Duration) {
    const sign = value?.startsWith('-') ? '-' : '';
    inputElement = <TimePicker 
      placeholder={''}
      format={sign + 'mm:ss'}
      showNow={false}
      needConfirm={false}
      changeOnScroll={true}
      value={value == null ? null : dayjs(value, 'mm:ss')}
      onBlur={(e) => {
        let timeString = e.target.value;
        if (e.target.value === undefined || e.target.value == '') {
          return
        }
        try {
          timeString = parseDuration(timeString)
        } catch (e) {
          return
        }
        setValue(timeString); 
        propagateValue(timeString)
      }}
      onChange={(time, timeString) => {
        try {
          timeString = parseDuration(timeString)
        } catch (e) {
          return
        }
        setValue(timeString); 
        propagateValue(timeString)
      }}
      renderExtraFooter={() => (
        <Button.Group>
          {
            props.negative !== false &&
            <Button 
              size={'small'} 
              icon={sign === '' ? <MinusOutlined/> : <PlusOutlined/>} 
              onClick={() => {
                if (value == null) {
                  return
                }
                let v = null;
                if (sign === '' && !value.startsWith('-')) {
                  v = '-' + value;
                } else if (sign === '-' && value.startsWith('-')) {  
                  v = value.substring(1)
                }
                setValue(v);
                propagateValue(v);
              }}
            />
          }
          <Button.Group>
            <Button size='small' type='link' onClick={() => { const v = sign + '01:00'; setValue(v); propagateValue(v); }}>
              {sign}1min
            </Button>
            <Button size='small' type='link' onClick={(e) => { e.stopPropagation(); const v = sign + '00:30'; setValue(v); propagateValue(v); }}>
              {sign}30s
            </Button>
            <Button size='small' type='link' onClick={() => { const v = sign + '00:15'; setValue(v); propagateValue(v); }}>
              {sign}15s
            </Button>
            <Button size='small' type='link' onClick={() => { const v = sign + '00:05'; setValue(v); propagateValue(v); }}>
              {sign}5s
            </Button>
          </Button.Group>
        </Button.Group>
      )}
    />
  } else if (columnType instanceof sys.type.Json) {
    inputElement = 
    <div style={{width: '100%'}}>
      <CodeMirror
        editable={props.disabled !== true}
        extensions={[langs.json()]}
        value={typeof value === 'string' ? value : value == null ? '' : JSON.stringify(value, null, 2)}
        onChange={(value) => {
          setValue(value)
          let parsedValue = parseJson(value)
          let type = typeof parsedValue;
          if (parsedValue === null) {
            type = 'null';
          } else if (Array.isArray(parsedValue)) {
            type = 'array';
          }
          setWarning(`Value interpreted as ${type}`)
        }}
        minHeight={props.style?.minHeight || '100px'}
        maxHeight={props.style?.maxHeight}
        height={props.style?.height}
        onBlur={(ev) => {
          let parsedValue = parseJson(ev.target.innerText)
          propagateValue(parsedValue)
        }}
        basicSetup={{
          foldGutter: true,
          allowMultipleSelections: false,
        }}
        onKeyDownCapture={(e) => { if (e.code === 'F1') { setShowHelp(true); e.preventDefault(); }}}
      />
    </div>
  } else if (columnType instanceof sys.type.File) {
    inputElement = 
    <div style={{width: '100%'}}>
      <Upload
        listType={'picture-card'}
        maxCount={1}
        showUploadList={true}
        defaultFileList={
          props.model == null || props.model[props.column] == null 
          ? [] 
          : props.model[props.column].map((src, i) => { 
            const key = props.model.meta.config.table + '/' + props.column + '/' + props.model.id + '/';
            return ({
              uid: 'file-' + i,
              name: src.replaceAll(key, ''),
            })
          })
        }
        headers={{
          Authorization: `Bearer ${useStore.getState().accessToken}`,
        }}
        onChange={({file, fileList, event}) => {
          if (props.model != null) {
            if (fileList.length === 0) {
              props.model[props.column] = null
            }
            if (event == null) {
              const key = props.model.meta.config.table + '/' + props.column + '/' + props.model.id + '/';
              const files = fileList.map(f => key + f.name)
              props.model[props.column] = files.length === 0 ? null : files
            }
          }
          if (typeof props.onChange === 'function') {
            props.onChange(fileList, event);
          }
        }}
        action={(file) => {
          return '/api/upload';
        }}
        data={(file) => {
          if (props.model != null) {
            const key = props.model.meta.config.table + '/' + props.column + '/' + props.model.id + '/' + file.name;
            return ({
              key
            })
          }
          if (props.s3key != null) {
            return ({
              key: props.s3key + '/' + file.name
            })
          }
        }}
      >
        <Button 
          type='text'
        >
          Upload
        </Button>
      </Upload>
    </div>
  } else {
    if (props.values != null) {
      valuesToOptions(props.values, model).then(o => {
        if (!_.isEqual(options, o)) {
          setOptions(o);
        }
      });
      inputElement = <Select
        mode={props.mode}
        value={value}
        options={options}
        onSelect={(value) => {
          propagateValue(value);
        }}
        style={{width: '100%'}}
        tagRender={props.tagRender}
        allowClear={props.allowClear}
        onClear={() => {
          propagateValue(null);
        }}

      />;
    } else {
      <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          propagateValue(value)
        }}
        readOnly={props.readOnly}
        disabled={props.disabled}
        {...props}
      />
    }
  }

  return (
    <div 
      style={{paddingBottom: '0.4rem'}}
      {...stopPropagations}
    >
      <Row justify="space-between" >
        <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
          {props.required === true ? <Typography.Text type="danger" style={{paddingRight:4}}>*</Typography.Text> : null}
          <Typography.Text>{title}</Typography.Text>
        </div>
        <Col style={{paddingRight: 2}}>
        { 
            warning != null
            ? <Typography.Text type="warning" style={{fontSize:'0.6rem', transition: 'opacity 2.0s ease-in'}}>{warning}</Typography.Text>
            : null
          }
          { 
            danger != null
            ? <Typography.Text type="danger" style={{fontSize:'0.6rem', transition: 'opacity 2.0s ease-in'}}>{danger}</Typography.Text>
            : null
          }
        </Col>
      </Row>
      <Row onKeyDownCapture={(e) => { if (e.code === 'F1') { setShowHelp(true); e.preventDefault(); }}}>
        { inputElement }
      </Row>
      <Modal 
        open={showHelp}
        type='info'
        title={'Help'}
        onCancel={() => setShowHelp(false)}
        footer={
          <Button type="primary" onClick={() => setShowHelp(false)}>Close</Button>
        }
      >
        
        <Divider />
        <Typography.Paragraph level={2}>
          Field name: <Typography.Text copyable code>{columnType?.title}</Typography.Text>
        </Typography.Paragraph>
        <Typography.Paragraph level={2}>
          Database location: <Typography.Text copyable code> {columnType?.table?.table}.{columnType?.name} </Typography.Text>
        </Typography.Paragraph>

        <Typography.Paragraph>{columnType?.description}</Typography.Paragraph>
      </Modal>
    </div>
  )
};

export default Component;