import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import { Card } from 'antd';

import useAsyncEffect from 'use-async-effect';
import sys from '../../system'


import imageUtils, { stringToColor } from './../../utils/image';


const Component = (props) => {

  const [data, setData] = useState();
  const [sources, setSources] = useState([])


  useAsyncEffect(async () => {
    
    const dataset = props.dataset;
    if (dataset == null) {
      return;
    }
    let sourceClassCount = await dataset.getSourceClassCount()

    let sources = {}
    let data = {}
    sourceClassCount.forEach(a => {
      if (data[a.class_name] == null) {
        data[a.class_name] = { 
          name: a.class_name,
          [a.source_name]: 0
        }
      }
      if (data[a.class_name][a.source_name] == null) {
        data[a.class_name][a.source_name] = 0;
      }
      data[a.class_name][a.source_name] += a.count;
      sources[a.source_name] = null;
    });
    
    setSources(Object.keys(sources))
    setData(Object.values(data))

  }, [props.dataset])

  return (
    <ResponsiveContainer width="100%" height="50%">
      <RadarChart cx="50%" cy="50%" outerRadius="90%" data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        <PolarRadiusAxis/>
        {
          sources.map((s, i) => 
            <Radar 
              key={s + '-' + i} 
              name={s}
              dataKey={s} 
              stroke={imageUtils.stringToColor(s.split('').reverse().join())} 
              fill={imageUtils.stringToColor(s.split('').reverse().join())} 
              fillOpacity={0.6} 
            />)
        }
      </RadarChart>

    </ResponsiveContainer>
  )
};

export default Component;