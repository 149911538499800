import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import { Button, Card, Checkbox, ColorPicker, Form, Layout, Row, Col, Typography, Space, Table, Transfer, Tabs, List, Menu, Select } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store';

import Input from '../components/Input'
import ImageClass from '../components/ImageAnnotator/ImageClass.jsx'
import GeometryStyleModel from '../models/GeometryStyle';
import sys from '../system';


const colorPresets = [
  {
    "label": "RGB",
    "colors": [
        "#ff1478" + "00",
        "#ff1478" + "1a",
        "#ff1478" + "33",
        "#ff1478" + "4d",
        "#ff1478" + "66",
        "#ff1478" + "80",
        "#ff1478" + "99",
        "#ff1478" + "b3",
        "#ff1478" + "cc",
        "#ff1478" + "ff",

        "#14ff78" + "00",
        "#14ff78" + "1a",
        "#14ff78" + "33",
        "#14ff78" + "4d",
        "#14ff78" + "66",
        "#14ff78" + "80",
        "#14ff78" + "99",
        "#14ff78" + "b3",
        "#14ff78" + "cc",
        "#14ff78" + "ff",

        "#1478FF" + "00",
        "#1478FF" + "1a",
        "#1478FF" + "33",
        "#1478FF" + "4d",
        "#1478FF" + "66",
        "#1478FF" + "80",
        "#1478FF" + "99",
        "#1478FF" + "b3",
        "#1478FF" + "cc",
        "#1478FF" + "ff",
    ]
  }, {
  "label": "Gray Scale",
    "colors": [
        "#000000" + "00",
        "#000000" + "1a",
        "#000000" + "33",
        "#000000" + "4d",
        "#000000" + "66",
        "#000000" + "80",
        "#000000" + "99",
        "#000000" + "b3",
        "#000000" + "cc",
        "#000000" + "ff",
    ]
  }
]

const Component = forwardRef((props, ref) => {
 
  const [geometryStyles, setGeometryStyles] = useState([]);

  useImperativeHandle(ref, () => ({
    save: async () => {
      geometryStyles.forEach(gs => {
        if (sys.isEmpty(gs.name)) {
          throw new Error('Name is required');
        }
      })

      const trx = sys.type.Transaction();
      geometryStyles.forEach(gs => {
        trx.add(gs);
      });
      await trx.save();
    }
  }));

  useAsyncEffect(async () => {
    const geometryStyles = await GeometryStyleModel.selectAll();
    setGeometryStyles(geometryStyles);
    console.log(geometryStyles)
  }, []);


  return (
    <>
      <Table
        dataSource={geometryStyles}
        columns={[{
          title: 'Name',
          dataIndex: 'name',
          onFilter: (value, gs) => gs.name == value,
          filters: geometryStyles.map(gs => ({ text: gs.name, value: gs.name })),
          filterSearch: true,
          render: (text, gs) => {
            console.log('gs.name', gs.name)
            return <Input title={null} model={gs} column='name'/>
          },
        }, {
          title: 'Fill',
          render: (text, gs) => <ColorPicker 
            value={gs?.style?.fillColor} 
            onChange={(color, hex) => {
              gs.style = {
                ...gs.style,
                fillColor: hex
              }
            }}
            presets={colorPresets}
          />,
        }, {
          title: 'Stroke',
          render: (text, gs) => <ColorPicker 
            value={gs?.style?.strokeColor} 
            onChange={(color, hex) => {
              gs.style = {
                ...gs.style,
                strokeColor: hex
              }
            }}
            presets={colorPresets}
          />,
        }, {
          title: 'Visible',
          dataIndex: 'visible',
          onFilter: (value, gs) => gs.name == value,
          filters: geometryStyles.map(gs => ({ text: gs.name, value: gs.name })),
          filterSearch: true,
          render: (text, gs) => {
            return <Input title={null} model={gs} switch column='visible'/>
          },
        }
      ]}
      />
    </>
  );
});

Component.displayName = 'GeometriesForm';
export default Component;