import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import { BaseEdge, BezierEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, MarkerType } from 'reactflow';

const Component = (props) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: props.sourceX,
    sourceY: props.sourceY,
    sourcePosition: props.sourcePosition,
    targetX: props.targetX,
    targetY: props.targetY,
    targetPosition: props.targetPosition,
  });

  return (
    <>
      <BaseEdge 
        className="dependency-edge"
        path={edgePath}
        style={{
          stroke: '#aaaaaa',
        }}
      >
      </BaseEdge>
      {/*
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: '#ffcc00',
            padding: 10,
            borderRadius: 5,
            fontSize: 12,
            fontWeight: 700,
          }}
          className="nodrag nopan"
        >
          {props.id}
        </div>
      </EdgeLabelRenderer>
      */}
    </>
  );
}

Component.displayName = 'DependencyEdge';
export default Component;
