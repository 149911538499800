import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, PageHeader, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin, List, Tag , Divider  } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';

import Table from '../components/Table';

import LogModel from './../models/Log';

import sys from '../system';
const { Text, Integer, DateTime, Uuid } = sys.type;
import { TbTypography } from 'react-icons/tb';

const Component = () => {

  const createdAt = useRef(sys.now());

  const [tail, setTail] = useState([]);
  
  const timerAction = async () => {
    let logs = await LogModel.select(`created_at > '${createdAt.current.toISOString()}' order by created_at desc limit 100`);
    logs = logs.map(log => {
      createdAt.current = sys.now();
      return `${log.id} ${log.created_at.format()} ${log.level.padStart(8)} ${log.message}`;
    });
    setTail([...tail, ...logs]);
  }

  useEffect(() => {
    sys.timers.stop('log-timer');
    sys.timers.start('log-timer', timerAction, 1000);

    return (() => {
      sys.timers.stop('log-timer');
    })
  });

  return (
  <div>
  {/*
      <MonacoEditor
        width="100%"
        height="calc(100vh - 102px)"
        language="text"
        theme="vs-dark"
        value={tail.join('\n')}
      />
  */
  }
  </div>
  );
};
export default Component;