import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu } from 'antd';
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store';

import Input from './../components/Input'

import WorkerModel from '../models/Worker';
import UserSelect from './../components/UserSelect';

const Component = forwardRef((props, ref) => {
 
  const [worker, setWorker] = useState();
  const [userId, setUserId] = useState();

  useImperativeHandle(ref, () => ({
    save: async () => {
      await worker.save(); 
    }
  }));

  useAsyncEffect(async () => {
    let worker;
    if (props.id == null) {
      worker = await WorkerModel.create(props.worker);
    } else {
      worker = await WorkerModel.get(props.id);
    }
    setWorker(worker);
  }, []);

  return (
    <>  
      <Input model={worker} column={'id'} disabled></Input>
      <Input model={worker} column={'name'} required></Input>
      <Input model={worker} column={'description'}></Input>
      <Input model={worker} column={'status'}></Input>
      <UserSelect 
        value={worker?.user_id}
        onChange={(ids) => {
          worker.user_id = ids[0];
        }} 
        mode={'multiple'}
      />
      <Input model={worker} column={'config'}></Input>
    </>
  );
});

Component.displayName = 'WorkerForm';
export default Component;