import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import { Button, Card, Checkbox, Table, Form, Modal, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu, Select } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store.js';

import UserAvatar from '../components/UserAvatar.jsx'
import Input from '../components/Input.jsx'
import ImagePicker from '../components/ImagePicker.jsx'
import ImageClass from '../components/ImageAnnotator/ImageClass.jsx'
import PersonModel from '../models/Person.js';
import sys from '../system.js';

import modelModel from '../models/Model.js';

const Component = forwardRef((props, ref) => {

  const [processCommits, setProcessCommits] = useState([]);

  useImperativeHandle(ref, () => ({
    getState: () => {
      return state
    },
  }));


  useAsyncEffect(async () => {
    const processId = props.processId;
    const processCommits = await sys.orm('select id, process_id, message, created_by, created_at from process_commit where process_id = $1 order by created_at desc', [processId]);
    setProcessCommits(processCommits)
  }, []);

  return (
    <>
      <Table
          dataSource={processCommits}
          pagination={{ position: ['bottomCenter'] }}
          columns={[
            {
              title: 'User',
              render: (text, c) => {
                return <UserAvatar id={c.created_by} ></UserAvatar>
              },
          },
          {
              title: 'Message',
              width: '300px',
              render: (text, c) => {
                return <div>{c.message}</div>
              },
          }, {
            title: 'Date',
            dataIndex: 'created_at',
            render: (text, c) => {
              return <div>{sys.format.datetime(c.created_at)}</div>
            },
          }, {
            title: 'Actions',
            render: (text, c) => {
              return (
                <Button 
                  size='small'
                  danger
                  type='primary' 
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure?',
                        content: `Are you sure you want to revert to this commit?`,
                        onOk: async () => {
                          if (typeof props.onRevert === 'function') {
                            props.onRevert(c)
                          }
                        },
                        okText: "Yes I'm sure!",
                        okType: 'danger',
                      });
                  }}
                >
                  Revert
                </Button>
              )
            },
          }
        ]}
      />
    </>
  );
});

Component.displayName = 'ProcessCommit';
export default Component;