import React, { useState, useRef, useEffect, useCallback, useMemo, memo } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Tabs, Checkbox, Button, Modal, Space, Tag, Alert, Menu, Statistic, Row, Col, Badge, Card, Spin, Select, Drawer, Tooltip, Transfer    } from 'antd';
import imageUtils, { stringToColor } from '../../utils/image';

import sys from './../../system'

const ImageCount = (props) => {
  const [count, setCount] = useState();
  useAsyncEffect(async () => {
    const tags = props.tags;
    let classes = props.classes;
    
    let i = 0;
    let values = [];

    let inTags;
    if (tags == null) {
      inTags = 'and 1 = 1';
    } else if (tags.length == 0) {
      inTags = 'and 1 = 2';
    } else if (tags.length > 0) {
      inTags = `and name in (${tags.map(_ => `$${++i}`)})`;
      values.push(tags);
    }


    let inClasses;
    if (classes == null) {
      inClasses = 'and 1 = 1';
    } else if (classes.length == 0) {
      inClasses = 'and 1 = 2';
    } else if (classes.length > 0) {
      inClasses = `and name in (${classes.map(_ => `$${++i}`)})`;
      values.push(classes);
    }

    const count = await sys.orm({
      first: true,
      select: `select count(*) from image where 
        exists (select * from tag where ref_id = image.id ${inTags})
        and exists (select * from annotation where image_id = image.id ${inClasses})
      `,
      values: values.flat()
    });
    setCount(count);
  }, [props]);
  return <div>{count} images</div>
}


const Component = (props) => {
    return (
      <Card title='Selected Tags' extra={<ImageCount tags={props.tags}></ImageCount>} >
        <Select
          mode="tags"
          style={{ width: '100%' }}
          tagRender={(props) => {
            const { label, value, closable, onClose } = props;
            const onPreventMouseDown = (event) => {
              event.preventDefault();
              event.stopPropagation();
            };
            return (
              <Tag
                color={imageUtils.stringToColor(value)}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{
                  marginRight: 3,
                }}
              >
                {label}
              </Tag>
            );
          }}
          placeholder="Tags Mode"
          value={props?.tags?.map(tag => ({ key: tag, value: tag }))}
          onChange={tags => {
            if (typeof props.onChange === 'function') {
              props.onChange(tags)
            }
          }}
          options={props.allTags}
        />
      </Card>
    );
  }


  Component.displayName = 'TagSelector';
  export default Component;
  
  