import { Button, Card, Tooltip, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu, Input } from 'antd';
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import moment from 'moment';
import _ from 'lodash';
import { usePresistentStore } from '../store';
import sys from '../system';

import { useNavigate } from 'react-router-dom';

const Component = (props) => {

  const navigate = useNavigate();

  const collapsed = usePresistentStore(store => store.collapsed);
  let width = 0

  if (collapsed) {
    if (!sys.screen.isMobile()) {
      width = 220;
    } else {
      width = 160;
    }
  } else {
    if (!sys.screen.isMobile()) {
      width = 360;
    } else {
      width = 260
    }
  }
  
  width = width + 'px';

  return (
    <div className="page-header" style={{
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      marginLeft: 64,
      height: 64,
      top: 0,
      overflow: 'hidden',
      width: `calc(100vw - ${width})`,
      transition: 'width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s',
    }}>
      <div style={{width: '100%', height: '100%'}}>
        {props.children}
      </div>
    </div>
  )
};

export default Component;