import React, { useState, useRef, useEffect, useCallback, useMemo, memo } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Tabs, Checkbox, Button, Modal, Space, Tag, Alert, Menu, Statistic, Row, Col, Badge, Card, Spin, Select, Drawer, Tooltip, Transfer    } from 'antd';
import imageUtils, { stringToColor } from '../../utils/image';
import sys from './../../system'



const Component = (props) => {
  const [selectedClasses, setSelectedClasses] = useState([]);

  return <Transfer
    className='cls-transfer'
    oneWay
    dataSource={props.allClasses}
    titles={['Available Classes', 'Selected Classes']}
    targetKeys={props?.classes}
    selectedKeys={selectedClasses}
    onChange={(nextTargetKeys, direction, moveKeys) => {
      nextTargetKeys = nextTargetKeys.filter(k => k != null);
      if (typeof props.onChange === 'function') {
        props.onChange(nextTargetKeys, props.rename)
      }
    }}
    onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
      setSelectedClasses(sourceSelectedKeys);
    }}
    render={(cls) => {
      if (props?.classes?.includes(cls.name)) {

        let rename = props?.rename[cls.name];
        if (rename == null) {
          rename = cls.name;
        }

        return (
          <Row align='middle' style={{height: '2rem'}}>
            <Col span={11}>
              {cls.name}
            </Col>
            <Col span={11}>
              <Input
                status={cls.name == rename ? undefined : 'warning'}
                defaultValue={rename}
                onBlur={ev => {

                  let value = ev.target.value;
                  value = value.trim();
                  let rename = {
                    ...props.rename,
                    [cls.name]: value === '' || cls.name === value ? undefined : value
                  };
                  if (typeof props.onChange === 'function') {
                    props.onChange(props.classes, rename)
                  }
                }}
              />
            </Col>
            <Col span={2} style={{textAlign: 'right'}}>
              <Typography.Text type={props.availableClasses.find(e => e.name == cls.name) ? undefined : "danger"}>
                {cls.count}
              </Typography.Text>
            </Col>
          </Row>
        )
      }
      return (
        <Row align='middle' style={{height: '2rem'}}>
          <Col span={22}>
            <Typography.Text>
              {cls.name}
            </Typography.Text>
          </Col>
          <Col span={2} style={{textAlign: 'right'}}>
              {cls.count}
          </Col>
        </Row>
      )
    }}
  />
}


Component.displayName = 'ClassSelector';
export default Component;