import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { Table, Typography, Input, Form, Skeleton, Checkbox, PageHeader, theme, Button, Modal, AutoComplete, Space, Alert, Menu, Row, Col, Badge, Pagination, Tag, Dropdown, Popconfirm, message, Select  } from 'antd';
import { FilterFilled, ClearOutlined, TableOutlined, DownloadOutlined, LoadingOutlined   } from '@ant-design/icons';
import moment from 'moment';
import _, { filter } from 'lodash';
const { useToken } = theme;

import useAsyncEffect from 'use-async-effect';
import sys from '../../system'


const Component = (props) => {
    const [value, setValue] = useState();
    const [tags, setTags] = useState(props.values || []);
    const [options, setOptions] = useState([]);

    useAsyncEffect(async () => {
      if (props.options != null) {
        if (Array.isArray(props.options)) {
          const opts = props.options.map(o => ({
            value: '='+o,
            label: o
          }));
          setOptions(opts);
        } else if (typeof props.options === 'function') {
          let opts = await props.options();
          opts = opts.map(o => ({
            value: '='+o,
            label: o
          }));
          setOptions(opts);
        }
      }
    }, [props.options])

    
    useAsyncEffect(() => {
      setTags(props.values || [])
    }, [props.values])
  
    const bubbleUp = (tags) => {
      if (typeof props.onChange == 'function') {
        props.onChange(tags)
      }
    }
  
    const addTag = (value) => {
      if (sys.isEmpty(value)) {
        return;
      }
      if (tags.includes(value)) {
        return;
      }
      const newTags = [...tags, value];
      bubbleUp(newTags);
      setTags(newTags);
    }
    return (
      <div style={{ width: '20rem', padding: 20 }}>
        <Row style={{}} gutter={[0, 10]}>
          {
            tags.map(tag => {
              return (
              <Tag key={tag} closable={true} onClose={e => { 
                const newTags = tags.filter(t => t !== tag);
                setTags(newTags); 
                bubbleUp(newTags);
              }}>
                <span>{tag}</span>
              </Tag>
              );
            })
          }
        </Row>
        <Row style={{paddingTop: 20}}>
          <AutoComplete
            style={{ display: 'block', width: '100%' }}
            placeholder='Search'
            allowClear={true}
            autoComplete="off"
            value={value}
            onSelect={async (value) => { 
              setValue(null);
              addTag(value); 
            }}
            onSearch={async (value) => { 
              setValue(value);
            }}
            onKeyDown={(e) => { 
              if (e.key === 'Enter') {
                addTag(value); 
                setValue(null);
              }
            }}
            options={options.filter(o => !tags.includes(o.value))}
          />
        </Row>
        <Row style={{paddingTop: 20}}>
          <Space>
            <Button type="primary" size="small" style={{ width: 90 }} onClick={() => { addTag(value); setValue(); }}>Search</Button>
            <Button size="small" style={{width: 90}} onClick={() => { setValue(); setTags([]); bubbleUp([]); }}>Reset</Button>
          </Space>
        </Row>
      </div>
    );
  }

Component.displayName = 'InputSearch';
export default Component;