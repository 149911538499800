import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from './../../../components/ProcessEditor/Handle'

import { Select, Space, Form, Card, Checkbox } from 'antd';
import useAsyncEffect from 'use-async-effect';

import SourceModel from '../../../models/Source'
import NodeCard from '../../../components/ProcessEditor/NodeCard'

const Component = props => {

  if (props.data.sourceId === undefined) {
    props.data.sourceId = null;
  }
  if (props.data.batch == null) {
    props.data.batch = false;
  }


  const [sourceId, setSourceId] = useState(props.data.sourceId);
  const [sources, setSources] = useState([]);
  const [batch, setBatch] = useState(props.data.batch);

  useAsyncEffect(async () => {
    let sources = await SourceModel.selectAll();
    sources = sources.map(e => ({value: e.id, label: e.name}));
    setSources(sources);
  }, []);


  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='1'
          name='in'
          title='Activation'
          accepts={['any']}
          activation
        />,
        <Handle
          key='2'
          name='out'
          title="Produces one [Image]"
          produces={['image']}
          activation
        />
      ]}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item label="Source" required>
          <Select
              allowClear={true}
              placeholder="Please select a source"
              style={{width: 200}}
              value={sourceId}
              onSelect={(value) => {
                props.data.sourceId = value;
                setSourceId(props.data.sourceId);
                props.onChange();
              }}
              onClear={() => {
                props.data.sourceId = null;
                setSourceId(props.data.sourceId);
                props.onChange();
              }}
              options={sources}
            />
        </Form.Item>
        <Form.Item label="Batch" >
            <Checkbox
              value={batch}
              onChange={value => {
                props.data.batch = value;
                setBatch(props.data.batch);
                props.onChange();
              }}
          />
        </Form.Item>
      </Form>
    </NodeCard>
  );
};

Component.displayName = 'LoadFromSourceNode';
export default Component;

