import { type } from '../system';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = type;

export default Table({
    name: 'User',
    table: 'user',
    columns: {
        username: Text({
            title: 'Username',
        }),
        password: Text({
            title: 'Password',
        }),
        person_id: Uuid(),
        person: Relation({
            to: 'Person',
            cardinality: '1',
            where: "id = $person_id",
        }),
        status: type.Text({
            defaultsTo: 'ACTIVE',
            values: ['ACTIVE', 'INACTIVE']
        }),
        password_history: Relation({
            to: 'PasswordHistory',
            cardinality: '*',
            where: "user_id = :id"
        })
    }
})