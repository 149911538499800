import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from '../../../components/ProcessEditor/Handle'

import { Space, Form, Card, Select, InputNumber, Radio } from 'antd';
import NodeCard from '../../../components/ProcessEditor/NodeCard'

import { MdOutlineArrowForward } from "react-icons/md";


const Component = props => {


  const [limit, setLimit] = useState(props.data.limit);
  
  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='1'
          name='activate'
          title="Activate"
          style={{ top: '40%' }}
          accepts={['any']}
          activation
        />,
        <Handle
          key='2'
          name='polyset'
          title='Polyset'
          style={{ top: '66%' }}
          accepts={['poly', 'polyset']}
          maxConnections={1}
        />,
        <Handle
          key='3'
          name='poly'
          title="Produces [Polygon]"
          style={{ top: '56.5%', }}
          produces={['poly']}
          activation
        />
      ]}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          width: 300, 
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <div style={{flex: 10, }}>
            <img src='/nodes/polygonAdd.svg' style={{width: '80px', height: '80px'}} />
          </div>
          <div >
            <MdOutlineArrowForward size={28}/>      
          </div>
          <div style={{flex: 7}}>
            <img src='/nodes/polygonA.svg' style={{width: '50px', height: '50px'}} />
          </div>
          <div>
            ...
          </div>
          <div style={{flex: 7}}>
            <img src='/nodes/polygonB.svg' style={{width: '50px', height: '50px'}} />
          </div>
        </div>
        <div 
          style={{
            display: 'flex',
            paddingTop: 20,
          }}
        >
          <Form>
            <Form.Item label="Limit" labelCol={{ span: 16 }}>
              <InputNumber
                min={0}
                step={1}
                value={limit == null ? '∞' : limit}
                onChange={value => {
                  if (value === '∞' || value <= 0) {
                    value = null;
                  }
                  props.data.limit = value;
                  setLimit(props.data.limit);
                  props.onChange();
                }}
                parser={(value) => {
                  console.log('parset', value)
                  if (value === '∞' || value <= 0) {
                    return null
                  };
                  return value;
                }}
                formatter={value => {
                  if (value == null || value <= 0) {
                    return '∞'
                  };
                  return value;
                }}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </NodeCard>
  );
};

Component.displayName = 'UngroupNode';
export default Component;


