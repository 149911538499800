import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';

import Table from '../components/Table';
import Player from './../components/Player';

import PageHeader from '../components/PageHeader'

import SourceForm from './../forms/Source';

import sys from '../system'
const { Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = sys.type;


const CountCaptures = (props) => {
  const [count, setCount] = useState();
  useAsyncEffect(async () => {
    let count = await sys.orm(`select count(*) from image where source_id = $1`, [props.id]);
    count = count[0].count;
    setCount(count);
  }, []);
  return count == null ? <Spin size="small" /> : <div>{count}</div>;
}

const LastCapture = (props) => {
  const [max, setMax] = useState();
  useAsyncEffect(async () => {
    const rows = await sys.orm(`select max(created_at) max from image where source_id = $1`, [props.id]);
    const max = rows[0].max;
    setMax(max);
  }, []);
  return max == null ? <Spin size="small" /> : <div>{sys.format(max)}</div>;
}


const FirstCapture = (props) => {
  const [min, setMin] = useState();
  useAsyncEffect(async () => {
    const rows = await sys.orm(`select min(created_at) min from image where source_id = $1`, [props.id]);
    const min = rows[0].min;
    setMin(min);
  }, []);
  return min == null ? <Spin size="small" /> : <div>{sys.format(min)}</div>;
}

const Component = () => {
  const table = useRef();
  const sourceForm = useRef();
  const [sourceId, setSourceId] = useState();
  const [viewSourceId, setViewSourceId] = useState();

  return (
    <div>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>
          <Typography.Title style={{margin: 0}} level={5} >List of Sources</Typography.Title>
        </div>
      </PageHeader>
      <Table
        ref={table}
        title="Sources"
        query={{
          count: "select count(*) from source",
          select: `
            select 
              *
            from
              source
          `,
          limit: 20,
          orderBy: { created_at: 'desc' }
        }}
        footer={
          <Button type='primary' onClick={() => setSourceId(null)}>New Source</Button>
        }

        columns={[
          { 
            title: 'Name', 
            sorter: true,
            name: 'name',
            type: Text,
            filter: true,
            width: '200px'
          },
          { 
            title: 'Description', 
            sorter: true,
            name: 'description',
            type: Text,
            filter: true,
            mobile: false,
          },
          {
            title: 'First Capture', 
            width: '180px',
            mobile: false,
            render: (record) => {
              return <FirstCapture id={record.id}/>
            }
          },
          {
            title: 'Last Capture', 
            width: '180px',
            mobile: false,
            render: (record) => {
              return <LastCapture id={record.id}/>
            }
          },
          {
            title: 'Captures', 
            width: '180px',
            render: (record) => {
              return <CountCaptures id={record.id}/>
            }
          },
          { 
            title: 'Status', 
            name: 'status',
            sorter: true,
            type: Text,
            width: '140px',
          },
          { 
            title: 'Created At', 
            sorter: true,
            name: 'created_at',
            type: DateTime,
            filter: true,
           mobile: false,
          },
          {
            title: 'Actions',
            width: '300px',
            render: (record) => {
              return <div>
                <Button type='link' onClick={() => {
                  setViewSourceId(record.id);
                }}>View</Button>
                <Button type='link' onClick={() => {
                  setSourceId(record.id);
                }}>Edit</Button>
              </div>
            }
          }
        ]}     
    />
    <Modal 
      width={1280+80}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText='Close'
      maskClosable={false}
      title="View Source"
      open={viewSourceId !== undefined} 
      onCancel={() => {
        setViewSourceId(undefined);
      }}
    >
      <Player
        key={sys.uuid()}
        width={1280}
        height={720}
        quality={75}
        sourceId={viewSourceId}
        showLabels={false}
        showConfidence={false}
        showPolygons={false}
        showBoxes={false}
      /> 
    </Modal>

    <Modal 
      title="Source"
      open={sourceId !== undefined} 
      onOk={async () => {
        console.log(sourceForm);
        await sourceForm.current.save();
        setSourceId(undefined);
        table.current.refresh();
      }}
      onCancel={() => {
        setSourceId(undefined);
      }}
    >
      <SourceForm ref={sourceForm} id={sourceId}></SourceForm>
    </Modal>
    </div>
  );
};
export default Component;