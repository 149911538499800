import { type } from '../system.js';
const { Text, Integer, Table, Uuid, DateTime, Relation, Json, Number, Boolean } = type;
import sys from '../system.js'


export default Table({
    name: 'Geometry',
    table: 'geometry',
    columns: {
        name: Text({
            onChange: (value, row) => {
                if (value == null || value.trim() === '') {
                    return 'UNDEFINED';
                }
                return value;
            }
        }),
        confidence: Number({
            title: 'Confidence',
            defaultsTo: 0,
        }),
        type: Text({
            title: 'Type',
        }),
        points: Json({
            title: 'Points'
        }),
        style: Json({
            title: 'Style'
        }),
        simulation_id: Uuid(),
        image_id: Uuid(),
        created_by: Uuid({
            defaultsTo: () => sys.getUser()?.id
        }),
        image: Relation({
            to: 'Image',
            cardinality: '1',
            where: '$image_id = id'
        }),
        process_run_id: Relation({
            to: 'ProcessRun',
            cardinality: '1',
            where: '$process_run_id = id'
        })
    }
});
