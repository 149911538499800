import React, { useState, useRef, useEffect, useCallback, useMemo, memo } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Tabs, Checkbox, Button, Modal, Space, Tag, Alert, Menu, Statistic, Row, Col, Badge, Card, Spin, Select, Drawer, Tooltip, Transfer    } from 'antd';
import imageUtils, { stringToColor } from '../../utils/image';

import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";
import { useStore } from '../../store';
import PageHeader from '../../components/PageHeader'
import { SaveOutlined, EditOutlined, DeleteOutlined, SyncOutlined as RefreshIcon } from '@ant-design/icons';

import ClassCountChart from '../../components/DatasetEditor/ClassCountChart';
import ClassCountTreemap from '../../components/DatasetEditor/ClassCountTreemap';
import ClassCountRadar from '../../components/DatasetEditor/ClassCountRadar';
import BackButton from '../../components/AppHeader/BackButton'

import ValidationSetStats from './ValidationSetStats';
import SourceStatistics from './SourceStatistics';


import Dataset from '../../models/Dataset';




const Component = (props) => {

  const [dataset, setDataset] = useState()
  const [refresh, setRefresh] = useState(0)
  const [tabKey, setTabKey] = useState('1')
  const params = useParams();

  useAsyncEffect(async () => {
    const datasetId = params.datasetId;
    if (datasetId == null) {
      return;
    }
    const dataset = await Dataset.get(datasetId);
    setDataset(dataset);
  }, []);

  return (

    <div style={{width: '100%', height: '100%'}}>
      <PageHeader>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', gap: 20}}>
          <BackButton/>
          <Tooltip title={<div>Refresh <kbd>CTRL + R</kbd></div>} placement='bottom'>
            <Button
              type='text'
              icon={<RefreshIcon />}
              onClick={() =>  {
                setRefresh(refresh + 1)
              }}
            />
          </Tooltip>

          <Typography.Title style={{margin: 0}} level={5} >{dataset?.name}</Typography.Title>
          <Typography.Text 
            style={{margin: 0, minWidth: 300 }} 
            level={5} 
          >
            {dataset?.description}
          </Typography.Text>
        </div>
      </PageHeader>

      <Card style={{}}>
        <Tabs
          key={'tab-'+refresh}
          defaultActiveKey={tabKey}
          onChange={(key) => {
            setTabKey(key)
          }}
          items={[
            {
              label: 'Classes',
              key: '1',
              children: 
                <div style={{height: 'calc(100vh - 215px)'}}> 
                  <ClassCountChart
                    dataset={dataset}
                  />
                  <ClassCountRadar
                    dataset={dataset}
                  />
                </div>,
            },
            {
              label: 'Sources',
              key: '3',
              children: 
                <div style={{height: 'calc(100vh - 215px)'}}>
                  <ClassCountTreemap
                    dataset={dataset}
                  />
                </div>
            },
            {
              label: 'Validation Set Statistics',
              key: '4',
              children: 
                <div style={{height: 'calc(100vh - 215px)', overflow: 'auto'}}>
                  <ValidationSetStats
                    dataset={dataset}
                  ></ValidationSetStats>
                </div>
            },
            {
              label: 'Image Statistics',
              key: '5',
              children: 
                <div style={{height: 'calc(100vh - 215px)', overflow: 'auto'}}>
                  <SourceStatistics
                    dataset={dataset}
                  ></SourceStatistics>
                </div>
            },
          ]}
        />





      </Card>
    </div>
  )
};

Component.displayName = 'DatasetStatistics';
export default Component;