import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';

import { Button, Card, Checkbox, Modal, Form, Layout, Row, Col, theme, Typography, Space, Transfer, Tabs, List, Menu, DatePicker, InputNumber  } from 'antd';

import {
  ScheduleOutlined
} from '@ant-design/icons';

import { Cron } from 'react-js-cron'
const { useToken } = theme;
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigation,
  useNavigate,
  useParams
} from "react-router-dom";
import imageUtils from './../../utils/image';
import useAsyncEffect from 'use-async-effect';
import sys from './../../system';

import VisibilitySensor  from 'react-visibility-sensor';


const cropImage = async (annotation, image) => {
  return new Promise((resolve, reject) => {

    const border = 50;
    const aX = annotation.points[0] * image.width;
    const aY = annotation.points[1] * image.height;
    const aW = annotation.points[2] * image.width;
    const aH = annotation.points[3] * image.height;

    const canvas = document.createElement('canvas');
    canvas.width = aW + border*2;
    canvas.height = aH + border*2;
    const ctx = canvas.getContext('2d');
    


    const img = new Image();
    img.src = image.url;  
    img.onload = async () => {
      ctx.drawImage(img, 
        aX - border, 
        aY - border, 
        aW + border*2, 
        aH + border*2, 
        0, 0, canvas.width, canvas.height
      );

      ctx.strokeStyle = "red";
      ctx.lineWidth = 2;
      ctx.strokeRect(border, border, canvas.width - border*2, canvas.height - border*2);

      const imgUrl = canvas.toDataURL('image/jpeg')
      return resolve(imgUrl);
    };
  });
};

import AnnotationModel from '../../models/Annotation';


const Component = (props) => {

  const [annotation, setAnnotation] = useState()
  const [showGoto, setShowGoto] = useState(false)

  const onChangeVisibility = async (isVisible) => {
    if (isVisible == false) {
      return
    }
    const id = props.id;
    if (id == null) {
      return ;
    }
    const annotation = await AnnotationModel.get(props.id);
    const image = await annotation.image;
    
    await sys.image.preload(image.url)
    
    annotation.img = await cropImage(annotation, image)

    setAnnotation(annotation)
  }


  return (
    [
      <VisibilitySensor 
        key='viewer'
        onChange={onChangeVisibility}
      >
        <div
          style={{
            backgroundColor: '#111',
            height: '100%', 
            width: '100%',
            border: '1px solid black',
            boxShadow: '2px 2px 3px gray',
            backgroundImage: `url(${annotation?.img})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
          onClick={() => {
            setShowGoto(true)
          }}
        />
      </VisibilitySensor>,
      <Modal
        key='viewer-goto'
        title="Go to"
        open={showGoto}
        okButtonProps={{ style:{display: 'none'}}}
        onCancel={() => {
          setShowGoto(false)
        }}
        centered
      >
        <div style={{display:'flex', flexDirection: 'column', gap: 20, padding: 20}}>
          <Button 
            type='primary' 
            style={{height: 50}}
            onClick={() => { 
              setShowGoto(false); 
              window.open('/image/' + annotation?.image_id);
            }} 
          >
            Image Viewer
          </Button>
          <Button 
            type='primary' 
            style={{height: 50}}
            onClick={() => { 
              setShowGoto(false); 
              window.open('/annotate/' + annotation?.image_id); }} 
            >
            Annotator
          </Button>
        </div>
      </Modal>
    ]
  )
};

export default Component;