
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Tag, Space, Transfer, Tabs, theme, List, Menu, Input, Collapse  } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import useAsyncEffect from 'use-async-effect';
import TagModel from '../models/Tag';

const { useToken } = theme;

import imageUtils from './../utils/image';


const NewTag = (props) => {

    const { token } = useToken();
    const inputElem = useRef()
    const [inputVisible, setInputVisible] = useState(false);

    useEffect(() => {
        if (inputVisible) {
            inputElem?.current?.focus();
        }
    }, [inputVisible]);

    const bubbleUp = (value) => {
        if (value == null || value.trim() == '') {
            return;
        }
        if (typeof props.onChange === 'function') {
            props.onChange(value);
        }
    }
    
    return (        
        inputVisible ?
        <Input
          ref={inputElem}
          type="text"
          size="small"
          style={{
            width: 80
          }}
          onBlur={(e) => {
            setInputVisible(false);
            bubbleUp(e.target.value);
          }}
          onPressEnter={(e) => {
            setInputVisible(false);
            bubbleUp(e.target.value);
          }}
        /> :    
        <Tag 
            onClick={() => {
                setInputVisible(true);
            }} 
            style={{
                width: 80,
                background: token.colorBgContainer,
                borderStyle: 'dashed',
            }}>
            <PlusOutlined /> New Tag
        </Tag>
    )
};


const ImageTags = (props) => {

    const [tags, setTags] = useState([]);
  
    useAsyncEffect(async () => {
  
      if (props.id) {
        const tags = await TagModel.select('ref_id = $1 order by created_at', [props.id] );
        setTags(tags);
      }
  
    }, [props.id])
    
    return (
      <Row gutter={[0,8]}>
        {
          tags.map(tag => 
            <Tag 
              key={`tag-${tag.id}`}
              closable={props.id && !props.disabled} 
              onClose={async () => {
                  tag.delete = true;
                  await tag.save();
              }}
              color={imageUtils.stringToColor(tag.name)}
            >
              {tag.name}
            </Tag>
          )
        }
        {
          props.id && !props.disabled ?
          <NewTag onChange={async (value) => {
            const tag = TagModel.create({
              name: value,
              ref_id: props.id,
            });
            await tag.save();
            setTags([...tags, tag]);
          }}/> 
          : null
        }
      </Row>)
  };

  
ImageTags.displayName = 'ImageTags';
export default ImageTags;