
import { Button, Card, Checkbox, Form, Layout, Select, Steps, Row, Col, Typography, Space, Transfer, Tabs, List, Menu } from 'antd';
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';


import Input from '../components/Input'

import sys from '../system';
import UserModel from '../models/User';


import UserAvatar from '../components/UserAvatar';



const Component = (props) => {
  const [users, setUsers] = useState([]);

  useAsyncEffect(async () => {
    const users = await UserModel.selectAll();
    setUsers(users)
  }, []);

  return (
    <>
      {props.value}
      <Select
        mode={props.mode}
        showSearch={false}
        placeholder="Select a User"
        value={props.value || props.values || props.id || props.ids || props.workerIds || props.id || []}
        onChange={(ids) => {
          if (typeof props.onChange === 'function') {
            props.onChange(ids);
          }
        }}
        style={{
            width: '100%', height: 55
        }}
        tagRender={(props) => {
            return (
            <div style={{paddingLeft: '3px'}}>
              <UserAvatar key={props.value} size='small' id={props.value} showName/>
            </div>
            );
        }}
        options={
            users.map((user) => {
            return {
                label: user.id,
                value: user.id,
            };
          })
        }
        optionRender={(item) => {
            return (
              <UserAvatar key={item.data.value} id={item.data.value} showName/>
            );
        }}
        disabled={props.disabled}
      />
    </>
  )
}

export default Component;