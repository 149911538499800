import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu, Select } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store';

import Input from '../components/Input'
import ImageClass from './../components/ImageAnnotator/ImageClass.jsx'
import PersonModel from '../models/Person';
import sys from './../system';

import modelModel from './../models/Model';
import WorkerModel from './../models/Worker';

const Component = forwardRef((props, ref) => {
 
  let previousState = localStorage.getItem('image_predict_state');
  if (previousState != null) {
    previousState = JSON.parse(previousState)
  }

  const [state, setState] = useState(previousState || {})
  if (state.modelId === undefined) {
    state.modelId = null;
  }
  if (state.workerId === undefined) {
    state.workerId = null;
  }
  if (state.confidence === undefined) {
    state.confidence = 20;
  }
  if (state.area === undefined) {
    state.area = 60;
  }
  if (state.classes === undefined) {
    state.classes = []
  }
  
  useImperativeHandle(ref, () => ({
    getState: () => {
      return state
    },
    saveState: () => {
      localStorage.setItem('image_predict_state', JSON.stringify(state))
    }
  }));

  const [models, setModels] = useState([]);
  const [workers, setWorkers] = useState([]);

  useAsyncEffect(async () => {

    const models = await modelModel.select("STATUS = 'ACTIVE'");
    setModels(models.sort((a, b) => (+new Date(a.created_at)) - (+new Date(b.created_at))).reverse().map(r => ({ value: r.id, label: `${r.name} trained at ${sys.format.date(r.created_at)}` })));

    const workers = await WorkerModel.select("STATUS = 'ACTIVE'");
    setWorkers(workers.map(r => ({ value: r.id, label: r.name })));

    
  }, []);

  return (
    <>  
        <div>
          Ignore Detections when
        </div>
        <div style={{paddingLeft: 20}}>
          <Input 
            title={<span>Confidence is less than</span>}
            max={100}
            min={0}
            addonAfter="%"
            type={sys.type.Number}
            value={state.confidence}
            onChange={confidence => {
              setState({...state, confidence})
            }}
            required
          />

          <Input 
            title={<span>The intersection area is greater than<br></br>(100% means a perfect match)</span>}
            type={sys.type.Number}
            min={0}
            max={100}
            step={1}
            addonAfter="%"
            value={state.area}
            onChange={area => {
              setState({...state, area})
            }}
            required
          />

          <Input
            title={<span>Detected class not included in<br></br>(empty means all class)</span>}
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Classes"
            values={state.classes}
            value={state.classes}
            onChange={value => {
              state.classes = value
              setState({...state})
            }}
            tagRender={(props) => {
              const { label, value, closable, onClose } = props;
              return <ImageClass key={'include-class-'+ value} name={label}></ImageClass>
            }}
          />
        </div>
        <Input 
          title='Model' 
          type={sys.type.Text}
          value={state.modelId}
          onChange={modelId => {
            setState({...state, modelId})
          }}
          values={models}
          required
        />
        <Input 
          title='Worker' 
          type={sys.type.Text}
          value={state.workerId}
          onChange={workerId => {
            setState({...state, workerId})
          }}
          values={workers}
          required
        />

    </>
  );
});

Component.displayName = 'Component';
export default Component;