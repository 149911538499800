import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, Button, Modal, Radio, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import { UnorderedListOutlined, DislikeOutlined, LikeOutlined, QuestionOutlined, MessageOutlined, StepForwardOutlined, StepBackwardFilled } from '@ant-design/icons';

import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";

import PageHeader from '../../components/PageHeader'
import Annotator from '../../components/ImageAnnotator/Annotator'
import Table from '../../components/Table';
import AnnotationViewer from './../../components/AnnotationViewer'

import BackButton from './../../components/AppHeader/BackButton'

import ReviewModel from './../../models/Review'
import DatasetModel from './../../models/Dataset'

import ImageModel from './../../models/Image'

import UserAvatar from './../../components/UserAvatar'

import sys from '../../system'
const { Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = sys.type;


const UserReviewCard = (props) => {

  const [review, setReview] = useState();
  const [result, setResult] = useState();

  useAsyncEffect(async () => {
    const review = await ReviewModel.get(props.id)
    setResult(review.result)
    setReview(review)
  }, [])



  return (
    <span>
      <Card
        actions={[
          <Button 
            key='not-ok' 
            size='small' 
            type={result === 'NOT OK' ? 'link' : 'text'} 
            icon={<DislikeOutlined/>} 
            onClick={async () => {
              review.result = 'NOT OK';
              await review.save()
              setResult(review.result);
            }}
          />,
          <Button 
            key='changed' 
            size='small' 
            type={result === 'CHANGED' ? 'link' : 'text'} 
            icon={<LikeOutlined/>} 
            onClick={async () => {
              review.result = 'CHANGED';
              await review.save()
              setResult(review.result);
            }}
          />,
        ]}
      > 
        <Card.Meta 
          title={
            <UserAvatar key={'avatar'+review?.user_id} id={review?.user_id}>
              <Typography.Text Text type="secondary">{sys.format.datetime(review?.created_at)}</Typography.Text>
            </UserAvatar>
          } 
          description={
            <div style={{paddingTop: 10}}>
              <Typography.Text>
                {review?.comment}
              </Typography.Text>
            </div>
          } 
        />
        
      </Card>
    </span>
  )
}



const Component = () => {
  const navigate = useNavigate()
  const params = useParams();

  const [dataset, setDataset] = useState();
  const [image, setImage] = useState();
  const tableRef = useRef();
  const annotator = useRef()

  useAsyncEffect(async () => {
    const datasetId = params.datasetId;
    const dataset = await DatasetModel.get(datasetId);
    setDataset(dataset);  
  }, [])


  if (dataset == null) {
    return null
  }
  return (
    <div>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>
          <BackButton/>
          <Typography.Title 
              style={{
                margin: 0,
              }} level={5} 
            >
              {dataset?.name} Problems
          </Typography.Title>
        </div>
      </PageHeader>        
      <Table
        ref={tableRef}
        id={'q'}
        title='Problems'
        query={{
          select: `            
            select
              annotation.id id,
              source.name source_name,
              annotation.name annotation_name,
              image.updated_at updated_at,
              image.id image_id,
              (select json_agg(json_build_object('id', id)) from review where annotation_id = annotation.id and result <> 'OK') review,
              annotation.created_at
            from
              annotation
              join image on (annotation.image_id = image.id)
              join source on (source.id = image.source_id)
            where
              exists(select * from review where annotation_id = annotation.id and result <> 'OK')
              and annotation.name in (${dataset.getClassesWhere()})
              and exists (
                select
                  * 
                from 
                  tag 
                where 
                  image_id = annotation.image_id
                  and name in (${dataset.getTagsWhere()})
              )
          `,
          limit: 20,
          orderBy: {
            'created_at': 'desc'
          }
        }}
        columns={[
          { 
            title: 'Problem',
            width: '10px', 
            render: (record) => {
              console.log(record.updated_at)
              return (
                <div style={{width: 160, height: 160}}>
                  <AnnotationViewer key={record.updated_at} id={record.id}></AnnotationViewer>
                </div>
              )
            }
          },
          { 
            title: 'Source', 
            sorter: true,
            name: 'source_name',
            type: Text,
            width: '200px'
          },
          { 
            title: 'Classe Name', 
            sorter: true,
            name: 'annotation_name',
            type: Text,
            width: '200px',
          },
          {
            title: 'Reviews',
            name: 'review',
            render: (record) => {
              return (
                <div style={{display:'flex', flexDirection: 'row', gap: 20}}>
                  {
                    record.review.map((r, i) => 
                      <UserReviewCard 
                        key={'review-card-'+i} 
                        id={r.id}
                      /> 
                    )
                  }
                </div>
              )
            }
          },
          {
            title: 'Actions',
            width: '300px',
            render: (record) => {
              return (
                <div>
                  <Button type='link' 
                    onClick={async () => {
                      const image = await ImageModel.get(record.image_id);
                      setImage(image);
                    }}>Annotate</Button>
                </div> 
              )
            }
          }
        ]}
      />
      <Modal
        title="Annotator"
        open={image != null}
        onCancel={() => {
          setImage(null)
        }}
        onOk={async () => {
          await annotator.current.save();
          tableRef.current.refresh()
          setImage(null)
        }}
        maskClosable={false}
        destroyOnClose={true}
        width={'90%'}
        centered={true}
      >
        <Annotator 
          ref={annotator}
          image={image}
          selectedTool='create-box'
          enabledTools={['create-box']}
        >
        </Annotator>
      </Modal>
    </div>
  );
};
export default Component;