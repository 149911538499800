import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Input as AntInput, Space, Transfer, Tabs, Table, List, Menu } from 'antd';
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store';

import Input from './../components/Input'

import PersonModel from '../models/Person';
import UserTokenModel from '../models/User_Token';


const TokensTab = (props) => {

  const [tokens, setTokens] = useState([]);

  useAsyncEffect(async () => {
    const tokens = await props.person.tokens
    tokens.forEach(token => {
      token.key = token.id;
    });
    setTokens(tokens);
  });




  return (      
    <Table
      bordered
      dataSource={tokens}
      pagination={false}
      columns={[
        {
          dataIndex: 'issued_at',
          title: 'Issued At',
          render: (value) => {
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
          }
        }, {
          dataIndex: 'expires_in',
          title: 'Expires In',
          render: (value) => {
            if (value == null) {
              return null;
            }
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
          }
        }, {
          dataIndex: 'token',
          title: 'Token',
          render: (value) => {
            return (
              <AntInput.Password 
                defaultValue={value}
              />
            )
          }
        }
      ]}
      footer={() => {
        return (
          <Button 
            type='primary'
            size='small'
            onClick={() => {
              const token = UserTokenModel.create({
                issued_at: new Date(),
              })
              token.key = token.id;
              tokens.push(token);
              setTokens([...tokens]);
            }}
          >Create Token</Button>
        )
      }}
    />
  )
};

const Component = forwardRef((props, ref) => {
 

  const [person, setPerson] = useState();
  
  useAsyncEffect(async () => {
    let person;
    if (props.id == null) {
      person = await PersonModel.create();
    } else {
      person = await PersonModel.get(props.id);
    }
    console.log('person', person);
    setPerson(person);
  }, []);

  return (
    <>
      <Tabs 
        defaultActiveKey='1' 
        items={[{
            key: '1',
            label: 'Details',
            children: <>
              <Input model={person} column={'first_name'}></Input>
              <Input model={person} column={'last_name'}></Input>
              <Input model={person} column={'display_name'}></Input>
            </>
        }, {
            key: '2',
            label: 'Tokens',
            children: <TokensTab person={person}></TokensTab>
        }]}
      />

    </>
  );
});

Component.displayName = 'Component';
export default Component;