import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from "react-error-boundary";

import './index.less';
import {
  BrowserRouter
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Neuroengine from './Neuroengine';

import { Quill } from 'react-quill';
import { ImageDrop } from 'quill-image-drop-module';

Quill.register('modules/imageDrop', ImageDrop);

const Fallback = ({ error, resetErrorBoundary }) => {
  return (
    <div>
      Erro
    </div>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    {/*<React.StrictMode>*/}
      <ErrorBoundary
        FallbackComponent={Fallback}
        onError={(error, info) => {
          console.log('ErrorBoundary', error, info)
        }}
        onReset={(details) => {
          console.log('ErrorBoundary onReset', details)
        }}
       >
        <Neuroengine />
      </ErrorBoundary>
    {/*</React.StrictMode>*/}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
