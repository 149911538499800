import React, { useState, useRef, useEffect } from 'react';

import {
  BellOutlined, LogoutOutlined, MenuFoldOutlined,
  MenuUnfoldOutlined, UserOutlined
} from '@ant-design/icons';
import { IoTicketOutline, IoCar } from 'react-icons/io5';

import { BiLogOut } from 'react-icons/bi';

import { Avatar, Badge, Dropdown, Tooltip, Button, theme, Layout, Menu, PageHeader, Space, Modal, Input, Row, Col, Typography, Card } from 'antd';
const { useToken } = theme;
import { useLocation } from 'react-router-dom';


import UserAvatar from '../../components/UserAvatar'
import { useStore, usePresistentStore } from '../../store';
import { useNavigate } from 'react-router-dom';

import sys from '../../system'


const Component = (props) => {

  const { token } = useToken();
  const [messageCount, setMessageCount] = useState(0);
  const collapsed = usePresistentStore(store => store.collapsed);
  const setCollapsed = usePresistentStore(store => store.setCollapsed);
  const person = useStore(store => store.person);
  const user = useStore(store => store.user);
  const location = useLocation();



  useEffect(() => {
    setMessageCount(messageCount + 5);
  }, [useLocation]);

  return (
    <Row
      style={{
        display: 'flex',
        height: 64,
        backgroundColor: token.colorBgContainer,
        boxShadow: '2px 0px 6px  rgba(125, 125, 125, 0.5)',
      }}
    >
      <Col 
        flex="none" 
        style={{
          display: 'flex', 
          width: 64, 
          justifyContent: 'center', 
          alignItems: 'center',
        }}
      >
        <Tooltip title='Collapse' placement='right' mouseEnterDelay={1}>
          <Button className='collapse-button' 
            key='button' 
            type='text' 
            icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
            onClick={
              () => setCollapsed(!collapsed)
            }
          />
        </Tooltip>
      </Col>
      <Col flex='auto'>     
      </Col>
      <Col 
        flex="none" 
        style={{
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          paddingLeft: 20, 
          paddingRight: 20,
        }}
      >
        <Typography>{person?.display_name}</Typography>
      </Col>
      <Col 
        flex="none"
        style={{
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          paddingRight: 20,
        }}
      >
        <Dropdown
          arrow
          placement="bottomLeft"
          menu={{
            items: [
              { 
                key: 1,
                label: 'Profile',
                icon: <UserOutlined/>,
              },
              {
                key: 2,
                label: 'Logout',
                icon: <BiLogOut/>,
                onClick: () => {
                  sys.logout();
                }
              }
            ]
          }}
        >
          <a>
          <Badge size='small' count={messageCount}>
            <UserAvatar id={user?.id}>
              </UserAvatar>
            </Badge>
          </a>
        </Dropdown>   
      </Col>

    </Row>
  );
};
export default Component;