import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';
import PageHeader from '../components/PageHeader'

import Table from '../components/Table';

import sys from '../system'
import { filter } from 'lodash';
const { Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = sys.type;




const Component = () => {
  const navigate = useNavigate()

  return (
    <div>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>
          <Typography.Title style={{margin: 0}} level={5} >Simulation List</Typography.Title>
        </div>
      </PageHeader>
      <Table
        title='Simulations'
        scroll={{y: 'calc(100vh - 280px)'}}
        query={{
          count: "select count(*) from simulation",
          select: `
            select * from simulation`,
          limit: 20,
          orderBy: {
            name: 'asc'
          }
        }}
        columns={[
          { 
            title: 'Name', 
            sorter: true,
            name: 'name',
            type: Text,
            width: '280px',
            render: (record) => {
              return <Link to={`/simulation/${record.id}`}>{record.name}</Link>
            }
          },
          { 
            title: 'Description', 
            sorter: true,
            name: 'description',
            type: Text,
            width: 'auto',
          },
          { 
            title: 'Status', 
            name: 'status',
            sorter: true,
            type: Text,
            width: '140px',
            filter: ['=ACTIVE']
          },
          { 
            title: 'Created At', 
            sorter: true,
            name: 'created_at',
            type: DateTime,
          },
          {
            title: 'Actions',
            width: '300px',
            render: (record) => {
              return (
                <div>
                  <Link to={`/simulation/${record.id}`}>Edit</Link>
                </div>
              )
            }
          }
        ]}
        footer={
          <Button type='primary'>
            <Link to={`/simulation/${sys.uuid()}`}>New Simulation</Link>  
          </Button>
        }
    />
    </div>
  );
};
export default Component;