import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from '../../../components/ProcessEditor/Handle'

import { Select, Space, Form, Card, Modal, Button, Typography } from 'antd';
import NodeCard from '../../../components/ProcessEditor/NodeCard'
import Input from '../../../components/Input'
import system from '../../../system'
const Component = props => {

  if (props.data.schedule == null) {
    props.data.schedule = '00:00:00'
  }
  const [schedule, setSchedule] = useState(props.data.schedule);


  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='1'
          name='in'
          title="Activation"
          accepts={['any']}
          activation
        />
      ]}
    >
      <Form style={{padding: 16}}>
        <Input 
          title="Next job to run in"
          required
          type={system.type.Duration} 
          value={schedule} 
          onChange={(value) => {
            props.data.schedule = value;
            setSchedule(props.data.schedule);
            props.onChange();
          }}
        />
      </Form>
    </NodeCard>
  );
};

Component.displayName = 'JobScheduleNode';
export default Component;

