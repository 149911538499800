

import { type } from '../system.js';
const { Text, Integer, Table, Uuid, DateTime, Relation, Json } = type;


export default Table({
    name: 'Tag',
    table: 'tag',
    columns: {
        name: Text({
            title: 'Name',
            onChange: (value, row) => {
                if (value == null || value.trim() === '') {
                    return 'UNDEFINED';
                }
                return value;
            }
        }),
        ref_id: Uuid(),
        image: Relation({
            to: 'Image',
            cardinality: '1',
            where: '$ref_id = id'
        })
    }
});
