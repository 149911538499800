import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from '../../../components/ProcessEditor/Handle'

import { Select, Space, Form, Card, Modal, Button, Typography } from 'antd';
import NodeCard from '../../../components/ProcessEditor/NodeCard'

const Component = props => {

  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='1'
          name='in'
          title="Activation"
          accepts={['any']}
          activation
        />
      ]}
    >
    </NodeCard>
  );
};

Component.displayName = 'ExitNode';
export default Component;

