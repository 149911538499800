import React, { useState } from 'react';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  DashboardOutlined,
  LikeOutlined,
  LikeFilled,
  SmileFilled
} from '@ant-design/icons';
import { FaMoon, FaSun, FaTasks, FaRobot, FaCog, FaUserCircle, FaScroll } from 'react-icons/fa';
import { AiFillSchedule } from 'react-icons/ai';
import { RiAdminFill } from 'react-icons/ri';
import { FaBrain, FaImages } from 'react-icons/fa'
import { BsFillArchiveFill } from 'react-icons/bs'
import { RiFileList2Fill } from 'react-icons/ri'
import { GrScheduleNew } from 'react-icons/gr';
import { TbPlugConnected, TbGitBranch } from 'react-icons/tb';
import { BsPeopleFill, BsPersonFill } from 'react-icons/bs'
import { FaRunning } from "react-icons/fa";
import { TbClockPlay } from "react-icons/tb";
import { IoTicketSharp as TicketIcon } from "react-icons/io5";

import { Layout, Menu, theme, Row, Col, Switch, ConfigProvider, Button, Card } from 'antd';
const { useToken } = theme;

import { BiLogOut } from 'react-icons/bi';
import DashboardPage from './Dashboard';
import ImagePage from './Image';
import PersonPage from './Person';
import UserPage from './User';
import ImageViewerPage from './ImageViewer'

import JobPage from './Job';
import LogPage from './Log';
import SourcePage from './Source';
import ModelPage from './Model';
import SchedulerPage from './Scheduler';
import WorkerPage from './Worker';
import ProcessPage from './Process'
import ProcessRunPage from './ProcessRun'
import ProcessEditorPage from './ProcessEditor'
import TicketPage from './Ticket'
import SimulationPage from './Simulation'
import SimulationEditorPage from './SimulationEditor2'
import DatasetEditorPage from './Dataset/DatasetEditor'
import DatasetPage from './Dataset';
import DatasetReviewPage from './Dataset/DatasetReview'
import DatasetResultPage from './Dataset/DatasetResult'
import DatasetStatisticPage from './Dataset/DatasetStatistic'
import DatasetClassViewPage from './Dataset/DatasetClassView';
import DemoPage from './Demo'

import AnnotateImagePage from './ImageAnnotator'

import sys from '../system';

import './Home.less'

import AppHeader from '../components/AppHeader'

import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
  Redirect,
  useLocation
} from "react-router-dom";

import { usePresistentStore } from '../store';

import { useEffect } from 'react';

const App = () => {
  const { token } = useToken();
  const location = useLocation();
  const themeName = usePresistentStore(state => state.theme);
  const setThemeName = usePresistentStore(state => state.setTheme);
  const collapsed = usePresistentStore(store => store.collapsed);

  const menuItems = [
    { type: 'divider', style: { border: '1px solid rgba(255, 255, 255, 0.1)'} },
    { key: '/dashboard', label: <Link to="/dashboard">Dashboard</Link>, icon: <DashboardOutlined style={{fontSize: 22 }}/> },
    { type: 'divider', style: { border: '1px solid rgba(255, 255, 255, 0.1)'} },
    { key: '/ticket', label: <Link to="/ticket">Tickets</Link>, icon: <TicketIcon style={{fontSize: 22 }}/> },
    { type: 'divider', style: { border: '1px solid rgba(255, 255, 255, 0.1)'} },

    
    { label: <div>Process</div>, icon: <TbGitBranch style={{fontSize: 22, transform: 'rotate(90deg)'  }}/>,
      children: [
        { key: '/process', label: <Link to="/process">Editor</Link>, icon: <TbGitBranch style={{fontSize: 22, transform: 'rotate(90deg)'  }}/> },
        { key: '/processRun', label: <Link to="/processRun">Process Runs</Link>, icon: <FaRunning style={{fontSize: 22  }}/> },
        { key: '/simulation', label: <Link to={`/simulation`}>Simulations</Link>, icon: <TbClockPlay style={{fontSize: 22  }}/> },
      ]
    },

    { type: 'divider', style: { border: '1px solid rgba(255, 255, 255, 0.1)'} },    
    {
      label: <div>Scheduler</div>, icon: <AiFillSchedule style={{fontSize: 22 }}/>,
      children: [
        { key: '/schedule', label: <Link to="/schedule">Schedules</Link>, icon: <AiFillSchedule style={{fontSize: 22 }}/> },
        { key: '/job', label: <Link to="/job">Jobs</Link>, icon: <FaTasks style={{fontSize: 22 }}/> },
        { key: '/worker', label: <Link to="/worker">Workers</Link>, icon: <FaRobot style={{fontSize: 22 }}/> },
      ]
    },

    { type: 'divider', style: { border: '1px solid rgba(255, 255, 255, 0.1)'} },    
    { key: '/source', label: <Link to="/source">Sources</Link>, icon: <TbPlugConnected style={{fontSize: 22 }}/> },    
    { key: '/image', label: <Link to="/image">Images</Link>, icon: <FaImages style={{fontSize: 22 }}/> },
    { key: '/dataset', label: <Link to="/dataset">Datasets</Link>, icon: <BsFillArchiveFill style={{fontSize: 22 }}/> },
    { key: '/model', label: <Link to="/model">Models</Link>, icon: <FaBrain style={{fontSize: 22 }}/> },
    { type: 'divider', style: { border: '1px solid rgba(255, 255, 255, 0.1)'} },    
    { key: '/person', label: <Link to="/person">People</Link>, icon: <BsPeopleFill style={{fontSize: 22 }}/> },
    { key: '/user', label: <Link to="/user">Users</Link>, icon: <RiAdminFill style={{fontSize: 22 }}/> },
    { type: 'divider', style: { border: '1px solid rgba(255, 255, 255, 0.1)'} },
    { key: '/log', label: <Link to="/log">Logs</Link>, icon: <FaScroll style={{fontSize: 22 }}/> },
    { key: '/demo', label: <Link to="/demo">Demo</Link>, icon: <SmileFilled style={{fontSize: 22 }}/> },
  ];
  const selectedKeys = [];
  for (const menuItem of menuItems) {
    if (location?.pathname === menuItem?.key || location?.pathname?.startsWith(menuItem?.key + '/')) {
      selectedKeys.push(menuItem.key);
      break;
    }
    for (const child of menuItem.children || []) {
      if (location?.pathname === child?.key || location?.pathname?.startsWith(child?.key + '/')) {
        selectedKeys.push(child.key);
        break;
      }
    }
    /*
    if (location?.pathname?.startsWith(menuItem?.key)) {
      selectedKeys.push(menuItem.key);
      break;
    }
    */
  }
  
  return (
      <Layout>
        <Layout.Sider 
          style={{
            backgroundColor: token.colorBgContainer,
            height: '100vh',
          }}
          trigger={
            <Row justify="space-around" align="middle">
            <Col span={12} >
              <BiLogOut 
                style={{
                  marginTop: 14,
                  fontSize: 20,
                }}
              title='Logout' onClick={() => {
                sys.logout();
              }}/>
            </Col>
            {
              ! collapsed
              ? <Col span={12}>
                <Switch style={{opacity: '90%', marginBottom: '8px'}}
                  title='Switch Theme'
                  checkedChildren={<FaSun fontSize={10}/>}
                  unCheckedChildren={<FaMoon fontSize={10}/>}
                  defaultChecked={theme === 'light' ? true : false }
                  onChange={() => {
                    if (themeName === 'light') {
                      setThemeName('dark');
                    } else {
                      setThemeName('light');
                    }
                  }}
                /> 
              </Col>
              :null
            }
          </Row>  
          } 
          collapsible 
          collapsed={collapsed}
          collapsedWidth={64}
          >
          <div style={{
             height: '64px',
             overflow: 'none'
          }}>
            { 
              collapsed 
              ? <div 
                  style={{
                    height: '100%',
                    width: '100%',
                    backgroundImage: "url('/neuroengine-logo.jpg')",
                    backgroundSize: 'cover',
                    backgroundPositionY: '30%',
                  }}
                />
              : <div 
                  style={{
                    height: '100%',
                    width: '100%',
                    backgroundImage: "url('/neuroengine.jpg')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                />
            }
          </div>
          <Menu 
            theme="dark" 
            style={{
              height: 'calc(100vh - (64px + 2 * 18px))',
              overflow: 'hidden',
              backgroundImage: "url('/background.jpg')",
            }} 
            mode="inline" 
            items={menuItems} 
            selectedKeys={selectedKeys}
          />

        </Layout.Sider>
        <Layout>
          <AppHeader></AppHeader>

          <Layout.Content
            style={{
              padding: 18,
              minHeight: 280,
            }}
          >
            <div style={{ 
              overflow: 'auto', 
              height: 'calc(100vh - (64px + 2 * 18px))'
              }}
            >
            <Routes>
              <Route path="/ticket" element={<TicketPage/>}/>
              <Route path="/dashboard" element={<DashboardPage/>}/>
              <Route path="/Source" element={<SourcePage/>}/>
              <Route path="/image/:imageId" element={<ImageViewerPage/>}/>
              <Route path="/image" element={<ImagePage/>}/>
              <Route path="/process" element={<ProcessPage/>}/>
              <Route path="/process/:processId" element={<ProcessEditorPage/>}/>
              <Route path="/processRun" element={<ProcessRunPage/>}/>
              <Route path="/processRun/:processRunId" element={<ProcessEditorPage/>}/>
              <Route path="/simulation" element={<SimulationPage/>}/>
              <Route path="/simulation/:simulationId" element={<SimulationEditorPage/>}/>
              <Route path="/dataset" element={<DatasetPage/>}/>
              <Route path="/dataset/:datasetId" element={<DatasetEditorPage/>}/>
              <Route path="/dataset/:datasetId/result" element={<DatasetResultPage/>}/>
              <Route path="/dataset/:datasetId/review" element={<DatasetReviewPage/>}/>
              <Route path="/dataset/:datasetId/statistic" element={<DatasetStatisticPage/>}/>
              <Route path="/dataset/:datasetId/:className/view" element={<DatasetClassViewPage/>}/>
              <Route path="/model" element={<ModelPage/>}/>
              <Route path="/schedule" element={<SchedulerPage/>}/>
              <Route path="/job" element={<JobPage/>}/>
              <Route path="/log" element={<LogPage/>}/>
              <Route path="/worker" element={<WorkerPage/>}/>
              <Route path="/person" element={<PersonPage/>}/>
              <Route path="/user" element={<UserPage/>}/>
              <Route path="/annotate/:imageId" element={<AnnotateImagePage/>}/>
              <Route path="/demo" element={<DemoPage/>}/>
              <Route path="*" element={<Navigate to="/dashboard" replace/>}/>
            </Routes>
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
  );
};
export default App;