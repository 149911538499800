import React, { useMemo, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu, Input, Collapse, Dropdown, Tooltip  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { EyeOutlined, SettingOutlined } from '@ant-design/icons'
import { Handle, Position, useNodeId, getConnectedEdges, useStore } from 'reactflow';

const selector = (s) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});

const Component = (props) => {

  const tooltipProps = { title: props.title }
  tooltipProps.placement = props.accepts !== null ? 'left' : 'right';  

  const handleProps = {
    type: props.type,
    style: props.style
  }
  delete handleProps.title;

  
  if (Array.isArray(props.accepts) && props.accepts.length > 0) {
    tooltipProps.placement = 'right';
    handleProps.type = 'target';
    handleProps.position = Position.Left;
    handleProps.id = props.name || '';
    handleProps.id +=`[${props.accepts.join(',')}]`
    if (props.activation === true) {
      handleProps.className = 'activation-handle';
      handleProps.id += '+';
    }
  } else
  if (Array.isArray(props.produces) && props.produces.length > 0) {
    handleProps.type = 'source';
    handleProps.position = Position.Right;
    tooltipProps.placement = 'left';
    handleProps.id = props.name || '';
    handleProps.id += `[${props.produces.join(',')}]`
    if (props.activation === true) {
      handleProps.className = 'activation-handle';
      handleProps.id += '+';
    }
  }


  // limita o numero de ligações recebidas a props.maxConnections
  const { nodeInternals, edges } = useStore(selector);
  const nodeId = useNodeId();
  const isHandleConnectable = useMemo(() => {
    if (typeof props.maxConnections === 'number') {
      const node = nodeInternals.get(nodeId);
      let connectedEdges = getConnectedEdges([node], edges);
      connectedEdges = connectedEdges.filter(e => e.targetHandle === handleProps.id)        
      return connectedEdges.length < props.maxConnections;
    }
    return props.isConnectable;
  }, [nodeInternals, edges, nodeId, props.maxConnections]);

  handleProps.isValidConnection = (con) => {
    //console.log('isValidConnection', props, con, props.accepts, props.produces)
    if (Array.isArray(props.accepts)) {
      if (props.accepts.length > 0) {
        for (const type of props.accepts) {
          if (con.sourceHandle.includes(type)) {
            return true;
          }
        }
      }
    }
    if (Array.isArray(props.produces)) {
      if (props.produces.length > 0) {
        for (const type of props.produces) {
          if (con.targetHandle.includes('any')) {
            return true;
          }
          if (con.targetHandle.includes(type)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  return (
    <Tooltip
      {...tooltipProps}
    >
      <Handle
        {...handleProps}
        isConnectable={isHandleConnectable}
      >
      </Handle>
    </Tooltip>
  )
};

Component.displayName = 'NodeHandle';
export default Component;