import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu, Input, Collapse  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { EyeOutlined } from '@ant-design/icons'

const Component = (props) => {
  return (
    <circle className="trigger-point" cx={`${props.x}`} cy={`${props.y}`} r="1" fill="darkgreen"/>
  )
};

Component.displayName = 'SVGPoint';
export default Component;