import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from './../../../components/ProcessEditor/Handle'

import { Space, Form, Card, Select, InputNumber, Radio } from 'antd';
import NodeCard from './../../../components/ProcessEditor/NodeCard'

const Component = props => {

  const [value, setValue] = useState(props.data);
  const [method, setMethod] = useState(props.data.method || 'all_points');
  
  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='activate'
          name='activate'
          title="Activate"
          style={{ top: '40%' }}
          accepts={['any']}
          activation
        />,
        <Handle
          key='a'
          name='a'
          title="Polygons A"
          style={{ top: '66%' }}
          accepts={['polyset', 'poly']}
          maxConnections={1}
        />,
        <Handle
          key='b'
          name='b'
          title="Polygons B"
          style={{ top: '85%', }}
          accepts={['polyset', 'poly']}
          maxConnections={1}
        />,
        <Handle
          key='c'
          name='out'
          title="Result"
          style={{ top: '56.5%', }}
          produces={['any']}
          activation
        />
      ]}
    >
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        style={{width: 300, paddingLeft: 20}}
      >
        <Radio.Group 
          value={method} 
          onChange={e => { 
            props.data.method = e.target.value;
            setMethod(props.data.method); 
          }}
        >
          <Space direction="vertical">
            <Radio value={'all_points'}>All points</Radio>
            <Radio value={'one_point'}>At least one point</Radio>
          </Space>
        </Radio.Group>
      </Form>
    </NodeCard>
  );
};

Component.displayName = 'PolygonContainsNode';
export default Component;

