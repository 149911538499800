
import React, { useState, useEffect, useMemo, useCallback, useRef  } from 'react';
import { Form, Input, Button, Checkbox, Dropdown, Menu, message, Tag, notification, Slider, Tooltip  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { BsCameraFill as CaptureIcon } from 'react-icons/bs'
import { BiDotsVertical as SettingsIcon } from 'react-icons/bi'
import { BiExitFullscreen as ExitFullScreenIcon } from 'react-icons/bi'
import { BiFullscreen as FullScreenIcon } from 'react-icons/bi'
import { FaPlay  as PlayIcon } from 'react-icons/fa';
import { TbPolygon as PolygonIcon } from 'react-icons/tb'
import { BsBoundingBox as BoxIcon } from 'react-icons/bs'
import { AiOutlineTag as TagIcon } from 'react-icons/ai'
import { BiText as TextIcon } from 'react-icons/bi'
import { PercentageOutlined as ConfidenceIcon } from '@ant-design/icons'

import ImageTags from './../ImageTags'

import {
  useNavigate
} from "react-router-dom";

import sys from './../../system';
import ImageViewer from './../ImageViewer'


const Component = (props) => {
  
  const navigate = useNavigate()

  const slider = useRef();
  const timerName = useRef(sys.uuid());
  const playing = useRef(true);
  
  const [showPolygons, setShowPolygons] = useState(props.showPolygons);
  const [showBoxes, setShowBoxes] = useState(props.showBoxes);
  const [showLabels, setShowLabels] = useState(props.showLabels);
  const [showConfidence, setShowConfidence] = useState(props.showConfidence);
  const [showTags, setShowTags] = useState(props.showTags);
  const [marks, setMarks] = useState({});
  const [interval, setInterval] = useState(5);
  const [fullscreen, setFullscreen] = useState(false);
  const [image, setImage] = useState();
  const [source, setSource] = useState();
  const [timeSeries, setTimeSeries] = useState();


  useEffect(() => {
    if (timeSeries == null) {
      return;
    }
    const marks = {}
    timeSeries.forEach((e, i) => {
      if (e.mark) {
        marks[i] = {
          label: <div></div>,
        }
      }
    });
    setMarks(marks)
  }, [timeSeries])


  useEffect(() => {
    sys.timers.start(`Player-Refresh-${timerName.current}`, async () => {
      
      if (timeSeries == null) {
        return;
      }

      let newEntries = await sys.orm(`
        select 
          id, 
          created_at,
          exists (select 1 from tag where tag.ref_id = image.id) mark
        from 
          image 
        where 
          source_id = $sourceId 
          and created_at > $created_at 
        order 
          by created_at
        `, {
        sourceId: props.sourceId,
        created_at: timeSeries.at(-1)?.created_at
      });

      newEntries = newEntries.filter(e => e.created_at > timeSeries.at(-1).created_at);

      if (newEntries.length > 0) {
        timeSeries.push(...newEntries);
        setTimeSeries(timeSeries);
        if (playing.current) {
          const image = timeSeries.at(-1);
          setImage({
            ...image,
            index: timeSeries.length
          });
        }
      }

    }, interval * 1000)


    return () => {
      sys.timers.stop(`Player-Refresh-${timerName.current}`);
    }
  }, [timeSeries, interval])

  
  useAsyncEffect(async () => {
    let source = sys.models.Source.get(props.sourceId);
    let timeSeries = sys.orm(`
      select 
        id, 
        created_at, 
        src,
        exists (select 1 from tag where tag.ref_id = image.id) mark
      from 
        image 
      where 
        source_id = $sourceId
      order by 
        created_at
      `, {sourceId: props.sourceId});
    source = await source;
    timeSeries = await timeSeries;
    setSource(source);
    setTimeSeries(timeSeries);
    setImage({
      ...timeSeries.at(-1),
      index: timeSeries.length
    });
  }, [])

  let style;
  if (fullscreen) {
    style = {
      ...props.style,
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      maxWidth: 'unset',
      minHeight: 'unset',
      borderWidth: 0,
      top: 0,
      left: 0,
      margin: 0,
      padding: 0,
      zIndex: 500,
    }
  } else {
    style = {
      position: 'relative',
      margin: 20,
      ...props.style
    }
  }


  const gotoIndex = (value) => {
    if (timeSeries[value] == null) {
      return;
    }
    playing.current = timeSeries.length-1 == value;
    sys.timers.start(`Player-Seek-${timerName.current}`, () => {
      const image = timeSeries[value]
      setImage({
        ...image,
        index: value
      });
    }, 200, 1)
  }


  return (
    timeSeries &&
      <div
        style={style}
        tabIndex="0"
        onKeyDown={e => {
          if (e.shiftKey && e.key === 'ArrowRight') {
            for (let i = +image.index + 1; i < timeSeries.length; i++) {
              if (timeSeries[i] != null && timeSeries[i].mark) {
                gotoIndex(i)
                break;
              }
            }
          } else
          if (e.shiftKey && e.key === 'ArrowLeft') {
            for (let i = +image.index - 1; i > 0; i--) {
              if (timeSeries[i] != null && timeSeries[i].mark) {
                gotoIndex(i)
                break;
              }
            }
          } else
          if (e.key === "ArrowRight") {
            gotoIndex(+image.index + 1);
          } else if (e.key === "ArrowLeft") {
            gotoIndex(+image.index - 1);
          } else if (e.key === 'Escape') {
            if (fullscreen) {
              setFullscreen(false);
            }
          } else if (e.key === 'Enter') {
            if (!fullscreen) {
              setFullscreen(true)
            }
          } else if (e.key === 'l') {
            setShowLabels(!showLabels);
          } else if (e.key === 'c') {
            setShowConfidence(!showConfidence);
          } else if (e.key === 'b') {
            setShowBoxes(!showBoxes);
          } else if (e.key === 'p') {
            setShowPolygons(!showPolygons);
          } else if (e.key === 't') {
            setShowTags(!showTags);
          } else if (e.key === 's') {
            setInterval()
          }
        }}
      >
        <ImageViewer
          imageId={image?.id}
          width={fullscreen ? '100%' : undefined}
          height={fullscreen ? '100%' : undefined}
          quality={props.quality}
          showLabels={showLabels}
          showBoxes={showBoxes}
          showPolygons={showPolygons}
          showConfidence={showConfidence}
        />

        { /* settings */ }
        <div style={{
          position: 'absolute',
          top: '20px',
          margin: '0px',
          right: '0px',
          transform: 'translate(0%, -50%)',
        }}>
          <Dropdown 
            placement='bottomRight'
            menu={{
              items: [
                {
                  key: '1',
                  style: { color: showLabels ? 'blue' : null },
                  label: <div>Labels <kbd>l</kbd></div>,
                  onClick: () => setShowLabels(!showLabels),
                  icon: <TextIcon/>
                },
                {
                  key: '4',
                  style: { color: showConfidence ? 'blue' : null },
                  label: <div>Confidence <kbd>c</kbd></div>,
                  onClick: () => setShowConfidence(!showConfidence),
                  icon: <ConfidenceIcon/>
                },                
                {
                  key: '2',
                  style: { color: showBoxes ? 'blue' : null },
                  label: <div>Boxes <kbd>b</kbd></div>,
                  onClick: () => setShowBoxes(!showBoxes),
                  icon: <BoxIcon/>
                },
                {
                  key: '3',
                  style: { color: showPolygons ? 'blue' : null },
                  label: <div>Polygons <kbd>p</kbd></div>,
                  onClick: () => setShowPolygons(!showPolygons),
                  icon: <PolygonIcon/>
                },
                {
                  key: '6',
                  style: { color: showTags ? 'blue' : null },
                  label: <div>Tags <kbd>t</kbd></div>,
                  onClick: () => setShowTags(!showTags),
                  icon: <TagIcon/>
                },
                {
                  type: 'divider'
                },
                {  
                  key: '5', 
                  label: 'Refresh rate',
                  type: 'group',
                  children: [
                    { key: '1-1', label: "1 seconds", disabled: interval === 1, onClick:() => { setInterval(1) } },
                    { key: '1-2', label: "5 seconds", disabled: interval === 5, onClick:() => { setInterval(5) } },
                    { key: '1-3', label: "10 seconds", disabled: interval === 10, onClick:() => { setInterval(10) } },
                    { key: '1-4', label: "30 seconds", disabled: interval === 30, onClick:() => { setInterval(30) } },
                    { key: '1-5', label: "1 minute", disabled: interval === 60, onClick:() => { setInterval(60) } },
                  ]
                },
              ]
            }}
          >
            <Button 
              type="text"
              size='large'
              style={{
                color: 'white',
                filter: 'drop-shadow(2px 2px 1.5px #000000FF)',
                fontSize: '20px',
              }}
              icon={<SettingsIcon/>}>
            </Button>
          </Dropdown>
          <Tooltip
            key={fullscreen+sys.uuid()}
            title={fullscreen ? <div>Exit Fullscreen <kbd>Esc</kbd></div> : <div>Fullscreen <kbd>Enter</kbd></div>}
          >
            <Button 
              type="text"
              size='large'
              style={{
                color: 'white',
                filter: 'drop-shadow(2px 2px 1.5px #000000FF)',
                fontSize: '20px',
              }}
              onClick={() => { 
                setFullscreen(!fullscreen)
              }}
              icon={fullscreen ? <ExitFullScreenIcon/> : <FullScreenIcon/>}
            />
          </Tooltip>
        </div>
       
        { /* source name */ }
        <div style={{
          position: 'absolute',
          color: 'white',
          whiteSpace: 'nowrap',
          backgroundColor: '#00000062',
          top: '20px',
          left: '10px',
          margin: '0px',
          paddingLeft: '10px',
          paddingRight: '10px',
          transform: 'translate(0%, -50%)',
        }}>
          <div>
            {source?.name}
          </div>
        </div>

        { /* tags */ 
        showTags && image
        ? <div
            style={{
              position: 'absolute',
              bottom: '8%',
              margin: '0px',
              right: '2%',
              transform: 'translate(0%, -50%)',
            }}
          >
            <ImageTags id={image?.id}></ImageTags>
          </div>
        : null
        } 

        { /* timestamp */ }
        <div style={{
          position: 'absolute',
          color: 'white',
          backgroundColor: '#00000062',
          whiteSpace: 'nowrap',
          top: '20px',
          left: '50%',
          margin: '0px',
          paddingLeft: '10px',
          paddingRight: '10px',
          transform: 'translate(-50%, -50%)',
        }}>
          <div>
            {sys.format.datetime(image?.created_at, 'l LTS')}
          </div>
        </div>

        { /* capture button */ }
        <Button 
          type='ghost'
          style={{
            position: 'absolute',
            width: '32px',
            height: '32px',
            bottom: '8%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF99',
          }} 
          shape="circle" icon={<CaptureIcon style={{marginTop: 1, fontSize:20 }}/>}
          onClick={() => {
            const win = window.open(`/annotate/${image.id}`, '_blank');
            win.focus();
          }}
        />

        <Slider
          ref={slider}
          style={{
            position: 'absolute',
            width: '96%',
            left: '2%',
            bottom: '5%',
            margin: '0px',
          }}
          keyboard={true}
          marks={marks}
          min={0}
          max={timeSeries.length-1}
          onChange={value => {
            gotoIndex(value)
            /*
            playing.current = timeSeries.length-1 == value;
            sys.timers.start(`Player-Seek-${timerName.current}`, () => {
              const image = timeSeries[value]
              setImage({
                ...image,
                index: value
              });
            }, 200, 1)
            */
          }}
          value={image?.index}
          range={true}
          tooltip={{
            formatter: () => {
              return sys.format(image?.created_at)
            }
          }}
          railStyle={{
            backgroundColor: '#ffffff66',
            boxShadow: playing?.current ? '2px 0px 2px 2px #4096ff88' : undefined,
          }}
        />
    </div>
  );
}

export default Component;
