import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from '../../../components/ProcessEditor/Handle'

import { Space, Form, Input, Card, Select, InputNumber, Divider, Tabs, TimePicker, Button  } from 'antd';
import NodeCard from '../../../components/ProcessEditor/NodeCard'

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import system from '../../../system';

dayjs.extend(customParseFormat)

import { TbFlag as FlagUp } from "react-icons/tb";
import { TbFlagOff as FlagDown } from "react-icons/tb";


const Component = props => {

  if (props.data.raise == null) {
    props.data.raise = { patience: '00:00' }
  }
  if (props.data.lower == null) {
    props.data.lower = { patience: '00:00' }
  }
  if (props.data.name === undefined) {
    props.data.name = null
  }

  const [raise, setRaise] = useState(props.data.raise);
  const [lower, setLower] = useState(props.data.lower);
  const [name, setName] = useState(props.data.name);
  
  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='1'
          name='out'
          title="Activate"
          accepts={['any']}
          activation
          process={props.process}
          subprocess={props.subprocess}
      />,
      <Handle
          key='2'
          name='raise'
          title="Produces a [Activation] when the flag is Raised"
          style={{ top: '40%' }}
          produces={['any']}
          activation
          process={props.process}
          subprocess={props.subprocess}
        />,
        <Handle
          key='3'
          name='lower'
          title="Produces a [Activation] when the flag is Lowered"
          style={{ top: '60%', backgroundColor: 'red' }}
          produces={['any']}
          activation
          process={props.process}
          subprocess={props.subprocess}
        />,
        <Handle
          key='4'
          name='no-change'
          title="Produces a [Activation] when the flag is unchanged"
          style={{ top: '80%', backgroundColor: 'gray'}}
          produces={['any']}
          activation
          process={props.process}
          subprocess={props.subprocess}
        />,
      ]}
    >
      <Form style={{padding: 16}} layout="vertical">
          <Form.Item label="Name" labelCol={{ span: 6 }} wrapperCol={{ span: 19 }}>
            <Input
              value={name}
              onChange={ev => {
                props.data.name = ev.target.value;
                setName(props.data.name)
                props.onChange();
              }}
            />
          </Form.Item>
          <Form.Item label="Raise patience:" required>
            <TimePicker 
              suffixIcon={<FlagUp size={24} />}
              title='Minutes:Seconds' 
              placeholder='MM:SS'
              format={'mm:ss'}
              showNow={false} 
              value={dayjs(props.data.raise.patience, 'mm:ss')}
              onChange={(time, timeString) => {
                if (system.isEmpty(timeString)) {
                  timeString = '00:00'
                }
                props.data.raise.patience = timeString;
                setRaise({...props.data.raise});
                props.onChange();
              }}
              renderExtraFooter={() => (
                <Button.Group>
                  <Button size='small' type='link' onClick={() => { props.data.raise.patience = '1:00'; setRaise({...props.data.raise }); props.onChange(); }}>
                    1min
                  </Button>
                  <Button size='small' type='link' onClick={() => { props.data.raise.patience = '00:30'; setRaise({...props.data.raise }); props.onChange(); }}>
                    30s
                  </Button>
                  <Button size='small' type='link' onClick={() => { props.data.raise.patience = '00:15'; setRaise({...props.data.raise }); props.onChange(); }}>
                    15s
                  </Button>
                  <Button size='small' type='link' onClick={() => { props.data.raise.patience = '00:05'; setRaise({...props.data.raise }); props.onChange(); }}>
                    5s
                  </Button>
                </Button.Group>
              )}
            />
          </Form.Item>
          <Form.Item label="Lower patience:" required>
            <TimePicker 
              suffixIcon={<FlagDown size={24} />}
              title='Minutes:Seconds' 
              placeholder='MM:SS'
              format={'mm:ss'}
              showNow={false} 
              value={dayjs(props.data.lower.patience, 'mm:ss')}
              onChange={(time, timeString) => {
                if (system.isEmpty(timeString)) {
                  timeString = '00:00'
                }
                props.data.lower.patience = timeString;
                setLower({...props.data.lower});
                props.onChange();
              }}
              renderExtraFooter={() => (
                <Button.Group>
                  <Button size='small' type='link' onClick={() => { props.data.lower.patience = '1:00'; setLower({...props.data.lower }); props.onChange(); }}>
                    1min
                  </Button>
                  <Button size='small' type='link' onClick={() => { props.data.lower.patience = '00:30'; setLower({...props.data.lower }); props.onChange(); }}>
                    30s
                  </Button>
                  <Button size='small' type='link' onClick={() => { props.data.lower.patience = '00:15'; setLower({...props.data.lower }); props.onChange(); }}>
                    15s
                  </Button>
                  <Button size='small' type='link' onClick={() => { props.data.lower.patience = '00:05'; setLower({...props.data.lower }); props.onChange(); }}>
                    5s
                  </Button>
                </Button.Group>
              )}
            />
          </Form.Item>
        </Form>
    </NodeCard>
  );
};

Component.displayName = 'FlagNode';
export default Component;

