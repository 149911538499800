import React, { memo, useState } from 'react';
import Handle from './../../../components/ProcessEditor/Handle'

import { Select, Space, Tabs, Form, Card, Radio, Modal, Input as AntdInput, Button, Row, Col, Divider } from 'antd';
import useAsyncEffect from 'use-async-effect';
import {
  EyeOutlined, EyeFilled, EyeTwoTone, PlusOutlined, MinusOutlined
} from '@ant-design/icons';
import { BsFiletypeJson } from "react-icons/bs";

import Input from './../../../components/Input'
import dayjs from 'dayjs';
import SourceModel from '../../../models/Source'
import NodeCard from './../../../components/ProcessEditor/NodeCard'
import system from '../../../system';
import { set, size } from 'lodash';
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { vscodeDark, vscodeDarkInit } from '@uiw/codemirror-theme-vscode';

const eventTypes = [
  { value: 'point', label: 'Point' },
  { value: 'range', label: 'Range' },
  { value: 'background', label: 'Background' },
  { value: 'bix', label: 'Box' },
];

const Component = props => {

  if (props.data.offset == null) {
    props.data.offset = '00:00'
  }
  if (props.data.type == null) {
    props.data.type = 'range'
  }
  if (props.data.group == null) {
    props.data.group = null
  }
  if (props.data.name == null) {
    props.data.name = null
  }
  if (props.data.status === undefined) {
    props.data.status = null
  }
  if (props.data.value === undefined) {
    props.data.value = null
  }

  const [openPayloadModal, setOpenPayloadModal] = useState(false);
  const [offset, setOffset] = useState(props.data.offset);
  const [type, setType] = useState(props.data.type);
  const [group, setGroup] = useState(props.data.group);
  const [name, setName] = useState(props.data.name);
  const [status, setStatus] = useState(props.data.status);
  const [value, setValue] = useState(props.data.value);

  return (
    <NodeCard
      title="Trigger Event"
      {...props}
      handles={[
        <Handle
          key='1'
          name='in'
          type="target"
          activation
          accepts={['any']}
          process={props.process}
          subprocess={props.subprocess}
        />,
        <Handle
          key='2'
          name='out'
          type="source"
          activation
          produces={['any']}
        />
      ]}
    >
      <Row style={{width: '420px', height: '190px' }}>
        <Col span={11} style={{paddingLeft: 16, paddingRight: 16}}>
          <Input
            required
            title="Type"
            placeholder="Please select a Type"
            value={type}
            onChange={type => {
              props.data.type = type;
              setType(props.data.type);
              props.onChange();
            }}
            values={eventTypes}
          />
          <div style={{display: 'flex', alignItems: 'center'}}>
            { ['range', 'box', 'background'].includes(type)
              ? 
                <Form.Item label="Position" required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Radio.Group
                    style={{ display: 'flex', flexDirection: 'column'}}
                    onChange={e => {
                      props.data.status = e.target.value;
                      setStatus(props.data.status);
                      props.onChange(); 
                    }}
                    value={status}
                  >
                    <Radio value={'START'}>Start</Radio>
                    <Radio value={'END'}>End</Radio>
                  </Radio.Group>
                </Form.Item>
              : null
            }
            <Button
              size='large'
              style={{marginTop: 20}}
              type={system.isEmpty(value) ? "default" : "primary"}
              onClick={() => {
                setOpenPayloadModal(true);
              }}
              icon={<BsFiletypeJson/>}
            />
          </div>
        </Col>
        <Col span={1}>
          <Divider style={{height: '100%'}} type="vertical" />
        </Col>
        <Col span={11} style={{paddingLeft: 16, paddingRight: 16}}>
          <Input
            title="Group"
            allowClear={true}
            value={group}
            type={system.type.Text}
            onChange={value => {
              props.data.group = value;
              setGroup(props.data.group);
              props.onChange();
            }}
          />
          <Input
            allowClear={true}
            required
            title="Name"
            value={name}
            type={system.type.Text}
            onChange={value => {
              props.data.name = value;
              setName(props.data.name);
              props.onChange();
            }}
          />
          <Input 
            title="Offset"
            required
            type={system.type.Duration} 
            value={offset} 
            onChange={(value) => {
              props.data.offset = value;
              setOffset(props.data.offset);
              props.onChange();
            }}
          />
        </Col>
        <Col span={1}>
          <Divider style={{height: '100%'}} type="vertical" />
        </Col>
        <Col span={1}>
        </Col>
      </Row>

      <Modal
        open={openPayloadModal}
        title="Edit Payload"
        width={'60vw'}
        centered
        onOk={() => {
          setOpenPayloadModal(false);
          props.data.value = value;
          setValue(props.data.value)
          props.onChange();
        }}
        onCancel={() => {
          setOpenPayloadModal(false);
        }}
        destroyOnClose={true}
      >
        <CodeMirror
          extensions={[langs.python()]}
          value={value == null || typeof value === 'object' ? '' : value}
          theme={vscodeDarkInit({
            settings: {
              caret: '#c6c6c6',
              fontFamily: 'monospace',
            }
          })}
          onChange={(value) => {
            if (value != null && value.trim() === '') {
              value = null
            }
            setValue(value)
          }}
          height={'800px'}
          onBlur={ev => {
            props.onChange();
          }}
          basicSetup={{
            foldGutter: true,
            allowMultipleSelections: false,
            autocompletion: false,
          }}
        />
      </Modal>
    </NodeCard>
  );
};

Component.displayName = 'TriggerEventNode';
export default Component;

