import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle  } from 'react';
import { renderToString } from 'react-dom/server';

import { BsAlignStart as StartFlag } from "react-icons/bs";
import { BsAlignEnd as FinishFlag } from "react-icons/bs";

import useAsyncEffect from 'use-async-effect';
import { Typography, Form, Skeleton, Select, Checkbox, notification, Button, Modal, Tooltip, Space, Alert, Menu, Row, Col, Badge, Card, Spin, Input as AntInput    } from 'antd';
import ImageViewer from '../../components/ImageViewer3';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)
import Timeline from '../../components/Timeline';
import { BsCameraFill as CaptureIcon } from 'react-icons/bs'
import { EllipsisOutlined  } from '@ant-design/icons';
import { AiOutlineEye as RangeFit } from "react-icons/ai";
import BackButton from '../../components/AppHeader/BackButton'
import { FaRegStopCircle } from "react-icons/fa";
import ImageModel from '../../models/Image';

import { SaveOutlined, EditOutlined, DeleteOutlined, SyncOutlined as RefreshIcon, 
  CloudDownloadOutlined as ImportIcon, 
  CloudUploadOutlined as ExportIcon  
} from '@ant-design/icons';
import SimulateForm from '../../forms/Simulate'
import { BsLayoutSplit as Horizontal } from "react-icons/bs";

import Input from '../../components/Input';
import { TbPlayerSkipForward as StepSimulateIcon } from "react-icons/tb";
import { TbPlayerPlay as SimulateIcon } from "react-icons/tb";

import {
  Route,
  Routes,
  Link,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';


import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined, SettingOutlined  } from '@ant-design/icons';

import { MdPlayArrow as PlayIcon } from "react-icons/md";
import { MdStop  as StopIcon } from "react-icons/md";
import { MdSkipNext as NextIcon } from "react-icons/md";
import { MdSkipPrevious as PrevIcon } from "react-icons/md";

import { BsPauseFill as PauseIcon } from 'react-icons/bs'
import { PiSquareSplitVerticalFill as SplitVertical, PiSquareSplitHorizontalFill as SplitHorizontal   } from "react-icons/pi";
import { AiOutlineClear as ClearSimulationIcon } from "react-icons/ai";


import { useStore } from '../../store';
import PageHeader from '../../components/PageHeader'

import moment from 'moment';
import Table from '../../components/Table';

import sys, { orm, sleep } from '../../system'
import SimulationModel from '../../models/Simulation'
import ProcessRunModel from '../../models/Process_Run'
import GeometryModel from '../../models/Geometry'
import ProcessEventModel from '../../models/Process_Event'

import './index.less'


const findNode = (processRun, nodeId) => {
  for (const sp of processRun.state.subprocess) {
    for (const node of sp.nodes) {
      if (node.id === nodeId) {
        return node
      }
    }
  }
  return null;
}

const ProcessRunDetails = ({image, simulationId}) => {
  const [processRun, setProcessRun] = useState(null);
  const navigate  = useNavigate();

  useAsyncEffect(async () => {
    if (image != null) {
      const processRun = await ProcessRunModel.selectFirst(`now = $now and simulation_id ${simulationId == null ? 'is null' : ' = $simulationId'}`, {
        now: image.now,
        simulationId: simulationId
      })
      setProcessRun(processRun)
    }
  }, [image]);

 
  const detectionElems = []
  for (const [nodeId, detection] of Object.entries(processRun?.carry?.detections || {})) {
    const node = findNode(processRun, nodeId)
    detectionElems.push(
      <div key={sys.uuid()}>
        {node.data.title} ({node.id})
        <div style={{paddingLeft: '50px'}}>
          {detection.map(d => {
            return (
              <div key={d.id}>{d.name} - {JSON.stringify(d.points)}</div>
            );
          })}
        </div>
      </div>
    );

  }

  return (
    <div>
      <div>
        <Input
          title='Vars'
          value={processRun?.carry?.vars}
          type={sys.type.Json}
          disabled
        />
      </div>
      <Button
        disabled={processRun == null}
        onClick={() => {
          window.open(`/processRun/${processRun?.id}`);
        }}
      >
        Go to Process Run
      </Button>
      <Typography.Text code copyable>
        {processRun?.id}
      </Typography.Text>
    <div>
      Detections
    </div>
    {detectionElems}
    </div>
  )
}




const Component = (props) => {
  const params = useParams();
  const location = useLocation()

  const internalState = useRef({
    simulating: false
  });
  const timelineRef = useRef()
  const [readOnly, setReadOnly] = useState(false);
  const [simulation, setSimulation] = useState(null);
  const [toBeSaved, setToBeSaved] = useState(false);
  const [processId, setProcessId] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const [running, setRunning] = useState(false);
  const [cleaning, setCleaning] = useState(false);
  const [image, setImage] = useState(null);
  const simulateForm = useRef()
  const [openSimulateModal, setOpenSimulateModal] = useState(false)
  const [sizes0, setSizes0] = useState([]);
  const [split0, setSplit0] = useState('vertical')
  const [sizes1, setSizes1] = useState([]);
  const [split1, setSplit1] = useState('horizontal')
  const [realMode, setRealMode] = useState(true)
  const [now, setNow] = useState(new Date());


  const onCleanSimulation = async (simulationId) => {
    setCleaning(true);
    try {
      const oldprocessRuns = await ProcessRunModel.select('simulation_id = $1', [simulationId]);
      const oldGeometries = await GeometryModel.select('simulation_id = $1', [simulationId]);
      const oldProcessEvents = await ProcessEventModel.select('simulation_id = $1', [simulationId]);
      const trx = sys.type.Transaction();
      oldprocessRuns.forEach(pr => {
        pr.delete = true;
        trx.add(pr);
      });
      oldGeometries.forEach(geo => { 
        geo.delete = true;
        trx.add(geo);
      });
      oldProcessEvents.forEach(event => {
        event.delete = true;
        trx.add(event);
      });
      await trx.save();~
      setNow(image.now)
    } catch (e) {
      console.log(e)
    }
    setCleaning(false);
  };

  const onStepSimulate = async () => {
    
    if (image == null) {
      return;
    }

    const oldProcessRun = await ProcessRunModel.selectFirst('simulation_id = $1 and now = $2', [simulation.id, image?.now]);
    if (oldProcessRun != null) {
      const oldGeometries = await GeometryModel.select('process_run_id = $1', [oldProcessRun.id]);
      const oldProcessEvents = await ProcessEventModel.select('process_run_id = $1', [oldProcessRun.id]);
      const trx = sys.type.Transaction();
      oldProcessRun.delete = true;
      trx.add(oldProcessRun);
      oldGeometries.forEach(geo => { 
        geo.delete = true;
        trx.add(geo);
      });
      oldProcessEvents.forEach(event => {
        event.delete = true;
        trx.add(event);
      });
      await trx.save();
      setNow(image.now)
    }


    let simulateFormState;
    if (simulateForm.current != null) {
      await simulateForm.current.saveState();
      simulateFormState = await simulateForm.current.getState();
    } else {
      simulateFormState = localStorage.getItem('simulate_state');
      if (simulateFormState != null) {
        try {
          simulateFormState = JSON.parse(simulateFormState);
        } catch (e) {
          console.log(e)
        }
      } 
    }

    if (simulateFormState == null || simulateFormState.workerId == null) {
      sys.notify({
        type: 'error',
        message: 'Required fields!'
      })
      setOpenSimulateModal(true);
      return;
    }


    const [response, nextImage] = await Promise.all([
      await sys.api(`worker/${simulateFormState.workerId}/run/${simulation.process_id}`, {
        method: 'POST',
        body: {
          now: image.now,
          simulation_id: simulation.id,
          use_draft: simulation.use_draft
        }
      }),
      await ImageModel.selectFirst('source_id = $1 and now > $2 order by now limit 1', [
        simulation.source_id,
        image.now,
      ])
    ])

    setNow(nextImage.now)

  }

  const onSimulate = async () => {
    setOpenSimulateModal(false)

    let simulateFormState;
    if (simulateForm.current != null) {
      await simulateForm.current.saveState();
      simulateFormState = await simulateForm.current.getState();
    } else {
      simulateFormState = localStorage.getItem('simulate_state');
      if (simulateFormState != null) {
        try {
          simulateFormState = JSON.parse(simulateFormState);
        } catch (e) {
          console.log(e)
        }
      } 
    }

    if (simulateFormState == null || simulateFormState.workerId == null) {
      sys.notify({
        type: 'error',
        message: 'Required fields!'
      })
      setOpenSimulateModal(true);
      return;
    }

    const notifyKey = sys.notify({
      type: 'success',
      message: 
        <div>
          <div>Simulation is Running!</div>
          <div style={{fontSize:10}}>You can stop it by pressing Stop</div>
        </div>,
      placement: 'top',
      duration: 0,
      type: 'warning',
      closeIcon: 
      <Button 
        type='text' 
        icon={<FaRegStopCircle size={22}/>} 
        onClick={() => {
          internalState.current.simulating = false;
        }}
      />
    })
    internalState.current.simulating = true;
    setRunning(internalState.current.simulating);

    await onCleanSimulation(simulation.id);

    ((async () => {
      try {
        let sImage = image;
        while (internalState.current.simulating == true) {
          if (sImage == null) {
            break;
          }
          const [response, nextImage] = await Promise.all([
            await sys.api(`worker/${simulateFormState.workerId}/run/${simulation.process_id}`, {
              method: 'POST',
              body: {
                now: sImage.now,
                simulation_id: simulation.id,
                use_draft: simulation.use_draft
              }
            }),
            await ImageModel.selectFirst('source_id = $1 and now > $2 order by now limit 1', [
              simulation.source_id,
              sImage.now,
            ])
          ])
          if (response.status != 200) {
            break
          }
          setNow(sImage.now)
          console.log('nextImage', sImage.now.toISOString(), nextImage.now.toISOString())
          sImage = nextImage;
        }
      } catch (e) {
        console.log(e)
      }

      internalState.current.simulating = false;
      setRunning(internalState.current.simulating)
      notification.destroy(notifyKey)
    })());
  };

  useAsyncEffect(async () => {
    const simulationId = params.simulationId;
    if (simulationId != null) {
      let simulation = await SimulationModel.get(simulationId);
      if (simulation == null) {
        simulation = SimulationModel.create({
          id: simulationId
        });
      }
      setSimulation(simulation);
      setRealMode(simulation.real_mode)
      setProcessId(simulation.process_id)
      setSourceId(simulation.source_id)
      setNow(dayjs(simulation.state.now))
    }
  }, []);

  useEffect(() => {
    setToBeSaved(true);
  }, [now]);

  return (
    <div style={{
      width: '100%',
      height: 'calc(100vh - 100px)'
    }}>
      <PageHeader>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', gap: 20}}>
          <BackButton/>
          <Tooltip title='Refresh' placement='bottom'>
            <Button
              type='text'
              icon={<Horizontal style={{transform: (split0 === 'horizontal' ? null : 'rotate(90deg)') }}/>}
              onClick={() => {
                if (split0 === 'horizontal') {
                  setSplit0('vertical')
                  setSplit1('horizontal')
                } else {
                  setSplit0('horizontal')
                  setSplit1('vertical')
                }
                timelineRef.current.fit()
              }}
            />
          </Tooltip>

          <Tooltip title='Refresh' placement='bottom'>
            <Button
              type='text'
              disabled={!toBeSaved || readOnly}
              icon={<RefreshIcon />}
              onClick={async () => {
                const s = await SimulationModel.get(simulation);
                setSimulation(s);
                setToBeSaved(false)
              }}
            />
          </Tooltip>

          <Tooltip title='Save' placement='bottom'>
            <Button
              type={toBeSaved && !readOnly ? 'default' : 'text' }
              danger={toBeSaved && !readOnly}
              disabled={!toBeSaved || readOnly}
              icon={<SaveOutlined />}
              onClick={async () => {
                simulation.state = {
                  ...simulation.state,
                  now: image?.now
                }
                await simulation.save();
                setToBeSaved(false);
              }}
            />
          </Tooltip>

          <div>
            <Button.Group>
            <Tooltip title={<div>Step Simulate</div>} placement='bottom'>
                <Button 
                  type='text' 
                  icon={<StepSimulateIcon size={16} />}
                  onClick={onStepSimulate}
                  disabled={running || readOnly}
                />
              </Tooltip>
              <Tooltip title={<div>Simulate <kbd>CTRL + R</kbd></div>} placement='bottom'>
                <Button 
                  type='text' 
                  icon={<SimulateIcon size={16} />}
                  onClick={onSimulate}
                  loading={running}
                />
              </Tooltip>
              <Tooltip title={<div>Clean Simulation</div>} placement='bottom'>
                <Button 
                  type='text' 
                  icon={<ClearSimulationIcon size={16} />}
                  onClick={() => {
                    if (simulation != null) {
                      onCleanSimulation(simulation.id)
                    }
                  }}
                  loading={cleaning}
                />
              </Tooltip>
              <Button
                type='text' 
                icon={<EllipsisOutlined />}
                disabled={toBeSaved || running || readOnly}
                onClick={async () => {
                  setOpenSimulateModal(true);
                }}
              />
            </Button.Group>
          </div>

          <Typography.Title 
            style={{margin: 0}} 
            level={5} 
            editable={{
              autoSize: { maxRows: 1 },
              triggerType: 'text',
              onChange: (value) => {
                if (value === simulation.name) {
                  return;
                }
                simulation.name = value;
                setToBeSaved(true);
              }
          }}
          >
            {simulation?.name}
          </Typography.Title>
          <Typography.Text 
            style={{flex: 'auto', margin: 0, minWidth: 300 }} 
            level={5} 
            editable={{
              autoSize: { maxRows: 1 },
              onChange: (value) => {
                if (value === simulation.description) {
                  return;
                }
                simulation.description = value;
                setToBeSaved(true);
              }
          }}>
            {simulation?.description}
          </Typography.Text>
          <div style={{width: 120}}>
            <Input 
              model={simulation}
              column='status'
              onChange={(value) => {
                simulation.status = value;
                setToBeSaved(true);
              }}
            />
          </div>
        </div>
      </PageHeader>


      <SplitPane 
        split={split0}
        sizes={sizes0}
        performanceMode={false}
        onChange={(sizes) => {        
          setSizes0(sizes)
          timelineRef.current.fit()
        }}
      >
        <Pane>
          <SplitPane 
            split={split1}
            sizes={sizes1}
            performanceMode={false}
            onChange={(sizes) => {        
              setSizes1(sizes)
              timelineRef.current.fit()
            }}
          >
            <Pane>
            <Row gutter={10}>
            <Col span={6}>
              <Input 
                title={'Source'} 
                model={simulation} 
                column='source_id'
                onChange={(value) => {
                  simulation.source_id = value
                  setSourceId(value)
                  setToBeSaved(true)
                }}
                required
              />
            </Col>
            <Col span={6}>
              <Input 
                title={'Process'} 
                model={simulation} 
                column='process_id' 
                onChange={(value) => {
                  simulation.process_id = value
                  setProcessId(value)
                  setToBeSaved(true)
                }}
                required
              />
            </Col>
            <Col>
              <Input
                title='Now'
                type={sys.type.DateTime}
                value={now}
                onChange={value => {
                  setNow(value.toDate())
                }}
                allowClear={false}
              ></Input>
            </Col>
            <Col span={1}>
            </Col>
            <Col >
              <Input
                title='Real Mode'
                model={simulation}
                column='real_mode'
                switch
                onChange={(value) => {
                  simulation.real_mode = value
                  setRealMode(value)
                  setToBeSaved(true)
                }}
              ></Input>
            </Col>
            <Col >
              <Input
                title='Use Draft'
                model={simulation}
                column='use_draft'
                switch
                onChange={(value) => {
                  simulation.use_draft = value
                  setToBeSaved(true)
                }}
              />
            </Col>
            <Col flex={'auto'}>
            </Col>
          </Row>
              <Timeline
                ref={timelineRef}
                time={now}
                sourceId={sourceId}
                processId={processId}
                onRangeChange={(range) => {
                  //console.log('onRangeChange callback', range)
                }}
                simulationId={realMode == true ? undefined : simulation?.id}
                onChangeImage={(image) => {
                  setImage(image)
                  setNow(image?.now)
                }}
              />
            </Pane>
            <Pane style={{padding: 10}}>
              <ProcessRunDetails
                image={image}
                simulationId={realMode == true ? undefined : simulation?.id}
              />
            </Pane>
          </SplitPane>
        </Pane>
        <Pane>
          <ImageViewer
            showTimestamp={true}
            image={image}
            showPolygons={true}
            showBoxes={true}
            showLabels={false}
            showConfidence={false}
            showTags={false}
            showGeometry={true}
            simulationId={realMode == true ? undefined : simulation?.id}
          ></ImageViewer>
        </Pane>
      </SplitPane>
     

      <Modal 
        title="Simulate" 
        open={openSimulateModal} 
        destroyOnClose={true}
        onOk={onSimulate}
        width={'400px'}
        onCancel={() => { 
          setOpenSimulateModal(false);
        }}
      >
        <SimulateForm ref={simulateForm}></SimulateForm>
      </Modal>
    </div>
  );
};

export default Component;