

import { create } from 'zustand';
import { persist, subscribeWithSelector } from "zustand/middleware"


export const usePresistentStore = create(persist(
  (set, get) => ({
    theme: 'light',
    setTheme: (theme) => {
      set({ theme });
    },
    collapsed: false,
    setCollapsed: (collapsed) => {
      set({collapsed})
    },
  }),{
    name: "vpm-store"
  }
));

export const useStore = create(subscribeWithSelector(
  (set, get) => ({
    makeModel: null,
    user: null,
    person: null,
    accessToken: null,
    setAccessToken: (accessToken, previousAccessToken) => {
      set({ accessToken });
    },
    states: undefined,
    cities: undefined,
    colors: undefined,
    sites: undefined,
    costs: undefined
  })
));