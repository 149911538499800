import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu, Select } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store';

import Input from '../components/Input'
import ImageClass from '../components/ImageAnnotator/ImageClass.jsx'
import PersonModel from '../models/Person';
import sys from '../system';

import modelModel from '../models/Model';
import WorkerModel from '../models/Worker';

const Component = forwardRef((props, ref) => {
 
  let previousState = localStorage.getItem('simulate_state');
  if (previousState != null) {
    previousState = JSON.parse(previousState)
  }

  const [state, setState] = useState(previousState || {})
  if (state.workerId === undefined) {
    state.workerId = null;
  }
  
  useImperativeHandle(ref, () => ({
    getState: () => {
      return state
    },
    saveState: () => {
      localStorage.setItem('simulate_state', JSON.stringify(state))
    }
  }));

  const [workers, setWorkers] = useState([]);

  useAsyncEffect(async () => {
    const workers = await WorkerModel.select("STATUS = 'ACTIVE'");
    console.log('workers', workers)
    setWorkers(workers.map(r => ({ value: r.id, label: r.name })));
  }, []);

  return (
    <>
      <Input 
        title='Worker' 
        type={sys.type.Text}
        value={state.workerId}
        onChange={workerId => {
          setState({...state, workerId})
        }}
        values={workers}
        required
      />
    </>
  );
});

Component.displayName = 'SimulateForm';
export default Component;