import React, { useState, useRef, useEffect } from 'react';
import { RollbackOutlined } from '@ant-design/icons';
import {
    useNavigate,
  } from "react-router-dom";
import { Button, Tooltip } from 'antd';


const Component = (props) => {
  const navigate = useNavigate();
  return (
    props.to != null || (window.history.state && window.history.state.idx > 0)
    ?
    <Tooltip title='Back' placement='bottom'>
        <Button type='text' icon={<RollbackOutlined />} onClick={() => navigate(props.to || -1, { replace: true })}></Button>
    </Tooltip>
    : null
  );
};
export default Component;