import { im, number } from 'mathjs';
import sys, { type } from '../system';
import { assign, defaults } from 'lodash';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime, Integer, Cron } = type;

const calcPrioirity = (urgency, impact) => {
    if (urgency == 1 && impact == 1) return 1;
    if (urgency == 1 && impact == 2) return 2;
    if (urgency == 1 && impact == 3) return 3;
    if (urgency == 2 && impact == 1) return 2;
    if (urgency == 2 && impact == 2) return 3;
    if (urgency == 2 && impact == 3) return 4;
    if (urgency == 3 && impact == 1) return 3;
    if (urgency == 3 && impact == 2) return 4;
    if (urgency == 3 && impact == 3) return 5;
    return null;
}

export default Table({
    name: 'Ticket',
    table: 'ticket',
    columns: {
        number: Number({
            title: 'Number',
            description: 'Ticket Number',
            persistent: false,
        }),
        description: Text({
            title: 'Description',
            description: "Should breifly describe the Ticket and what's for.",
        }),
        summary: Text({
            title: 'Summary',
            description: "Can be used to summarize the Ticket's description or to provide a quick overview of the Ticket.",
        }),
        priority: Text({
            title: 'Priority',
            values: ['Low', 'Medium', 'Hight']
        }),
        status: Text({
            title: 'Status',
            defaultsTo: 'New',
            values: ['New', 'Analysis', 'Approved', 'In Progress', 'Complete', 'Closed', 'Canceled']
        }),
        impact: Number({
            title: 'Impact',
            description: 'Impact of the Ticket',
            values: [ { value: 1, label: 'Low' }, { value: 2, label: 'Medium' }, { value: 3, label: 'High' }],
            defaultsTo: 1,
            onChange: (value, row) => {
                const impact = value;
                const urgency = row.urgency;
                row.priority = calcPrioirity(urgency, impact);
                return value;
            },
        }),
        urgency: Number({
            title: 'Urgency',
            description: 'Urgency of the Ticket',
            values: [ { value: 1, label: 'Low' }, { value: 2, label: 'Medium' }, { value: 3, label: 'High' }],
            defaultsTo: 1,
            onChange: (value, row) => {
                const urgency = value;
                const impact = row.impact;
                row.priority = calcPrioirity(urgency, impact);
                return value;
            }
        }),
        priority: Number({
            title: 'Priority',
            description: 'Priority of the Ticket',
            defaultsTo: 1,
            values: [
                { value: 1, label: 'Very Low' }, 
                { value: 2, label: 'Low' }, 
                { value: 3, label: 'Medium' },
                { value: 4, label: 'High' },
                { value: 5, label: 'Very High' }
            ]
        }),
        sched_finish: DateTime({
            title: 'Scheduled Finish',
            description: 'When the Ticket is Scheduled to be Complete',
        }),
        assign: Json(),
        created_at: DateTime({
            title: 'Created At',
            defaultsTo: () => sys.now(),
        }),
        created_by: Uuid({
            defaultsTo: () => sys.getUser()?.id
        }),
    }
})