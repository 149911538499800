import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, PageHeader, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';


import sys from '../system'

import PersonForm from '../forms/Person';
import ReportViewer from '../components/ReportViewer';

const Component = () => {

  const [ showForm, setShowForm ] = useState(false);
  const [ showReport, setShowReport ] = useState(false);



  let overflow = [];
  for (let i = 0; i < 1000; i++) {
    overflow.push(<Typography>Overflow</Typography>);
  }

  return (
    <div style={{
    }}>
      <Button onClick={() => setShowForm(true)}> show form </Button>
      <Modal open={showForm} onOk={() => setShowForm(false)} onCancel={() => setShowForm(false)} destroyOnClose={true}>
        <PersonForm></PersonForm>
      </Modal>

      <Button onClick={() => setShowReport(true)}> Show Report </Button>
      <Modal open={showReport} onOk={() => setShowReport(false)} onCancel={() => setShowReport(false)} destroyOnClose={true}>
        <ReportViewer rptDesign='hello_world.rptdesign' parameters={{teste:'teste'}}></ReportViewer>
      </Modal>
    </div>
  );
};
export default Component;