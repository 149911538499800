import React, { memo, useState, useRef } from 'react';
import { Position } from 'reactflow';
import Handle from '../../../components/ProcessEditor/Handle'
import { generate, green, presetPalettes, red } from '@ant-design/colors';

import { Select, Row, Col, Space, Divider, Form, ColorPicker, Card, Input, Modal, Checkbox, Button, InputNumber, theme  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { Popover, Tooltip  } from 'antd';

import {
  EyeOutlined, EyeFilled, EyeTwoTone, BgColorsOutlined, PlusOutlined
} from '@ant-design/icons';

import ModelModel from '../../../models/Model'
import sys from '../../../system'
import NodeCard from '../../../components/ProcessEditor/NodeCard'
import GeometryStylesForm from '../../../forms/GeometryStyles'
import GeometryStyle from '../../../models/GeometryStyle';

const colorPresets = [
  {
    "label": "RGB",
    "colors": [
        "#ff1478" + "00",
        "#ff1478" + "1a",
        "#ff1478" + "33",
        "#ff1478" + "4d",
        "#ff1478" + "66",
        "#ff1478" + "80",
        "#ff1478" + "99",
        "#ff1478" + "b3",
        "#ff1478" + "cc",
        "#ff1478" + "ff",

        "#14ff78" + "00",
        "#14ff78" + "1a",
        "#14ff78" + "33",
        "#14ff78" + "4d",
        "#14ff78" + "66",
        "#14ff78" + "80",
        "#14ff78" + "99",
        "#14ff78" + "b3",
        "#14ff78" + "cc",
        "#14ff78" + "ff",

        "#1478FF" + "00",
        "#1478FF" + "1a",
        "#1478FF" + "33",
        "#1478FF" + "4d",
        "#1478FF" + "66",
        "#1478FF" + "80",
        "#1478FF" + "99",
        "#1478FF" + "b3",
        "#1478FF" + "cc",
        "#1478FF" + "ff",
    ]
  }, {
  "label": "Gray Scale",
    "colors": [
        "#000000" + "00",
        "#000000" + "1a",
        "#000000" + "33",
        "#000000" + "4d",
        "#000000" + "66",
        "#000000" + "80",
        "#000000" + "99",
        "#000000" + "b3",
        "#000000" + "cc",
        "#000000" + "ff",
    ]
  }
]

const Component = props => {

  if (props.data.strokeColor == null) {
    props.data.strokeColor = '#0000ff80';
  }
  if (props.data.fillColor == null) {
    props.data.fillColor = '#0000ff1a';
  }
  if (props.data.active == null) {
    props.data.active = true
  }

  const [name, setName] = useState(props.data.name);
  const [strokeColor, setStrokeColor] = useState(props.data.strokeColor);
  const [fillColor, setFillColor] = useState(props.data.fillColor);
  const [active, setActive] = useState(props.data.active);
  /*
  const geometryModalRef = useRef();
  const addInputRef = useRef();
  const [newName, setNewName] = useState();
  const [openGeometryModal, setOpenGeometryModal] = useState(false);

  const [geoStyles, setNames] = useState([]);

  useAsyncEffect(async () => {
    const names = await GeometryStyle.selectAll();
    //setNames(names);
  }, []);
  const nameCanBeAdded = sys.isEmpty(newName) || geoStyles.find((g) => g.name === newName.trim());
  */
 
  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='1'
          name='activation'
          style={{ top: '40%' }}
          title="Activate"
          accepts={['any']}
          activation
        />,
        <Handle
          key='2'
          name='polyset'
          title="[Polyset, Polygon] a to be saved"
          style={{ top: '66%', }}
          accepts={['polyset', 'poly']}
          maxConnections={1}
        />,
        <Handle
        key='3'
        name='image'
          style={{ top: '85%', }}
          title="Associated [Image]"
          accepts={['image']}
          />,
        <Handle
          key='4'
          name='out'
          style={{ top: '50%' }}
          title='Produces the same [Polyset]'
          produces={['polyset']}
          activation
        />
      ]}
    >
      <Form style={{width: 260, padding: 16 }}>
          <Form.Item label="Active" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Checkbox
              checked={active}
              onChange={(ev) => {
                props.data.active = ev.target.checked;
                setActive(props.data.active);
                props.onChange();
              }}
            />
          </Form.Item>
          <Form.Item label="Name" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Input
              value={name}
              onChange={ev => {
                props.data.name = ev.target.value;
                setName(props.data.name)
                props.onChange();
              }}
            />
          </Form.Item>
            <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
              <Form.Item label="Fill Color">
                <ColorPicker 
                  value={fillColor} 
                  onChange={(color, hex) => {
                    props.data.fillColor = hex;
                    setFillColor(props.data.fillColor);
                    props.onChange();
                  }}
                  presets={colorPresets}
                  />
              </Form.Item>
              <Form.Item label="Stroke Color">
                <ColorPicker
                  value={strokeColor} 
                  onChange={(color, hex) => {
                    props.data.strokeColor = hex;
                    setStrokeColor(props.data.strokeColor);
                    props.onChange();
                  }}
                  presets={colorPresets}
                />
              </Form.Item>
            </div>
      </Form>
    </NodeCard>
  )
  /*
  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='1'
          name='activation'
          style={{ top: '40%' }}
          title="Activate"
          accepts={['any']}
          activation
        />,
        <Handle
          key='2'
          name='polyset'
          title="[Polyset] a to be saved"
          style={{ top: '66%', }}
          accepts={['polyset', 'poly']}
        />,
        <Handle
        key='3'
        name='image'
          style={{ top: '85%', }}
          title="Associated [Image]"
          accepts={['image']}
          />,
        <Handle
          key='4'
          name='out'
          style={{ top: '50%' }}
          title='Produces the same [Polyset]'
          produces={['polyset']}
          activation
        />
      ]}
    >
      <Form
        style={{
          width: 260, 
          padding: 16, 
        }}
      >
        <Space.Compact style={{display: 'flex', flexDirection: 'row'}}>
          <Form.Item label="Name" style={{flex:'auto'}}>
            <Select
              style={{ width: '100%' }}
              placeholder="Event Name"
              value={name}
              popupMatchSelectWidth={false}
              on
              options={geoStyles.map((gs) => ({
                id: gs.id,
                value: gs.id,
                name: gs.name,
                strokeColor: gs.style?.strokeColor,
                fillColor: gs.style?.fillColor,
              }))}
              onChange={(id) => {
                const gs = geoStyles.find((g) => g.id === id);
                props.data.name = gs.name;
                props.data.fillColor = gs.style?.fillColor;
                props.data.strokeColor = gs.style?.strokeColor;
                setName(props.data.name)
                props.onChange();
              }}
              optionRender={(option) => {
                return (
                  <Row align="middle">
                    <Col flex="auto">
                      {option.data.name}
                    </Col>
                    <Col span={4}>
                      <ColorPicker
                        value={option.data.fillColor} 
                        disabled 
                      />
                    </Col>
                    <Col>
                      <ColorPicker
                        value={option.data.strokeColor} 
                        disabled 
                      />
                    </Col>
                  </Row>
                )
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <Space
                    style={{
                      padding: '0 8px 4px',
                    }}
                  >
                    <Input
                      placeholder="Please enter name"
                      ref={addInputRef}
                      onChange={(event) => {
                        setNewName(event.target.value);
                      }}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button 
                      type="text" 
                      icon={<PlusOutlined />} 
                      onClick={async () => {
                        const gs = await GeometryStyle.create({name: newName});
                        await gs.save();
                        props.data.name = newName;
                        setName(props.data.name)
                        setNewName(null);
                        setNames([...geoStyles, gs]);
                        props.onChange();
                      }}
                      disabled={nameCanBeAdded}
                    >
                      Add item
                    </Button>
                  </Space>
                </>
              )}
            />
          </Form.Item>
          <Form.Item>
            <Button onClick={() => { setOpenGeometryModal(true) }} icon={<BgColorsOutlined />}></Button>
          </Form.Item>
        </Space.Compact>
      </Form>

      <Modal
        title="Geometry Options"
        open={openGeometryModal}
        destroyOnClose={true}
        onOk={async () => {
          try {
            await geometryModalRef.current.save();
            setOpenGeometryModal(false);
          } catch (e) {
            sys.notify(e);
          }
        }}
        width={'600px'}
        onCancel={() => {
          setOpenGeometryModal(false);
        }}
      >
        <GeometryStylesForm ref={geometryModalRef} filter={{name: name}}></GeometryStylesForm>
      </Modal>
    </NodeCard>
  );
  */
};

Component.displayName = 'SaveGeometryNode';
export default Component;

