import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, theme, Row, Col, Typography, Space, Transfer, Tabs, List, Menu, Input, Collapse  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { EyeOutlined } from '@ant-design/icons'

import ImageModel from '../../models/Image';
import imageUtils from '../../utils/image';
import sys from './../../system';

const { useToken } = theme;


const Component = (props) => {

  const containerRef = useRef(null);
  const timerName = useRef(sys.uuid());
  const [update, setUpdate] = useState(sys.uuid());
  const [image, setImage] = useState();
  const [url, setUrl] = useState()
  const { token } = useToken();

  useAsyncEffect(async () => {
    let image;
    if (props.imageId != null) {
      image = await ImageModel.get(props.imageId);
    } else if (props.image != null) {
      image = props.image;
    }

    if (image != null) {

      if (props.showBoxes || props.showPolygons) {
        const geometry = []
        if (props.showGeometry) {
          geometry.push(...await image.geometry)
        }
        if (props.showAnnotations) {
          geometry.push(...await image.annotations)
        }
        image.svgBoundigBox = await imageUtils.toSvgBoundingBox(image, geometry, {
          showLabels: props.showLabels,
          showConfidence: props.showConfidence,
          showBoxes: props.showBoxes,
          showPolygons: props.showPolygons
        });
      }
      
      const width = containerRef?.current?.offsetWidth;
      const height = containerRef?.current?.offsetHeight;
      const url = sys.image.url(image.src, { width, height });
      
      await sys.image.preload(url)
      setUrl(url)
      setImage(image);
    }
  }, [props.imageId, props.image, props.showBoxes, props.showLabels, props.showPolygons, props.showConfidence, update]);


  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      sys.timers.start(`Viewer-Refresh-${timerName.current}`, () => {
        setUpdate(sys.uuid())
      }, 1000, 1)
    });
    resizeObserver.observe(containerRef.current);
    return () => { 
      resizeObserver.disconnect();
    }
  }, []);
  
  return (
    <div 
      ref={containerRef}
      style={{
        display: 'flex',
        backgroundColor: token.colorIconHover,
        width: '100%',
        height: '100%',
        minHeight: 135,
        minWidth: 240,
      }}
      onClick={props.onClick}
    >
      {
        image != null ?
        <svg
          style={{
            border: '1px solid black',
            boxShadow: '2px 2px 3px gray',
            backgroundImage: `url(${url})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            transition: 'background-image 0.1s ease-in-out'
          }}
          width='100%'
          height='100%'
          viewBox={`0 0 ${image?.width} ${image?.height}`}
        >
          <g>
            {image.svgBoundigBox}
          </g>
        </svg>
        : null
      }
    </div>
  )
};

Component.displayName = 'ImageViewer';
export default Component;