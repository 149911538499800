import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu } from 'antd';
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store';

import Input from '../components/Input'

import ModelModel from '../models/Model';


const Component = forwardRef((props, ref) => {
 
  const [model, setModel] = useState();
  
  useImperativeHandle(ref, () => ({
    save: async () => {
      await model.save(); 
    }
  }));

  useAsyncEffect(async () => {
    let model;
    if (props.id == null) {
      model = await ModelModel.create();
    } else {
      model = await ModelModel.get(props.id);
    }
    setModel(model);
  }, []);

  return (
    <>  
        <Input model={model} column={'id'} disabled></Input>
        <Input model={model} column={'name'} required></Input>
        <Input model={model} column={'description'}></Input>
        <Input model={model} column={'status'}></Input>
        <Input model={model} column={'config'}></Input>
        <Input model={model} column={'src'}></Input>
    </>
  );
});

Component.displayName = 'Component';
export default Component;