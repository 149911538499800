import React, { useState, useRef, useEffect, useCallback, useMemo, memo } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Tabs, Checkbox, Progress, Button, Modal, Space, Tag, Alert, Menu, Statistic, Row, Col, Badge, Card, Spin, Select, Drawer, Tooltip, Transfer    } from 'antd';
import imageUtils, { stringToColor } from '../../utils/image';

import { SaveOutlined, EditOutlined, DeleteOutlined, SyncOutlined as RefreshIcon, 
  CloudDownloadOutlined as ImportIcon, 
  CloudUploadOutlined as ExportIcon  
} from '@ant-design/icons';

import sys from '../../system'
import SourceModel from '../../models/Source'

const percent = (value, count) => {
  if (count == null || count == 0) {
    return 0
  }
  return value / count
}

const SourceWarnings = (props) => {
  const { source } = props

  const [stats, setStats] = useState([])
  const [refresh, setRefresh] = useState(0)


  useAsyncEffect(async () => {
    const tags = await props.dataset.getTagsWhere()
  


    let stats = await sys.orm(`select
      (
        select count(*) from image i where i.source_id = $1 and i.id in (select ref_id from tag where name in (${tags}))
      ) count,
      (
        select count(*) from image i where i.source_id = $1 and i.id in (select ref_id from tag where name in (${tags}))
        and i.id in (select ref_id from tag where name = 'BACKGROUND')
      ) background,
      (
        select count(*) from image i where i.source_id = $1 and i.id in (select ref_id from tag where name in (${tags}))
        and i.id in (select ref_id from tag where name = 'NIGHT')
      ) night,
      (
        select count(*) from image i where i.source_id = $1 and i.id in (select ref_id from tag where name in (${tags}))
        and i.id in (select ref_id from tag where name = 'DAY')
      ) "day",
      (
        select count(*) from image i where i.source_id = $1 and i.id in (select ref_id from tag where name in (${tags}))
        and i.id in (select ref_id from tag where name = 'RAIN')
      ) rain
    `, [source.id])
    
    stats = stats[0]
    stats.count = parseInt(stats.count)
    stats.background = parseInt(stats.background)
    stats.backgroundPercent = percent(stats.background, stats.count)
    stats.day = parseInt(stats.day)
    stats.dayPercent = percent(stats.day, stats.count)
    stats.night = parseInt(stats.night)
    stats.nightPercent = percent(stats.night, stats.count)
    stats.rain = parseInt(stats.rain)
    stats.rainPercent = percent(stats.rain, stats.count)

    setStats(stats)    
  }, [refresh]);

  const warnings = [];

  if (stats != null) {

    {
      const warning = {
        message: 'At least 70',
        level: stats.count < 30 ? 'high' : stats.count < 50 ? 'medium' : stats.count < 70 ? 'low' : 'success',
        count: stats.count
      }
      warnings.push(warning)
    }

    {
      const warning = {
        message: 'BACKGROUND between 20% and 35%',
        level: stats.backgroundPercent > 0.50 || stats.backgroundPercent < 0.10 ? 'high' 
              : stats.backgroundPercent > 0.40 || stats.backgroundPercent < 0.15 ? 'medium' 
              : stats.backgroundPercent > 0.35 || stats.backgroundPercent < 0.20 ? 'low' 
              : 'success',        total: stats.count,
        count: stats.background,
        percent: stats.backgroundPercent
      }  
      warnings.push(warning)
    }

    {
      const offset = Math.abs( - 0.50);
      const warning = {
        message: 'DAY between 30% and 70%',
        level: stats.dayPercent > 0.9 || stats.dayPercent < 0.1 ? 'high' 
              : stats.dayPercent > 0.8 || stats.dayPercent < 0.2 ? 'medium' 
              : stats.dayPercent > 0.7 || stats.dayPercent < 0.3 ? 'low' 
              : 'success',
        total: stats.count,
        count: stats.day,
        percent: stats.dayPercent
      }  
      warnings.push(warning)
    }
    {
      const warning = {
        message: 'NIGHT between 30% and 70%',
        level: stats.nightPercent > 0.9 || stats.nightPercent < 0.1 ? 'high' 
              : stats.nightPercent > 0.8 || stats.nightPercent < 0.2 ? 'medium' 
              : stats.nightPercent > 0.7 || stats.nightPercent < 0.3 ? 'low' 
              : 'success',
        total: stats.count,
        count: stats.night,
        percent: stats.nightPercent
      }  
      warnings.push(warning)
    }

  }


  return (
    <Card 
      style={{width: '400px'}}
      styles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
          gap: 20,
        }
      }}
      title={source.name}
      extra={<Button type='text' icon={<RefreshIcon/>} onClick={() => {
        setRefresh(refresh + 1)
      }}/>}
    >
      {
        warnings.map((w,i) => {
          return (
            <Alert
              key={`w-${i}`}
              type={w.level == 'high' ? 'error' : w.level == 'medium' ? 'warning' : w.level == 'low' ? 'info' : 'success'}
              message={
                <div style={{display: 'flex', gap: 10}}>
                  <div style={{flex:'auto'}}>{w.message}</div>
                  
                  {
                    w.count != null && w.total != null
                    ? <div>{w.count}/{w.total}</div> 
                    : w.count != null
                    ? <div>{w.count}</div> 
                    : null 
                  }
                  
                  { w.percent != null ? <div>{parseInt(w.percent*100) }%</div> : null }
                
                </div>
              }
            />
          )
        })
      }

    </Card>
  )
}



const Component = ({dataset}) => {
  
  const [sources, setSources] = useState([])
  
  useAsyncEffect(async () => {
    const s = await SourceModel.select("status = 'ACTIVE' order by name")
    setSources(s)
  }, []);
  

  return (
    <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 10}}>
      {
        sources.map((s, i) => {
          return (<SourceWarnings dataset={dataset} source={s} key={i}/>)
        })
      }
    </div>
  );
}

Component.displayName = 'ValidationSetStats';
export default Component;
