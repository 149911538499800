import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Tag, Space, Transfer, Tabs, List, Menu, Input, Collapse  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { EyeOutlined } from '@ant-design/icons'

import imageUtils from './../../utils/image';

const Component = (props) => {
  return (<Tag color={imageUtils.stringToColor(props.name)}>{props.name}</Tag>);
};

Component.displayName = 'ImageClass';
export default Component;