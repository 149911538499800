import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { Table, Typography, Input, Form, Skeleton, Checkbox, PageHeader, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Pagination, Tag, Dropdown, Popconfirm, message  } from 'antd';
import { FilterFilled, ClearOutlined, TableOutlined, DownloadOutlined, LoadingOutlined, ReloadOutlined   } from '@ant-design/icons';
import { SiMicrosoftexcel } from 'react-icons/si'
import moment from 'moment';
import _, { filter } from 'lodash';
import useAsyncEffect from 'use-async-effect';
import sys from '../../system'
const { Text, DateTime, Json, Integer, Number, Uuid, Id, Boolean } = sys.type;
import { useSearchParams } from "react-router-dom";

import InputSearch from './InputSearch';

const composeQuery = (columns) => {
  let sqlWhere = [];
  columns
    .filter(c => Array.isArray(c.filter))
    .forEach(c => {
      c.filter.forEach(value => {
        if (typeof c.toWhere === 'function') {
          sqlWhere.push(c.toWhere(value));
        } else {
          sqlWhere.push(sys.sql.toWhere(c.name, c.type, value));
        }
      })
  })
  sqlWhere = sqlWhere.filter(e => e != null).join(' and ');
  return sqlWhere === '' ? undefined : sqlWhere;
}

const parseUrlToQuery = (props, searchParams) => {
  
  let params = {};
  if (props.id != null && searchParams.get(props.id)) {
    params = searchParams.get(props.id);
    params = atob(params);
    params = JSON.parse(params);
  }
  

  const query = { 
    ...props.query, 
    where: params.where || props.query.where || {},
    orderBy: params.orderBy || props.query.orderBy || {},
    offset: params.offset || props.query.offset || 0,
    limit: params.limit || props.query.limit || 50, 
  };
  //console.log('parseUrlToQuery', query);
  return query;
};

const stringifyQueryToUrl = (props, query) => {
  if (props.id == null) {
    return {};
  }

  const compactQuery = {};

  // limit
  compactQuery.limit = query.limit;
  if (compactQuery.limit === 50 || compactQuery.limit == props.query.limit) {
    delete compactQuery.limit;
  }

  // offset
  compactQuery.offset = query.offset;
  if (compactQuery.offset === 0 || compactQuery.offset == props.query.offset) {
    delete compactQuery.offset;
  }
  
  // orderBy
  compactQuery.orderBy = { ...query.orderBy };
  if (_.isEqual(compactQuery.orderBy, props.query.orderBy)) {
    delete compactQuery.orderBy;
  }
  
  // where
  compactQuery.where = { ...query.where };
  if (compactQuery.where != null) {
    for (const [k, v] of Object.entries(compactQuery.where)) {
      if (v == null || v.length === 0) {
        delete compactQuery.where[k];
      } 
    }
    if (Object.keys(compactQuery.where).length === 0) {
      delete compactQuery.where;
    }
    if (_.isEqual(compactQuery.orderBy, props.query.orderBy)) {
      delete compactQuery.orderBy;
    }
  }


  if (Object.keys(compactQuery).length === 0) {
    return ({});
  } else {
    return ({
      [props.id]: btoa(JSON.stringify(compactQuery)).replace(/=+$/,'')
    });
  }
}

const Component = forwardRef((props, ref) => {
  
  const destroying = useRef(false);
  const [exportRequested, setExportRequested] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  
  
  const [query, setQuery] = useState(parseUrlToQuery(props, searchParams));

  const [totalRows, setTotalRows] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  let [columns, setColumns] = useState(
    _.cloneDeep(props.columns)
    .filter(c => c.mobile == null || c.mobile == sys.screen.isMobile())
  );
  columns
  .forEach((c,i) => {
    

    // column.filterIcon
    let filterIcon = c.filterIcon;

    // column.filterDropdown
    let filterDropdown = c.filterDropdown;

    // column.filter
    if (c.filter === true || Array.isArray(c.filter)) {
      c.filter = Array.isArray(c.filter) ? c.filter : [];
      filterIcon = <FilterFilled style={{ color: (c.filter.length > 0 ? '#1890ff' : undefined) }}/>;
      filterDropdown = () => 
        <InputSearch values={c.filter} 
          onChange={values => { 
            c.filter = values; 
            setLoading(true); 
            setColumns([...columns]); 
          }}
        />;
    }

    // column.render
    if (c.render == null) {

      if (c.type === Text) {
        c.render = (text, record, index) => { 
          const value = record[c.name];
          return <div style={{overflowWrap: 'anywhere'}}>{value}</div>;
        }
      } else if (c.type === Boolean) {
        c.render = (text, record, index) => {
          const v = record[c.name];
          return (
            <div style={{overflowWrap: 'normal', ...c.style}}>
              <Checkbox checked={v} disabled/>
            </div>
          );
        }
      } else if (c.type === DateTime) {
        if (c.width == null) {
          c.width = '180px'
        }
        c.render = (text, record, index) => {
          const v = record[c.name];
          if (v == null) {
            return null;
          }
          const m = moment(v);
          return (
            <div style={{overflowWrap: 'normal', ...c.style}}>
              {sys.format.datetime(m)}
            </div>
          );
        }
      } else if (c.type === Number || c.type === Integer) {
        if (c.width == null) {
          c.width = '120px'
        }
        c.render = (text, record, index) => { 
          const value = record[c.name];
          return <div style={{...c.style}}>{value}</div>;
        }
      } else  if (c.type === Uuid) {
        if (c.width == null) {
          c.width = '160px'
        }
        c.render = (text, record, index) => { 
          const value = record[c.name];
          return (
            <div style={c.style}>
              <div style={{
                fontFamily: 'monospace', 
                lineHeight: '11px', 
                fontSize: '11px',
                borderLeft: '1px solid black',
                paddingLeft: 5,
                borderRight: '1px solid black',
                paddingRight: 5,
                width: 'fit-content'
              }}>
                <div>
                  <code>
                    {value.replace(/-/ig, '').substring(0,16)}
                  </code>
                </div>
                <div>
                  <code>
                    {value.replace(/-/ig, '').substring(16)}
                  </code>
                </div>
              </div>
            </div>
          );
        }
      }
    }    
    c.sortOrder = ({'asc': 'ascend', 'desc': 'descend'})[query.orderBy[c.name]];
    c.filterIcon = filterIcon;
    c.filterDropdown = filterDropdown;
  });
  
  useImperativeHandle(ref, () => ({
    getSelect() {
      let rowsSelect = query.select;
      const where = composeQuery(columns);
      if (where != null) {
        rowsSelect += ` where ${where}`;
      }
      if (query.orderBy != null) {
        const orderBy = Object.entries(query.orderBy)[0];
        if (orderBy != null) {
          const columnName = orderBy[0];
          const sortDirection = orderBy[1]
          rowsSelect += ` order by ${columnName} ${sortDirection}`
        }
      }
      return rowsSelect;
    },
    refresh() {
      setLoading(true);
      setQuery({...query});
    }
  }));

  useEffect(() => {
    setLoading(true);
    try {
      query.where = composeQuery(columns);
    } catch (e) {
      z.notify({
        type: 'error',
        message: e?.message || 'Invalid Query',
        placement: 'top'
      });
    }
    setQuery({...query});
  }, [columns])

  useEffect(() => {
    return () => {
      destroying.current = true;
    }
  }, [])

  useAsyncEffect(async () => {

    
    let countSelect = query.select;
    let rowsSelect = query.select;

    const where = composeQuery(columns);
    if (where != null) {
      countSelect += ` where ${where}`; 
      rowsSelect += ` where ${where}`;
    }
    if (query.orderBy != null) {
      const orderBy = Object.entries(query.orderBy)[0];
      if (orderBy != null) {
        const columnName = orderBy[0];
        const sortDirection = orderBy[1]
        rowsSelect += ` order by ${columnName} ${sortDirection}`
      }
    }
    if (query.limit != null) {
      rowsSelect += ` limit ${query.limit}`;
    }
    if (query.offset != null) {
      rowsSelect += ` offset ${query.offset}`;
    }

    
    let [countResult, rowsResult] = await Promise.all([
      sys.orm({ first: true, select: `select count(*) from (${countSelect}) as t` }),
      sys.orm({ select: rowsSelect })
    ])
    setTotalRows(countResult);
    setData(rowsResult);
    setLoading(false);


    if (!destroying.current) {
      setSearchParams(stringifyQueryToUrl(props, query))
    }



  }, [query, columns]);

  const onExport = async () => {
    setExportRequested(true);
    const fileName = sys.toFileName(props.title) + '.xlsx';
    let response = await sys.api("excel", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fileName,
        columns: columns.filter(c => c.dataIndex != null).map(c => ({ dataIndex: c.dataIndex, title: c.title })),
        query: {
          ...query,
          offset: undefined,
          limit: undefined,
        }
      })
    });
    if (response.ok) {
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition.split('filename=')[1].split(';')[0];
      const blob = await response.blob();
      sys.download(filename, blob);
    }
    setExportRequested(false);
  }

  const page = query.offset === 0 ? 1 : query.offset / query.limit + 1;
  const pageSize = query.limit > totalRows ? 30 : query.limit;


  const pageSizeOptions = [];
  if (totalRows > 10) pageSizeOptions.push(10);
  if (totalRows > 20) pageSizeOptions.push(20);
  if (totalRows > 50) pageSizeOptions.push(50);
  if (totalRows > 100) pageSizeOptions.push(100);
  if (totalRows > 200) pageSizeOptions.push(200);
  if (totalRows > 500) pageSizeOptions.push(500);
  if (totalRows > 1000) pageSizeOptions.push(1000);
  pageSizeOptions.push(totalRows);


  return (
    <Table 
      style={{...props.style}}
      dataSource={data} 
      columns={columns} 
      loading={loading}
      rowSelection={props.rowSelection}
      rowKey={record => record.id}
      bordered 
      scroll={props.scroll}
      pagination={false}
      onChange={(pagination, filters, sorter, extra)=>{
        let orderBy = {};
        if (sorter.column != null) {
          orderBy[sorter.column.name] = {'ascend': 'asc', 'descend': 'desc'}[sorter.order];
        }
        setLoading(true);
        setQuery({
          ...query,
          orderBy
        })
      }}      
      title={() => {
        return (
          <Row wrap={false} align='middle'>
            <Col span={12}>
              <TableOutlined style={{paddingLeft: 10}}/>
              <Typography.Text style={{paddingLeft: 15}}>
                {props.title}
              </Typography.Text>
            </Col>
            <Col span={12} style={{textAlign: 'end', paddingRight: 10}}>
              <Space size='small'>
              <Button type="ghost" size="small" title="Clear" icon={<ReloadOutlined />} onClick={
                () => {
                  setLoading(true);
                  setQuery({ 
                    ...query
                  });
                }
              }/>
              <Button type="ghost" size="small" title="Clear" icon={<ClearOutlined />} onClick={
                () => {
                  setLoading(true);
                  setColumns(_.cloneDeep(props.columns));
                  setQuery({ 
                    ...props.query, 
                    where: props.query.where || {},
                    orderBy: props.query.orderBy || {},
                    offset: props.query.offset || 0,
                    limit: props.query.limit || 50, 
                  });
                }
              }/>
              {
                exportRequested
                ? <Button type="ghost"  size='small' title='Export' icon={<LoadingOutlined spin/>}/>
                : <Button type="ghost"  size='small' title='Export' icon={<DownloadOutlined/>} onClick={onExport}/>
              }
              </Space>
            </Col>
          </Row>
        )
      }}
      footer={() => {
        return (
        <Row wrap={false} align='middle'>
          <Col>
            <Typography.Text>
              Total {totalRows}
            </Typography.Text>
          </Col>
          <Col flex="auto" style={{display: 'flex', justifyContent: 'center', textAlign:'center'}}>
            <Pagination
              current={page}
              total={totalRows}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              onChange={(page, pageSize) => {
                setLoading(true);
                if (pageSize > 10_000) {
                  Modal.confirm({
                    type: 'warning',
                    title: 'Are you sure?',
                    content: `You've selected ${pageSize} records, this many records may crash your browser, are you REALLY SURE about this?`,
                    onOk: () => {
                      setQuery({
                        ...query,
                        limit: pageSize,
                        offset: (page - 1) * pageSize
                      });
                    },
                    okText: "Yes I'm sure!",
                    okType: 'danger',
                  });
                } else {
                  setQuery({
                    ...query,
                    limit: pageSize,
                    offset: (page - 1) * pageSize
                  });
                }
              }}
            />

          </Col>
          <Col style={{textAlign:'end'}}>
            {props.footer}
          </Col>
        </Row>)
      }}
    />
  );
});

Component.displayName = 'Table';
export default Component;