import sys, { type } from '../system';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime, Attribute } = type;

export default Table({
    name: 'Dataset',
    table: 'dataset',
    columns: {
        name: Text({
            title: 'Name',
            onChange: (value, row) => {
                if (value != null) {
                   value = value.replace(/[^a-z0-9\-\._]/ig,'');
                }
                return value;
            }
        }),
        description: Text({
            title: 'Description',
        }),
        tags: Json({
            title: 'Tags',
            persistent: false,
            onInitialize: (value, row) => {
                return row.extra_columns?.tags || [];
            },
            onChange: (value, row) => {
                const extra_columns = row.extra_columns || {};
                row.extra_columns = { ...extra_columns, tags: value };
                return value;
            }
        }),
        classes: Json({
            title: 'Classes',
            persistent: false,
            onInitialize: (value, row) => {
                return row.extra_columns?.classes || [];
            },
            onChange: (value, row) => {
                const extra_columns = row.extra_columns || {};
                row.extra_columns = { ...extra_columns, classes: value };
                return value;
            }
        }),
        rename: Json({
            Title: 'Classes Rename',
            persistent: false,
            onInitialize: (value, row) => {
                return row.extra_columns?.rename || {};
            },
            onChange: (value, row) => {
                const extra_columns = row.extra_columns || {};
                row.extra_columns = { ...extra_columns, rename: value };
                return value;
            }
        }),
        extra_columns: Json({
            title: 'Extra Columns',
        }),
        created_at: DateTime({
            defaultsTo: () => {
                return new Date();
            }
        }),
        getClassesWhere: Attribute({
          persistent: false,
          onInitialize: (value, row) => {
            return () => {
              if (row.classes.length === 0) {
                return 'null';
              }
              const classWhere = row.classes.map(c => sys.sql.escape(sys.type.Text, c)).join(', ');
              return classWhere;
            }
          }
        }),
        getTagsWhere: Attribute({
          persistent: false,
          onInitialize: (value, row) => {
            return () => {
              if (row.tags.length === 0) {
                return 'null';
              }
              const tagWhere = row.tags.map(t => sys.sql.escape(sys.type.Text, t)).join(', ')
              return tagWhere
            };
          }
        }),
        getSourceClassCount: Attribute({
          persistent: false,
          description: `Returns a list of Dataset Classes with [source, classe, count]`,
          onInitialize: (value, row) => {
            const classCountPromise = 
              async () => {
                const classWhere = row.getClassesWhere()
                const tagWhere = row.getTagsWhere()
                const rows = await sys.orm({
                  select: `
                    select
                      source.name source_name,
                      annotation.name class_name,
                      count(*)
                    from
                      annotation
                      join image on (image.id = annotation.image_id)
                      join source on (source.id = image.source_id)
                    where
                      annotation.name in (${classWhere})
                      and exists (
                        select 
                          * 
                        from 
                          tag 
                        where 
                          ref_id = image.id
                          and name in (${tagWhere})
                      )
                    group by
                      source.name,
                      annotation.name
                    order by
                      source.name,
                      annotation.name
                  `,
                })
                rows.forEach(r => r.count = parseInt(r.count))
                return rows;
              }
            return classCountPromise;
          }
      }),
      getAnnotations: Attribute({
        persistent: false,
        description: `Returns a list of Dataset Annotations with [id, name]`,
        onInitialize: (value, row) => {
          return async () => {
            const classWhere = row.getClassesWhere()
            const tagWhere = row.getTagsWhere()
            const rows = await sys.orm({
              select: `
                select
                  id,
                  name
                from
                  annotation
                where
                  annotation.name in (${classWhere})
                  and exists (
                    select 
                      * 
                    from 
                      tag 
                    where 
                      ref_id = annotation.image_id
                      and name in (${tagWhere})
                  )
              `,
            })
            return rows
          }
        }
      }),
    }
})