import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Alert, Skeleton, Checkbox, Button, Modal, Space, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';

import Table from '../components/Table';
import Player from './../components/Player';

import TicketForm from '../forms/Ticket';
import PageHeader from '../components/PageHeader'
import UserAvatar from '../components/UserAvatar';

import sys from '../system'
import { filter } from 'lodash';
const { Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = sys.type;

const Component = () => {
  const table = useRef();
  const ticketForm = useRef();
  const [ticketId, setTicketId] = useState();

  return (
    <div>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>
          <Typography.Title style={{margin: 0}} level={5} >List of Tickets</Typography.Title>
        </div>
      </PageHeader>
      <Table
        ref={table}
        title="Tickets"
        scroll={{y: 'calc(100vh - 280px)'}}
        query={{
          count: "select count(*) from ticket",
          select: `
            select 
              id,
              number,
              description,
              impact,
              urgency,
              priority,
              assign,
              status,
              created_at
            from
              ticket
          `,
          limit: 20,
          orderBy: { created_at: 'desc' }
        }}
        footer={
          <Button type='primary' onClick={() => setTicketId(null)}>New Ticket</Button>
        }

        columns={[
          {  
            title: 'Number', 
            sorter: true,
            name: 'number',
            type: Number,
            filter: true,
            mobile: false,
            width: '120px',
            align: 'right',
          },
          { 
            title: 'Description', 
            sorter: true,
            name: 'description',
            type: Text,
            filter: true,
            mobile: false,
          },
          {
            title: 'Assignment',
            name: 'assign',
            render: (record) => {
              const ids = record.assign || []
              return <div style={{display:'flex', flexDirection: 'row'}}>
                {ids.map((id, index) => {
                  return <UserAvatar key={id} id={id} showName={ids.length === 1}/>
                })}
              </div>
            }
          },
          { 
            title: 'Urgency', 
            name: 'urgency',
            sorter: true,
            type: Text,
            width: '140px',
            align: 'center',
          },
          { 
            title: 'Impact', 
            name: 'impact',
            sorter: true,
            type: Text,
            width: '140px',
            align: 'center',
          },
          { 
            title: 'Priority', 
            name: 'priority',
            sorter: true,
            type: Text,
            width: '140px',
            align: 'center',
          },
          { 
            title: 'Status', 
            name: 'status',
            sorter: true,
            type: Text,
            width: '160px',
            filter: ['!=Closed', '!=Canceled'],
            render: (record) => {
              let type = undefined;
              if (record.status === 'New') type = 'info';
              if (record.status === 'Analysis') type = 'warning';
              if (record.status === 'Approved') type = 'info';
              if (record.status === 'In Progress') type = 'warning';
              if (record.status === 'Complete') type = 'success';
              if (record.status === 'Closed') type = 'success';
              if (record.status === 'Canceled') type = 'error';
              return <div><Alert showIcon type={type} message={record.status}/></div>
            }
          },
          { 
            title: 'Created At', 
            sorter: true,
            name: 'created_at',
            type: DateTime,
            filter: true,
           mobile: false,
          },
          {
            title: 'Actions',
            width: '300px',
            render: (record) => {
              return <div>
                <Button type='link' onClick={() => {
                  setTicketId(record.id);
                }}>Edit</Button>
              </div>
            }
          }
        ]}     
    />
    <Modal 
      title="Ticket"
      open={ticketId !== undefined} 
      onOk={async () => {
        await ticketForm.current.save();
        setTicketId(undefined);
        table.current.refresh();
      }}
      onCancel={() => {
        setTicketId(undefined);
      }}
      width={'60vw'}
      destroyOnClose={true}
      centered={true}
    >
      <TicketForm ref={ticketForm} id={ticketId}/>
    </Modal>
    </div>
  );
};
export default Component;