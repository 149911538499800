import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, PageHeader, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';

import ModelForm from '../forms/Model'
import Table from '../components/Table';


import sys from '../system'
const { DateTime, Text } = sys.type;

const Component = () => {
  const table = useRef();
  const modelForm = useRef();
  const [modelId, setModelId] = useState();

  return (
    <div>
      <Table
        ref={table}
        title='List of Models'
        query={{
          count: "select count(*) from model",
          select: `
            select * from model`,
          limit: 20,
          orderBy: {
            name: 'desc'
          }
        }}
        columns={[
          { 
            title: 'Name', 
            sorter: true,
            name: 'name',
            type: Text,
          },
          { 
            title: 'Description', 
            sorter: true,
            name: 'description',
            type: Text,
          },
          { 
            title: 'Status', 
            name: 'status',
            sorter: true,
            type: Text,
            width: '140px',
            filter: ['=ACTIVE']
          },
          { 
            title: 'Created At', 
            sorter: true,
            name: 'created_at',
            type: DateTime,
          },
          {
            title: 'Actions',
            render: (record) => {
              return <div>
                <Button type='link' onClick={() => {
                  setModelId(record.id);
                }}>Edit</Button>
              </div>
            }
          }
        ]}
        footer={
          <Button type='primary' onClick={() => setModelId(null)}>New Model</Button>
        }
    />
    <Modal 
      title="Model"
      open={modelId !== undefined} 
      onOk={async () => {
        await modelForm.current.save();
        setModelId(undefined);
        table.current.refresh();
      }}
      onCancel={() => {
        setModelId(undefined);
        table.current.refresh();
      }}
      destroyOnClose={true}
    >
      <ModelForm ref={modelForm} id={modelId}></ModelForm>
    </Modal>
    </div>
  );
};
export default Component;