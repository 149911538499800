import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Flex ,Tooltip, Form, Skeleton, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';
import SplitPane, { Pane } from 'split-pane-react';
import Timeline from '../components/Timeline';
import ImageViewer from '../components/ImageViewer3';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';
import PageHeader from '../components/PageHeader'
import { BsLayoutSplit as Horizontal } from "react-icons/bs";

import SourceModel from '../models/Source';
import ProcessModel from '../models/Process';
import Input from '../components/Input';
import sys from '../system'

import Player from './../components/Player';

import ImagePicker from '../components/ImagePicker'
import RegionPicker from '../components/RegionPicker'


const Component = () => {

  const timelineRef = useRef()
  const [now, setNow] = useState(new Date())
  const [image, setImage] = useState(null)
  const [processId, setProcessId] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const [sizes0, setSizes0] = useState([]);
  const [split0, setSplit0] = useState('vertical')

  const [sources, setSources] = useState([])
  const [processes, setProcesses] = useState([])

  useAsyncEffect(async () => {
    const sources = []
    setSources(sources)
    const processes = await ProcessModel.select("status = 'ACTIVE' and name like 'STAND-%' order by name")
    setProcesses(processes)
    timelineRef.current.fit()
  }, [])


  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 100px)'
      }}
    >
      <PageHeader>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', gap: 20}}>

          <Tooltip title='Refresh' placement='bottom'>
            <Button
              type='text'
              icon={<Horizontal style={{transform: (split0 === 'horizontal' ? null : 'rotate(90deg)') }}/>}
              onClick={() => {
                if (split0 === 'horizontal') {
                  setSplit0('vertical')
                } else {
                  setSplit0('horizontal')
                }
                timelineRef.current.fit()
              }}
            />
          </Tooltip>
          <Typography.Title style={{margin: 0}} level={5} >Cameras Overview</Typography.Title>
        </div>
      </PageHeader>

      <SplitPane
        split={split0}
        sizes={sizes0}
        performanceMode={false}
        onChange={(sizes) => {        
          setSizes0(sizes)
          timelineRef.current.fit()
        }}
      >
        <Pane style={{height: '100%'}}>
          <Row gutter={10}>
            <Col span={4}>
              <Input 
                title={'Stand'} 
                value={processId}
                values={processes?.map(p => ({ value: p.id, label: p.name.split('-').at(1) }))}
                onChange={async (value) => {
                  const process = processes.find(p => p.id === value)
                  let sources = []

                  if (process != null && process.state && process.state.subprocess) {
                    const sourcesIds = {};
                    process.state.subprocess.forEach(sp => sp.nodes.forEach(n => {
                      if (n?.type === 'CaptureFromSource' && n.data?.sourceId) {
                        sourcesIds[n.data.sourceId] = null
                      }
                    }))
                    if (Object.keys(sourcesIds).length > 0) {
                      sources = await SourceModel.select(`
                        status = 'ACTIVE' 
                        and id in (${Object.keys(sourcesIds).map(id => sys.sql.escape(sys.type.Uuid, id)).join(',')})
                        order by name
                      `)
                    }
                  }
                  setProcessId(value)
                  setSources(sources)
                  setSourceId(sources[0].id)
                }}
                required
              />
            </Col>
            <Col flex='auto'>
              <Input 
                title={'Camera'} 
                value={sourceId}
                values={sources?.map(s => ({value: s.id, label: s.name.split('-').at(-1) }))}
                onChange={(value) => {
                  setSourceId(value)
                }}
                optionType='button'
                buttonStyle="solid"
                />
            </Col>
            <Col span={4} style={{marginRight: 10}}>
              <Input
                title='Current'
                type={sys.type.DateTime}
                value={now}
                onChange={value => {
                  setNow(value.toDate())
                }}
                allowClear={false}
              ></Input>
            </Col>
          </Row>
          <div style={{height: 'calc(100% - 60px)'}}>
            <Timeline
              ref={timelineRef}
              time={now}
              sourceId={sourceId}
              processId={processId}
              onRangeChange={(range) => {
                //console.log('onRangeChange callback', range)
              }}
              onChangeImage={(image) => {
                console.log('onChangeImage callback', image)
                setImage(image)
                setNow(image?.now)
              }}
            />
          </div>
        </Pane>
        <Pane>
          <ImageViewer
            image={image}
            showTimestamp={true}
            showPolygons={true}
            showBoxes={true}
            showLabels={false}
            showConfidence={false}
            showTags={false}
            showGeometry={true}
          ></ImageViewer>
        </Pane>
      </SplitPane>

        

    </div>
  );
};
export default Component;