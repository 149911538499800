import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from '../../../components/ProcessEditor/Handle'

import { Select, Space, Form, Card, Modal, Button, Typography } from 'antd';
import NodeCard from '../../../components/ProcessEditor/NodeCard'

const Component = props => {

  const [script, setScript] = useState(props.data.script);
  const [openModal, setOpenModal] = useState(false);


  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='a-activate'
          name='activate'
          title="Activate"
          style={{ top: '40%' }}
          accepts={['any']}
          activation
        />,
        <Handle
          key='a'
          name='a'
          title="Polygons A"
          style={{ top: '66%' }}
          accepts={['polyset', 'poly']}
          maxConnections={1}
        />,
        <Handle
          key='b'
          name='b'
          title="Polygons B"
          style={{ top: '85%', }}
          accepts={['polyset', 'poly']}
          maxConnections={1}
        />,
        <Handle
          key='c'
          name='c'
          title="Polygon result"
          style={{ top: '56.5%', }}
          produces={['any']}
          activation
        />
      ]}
    >
      <div style={{padding: '10px', display: 'flex', justifyContent: 'center', minWidth: '140px'}}>
        <img src='/nodes/polygonAdd.svg' style={{width: '80px', height: '80px'}} />
      </div>
    </NodeCard>
  );
};

Component.displayName = 'PolygonAddNode';
export default Component;

