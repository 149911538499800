import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, FloatButton, Tooltip, Empty, Drawer, Radio, Skeleton, Timeline, Progress, Slider, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin, List, Tag , Divider  } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined, RollbackOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined, UnorderedListOutlined, DislikeOutlined, LikeOutlined, QuestionOutlined, MessageOutlined, StepForwardOutlined, StepBackwardFilled } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";
import { useStore } from '../../store';
import imageUtils from '../../utils/image';
import PageHeader from '../../components/PageHeader'

import AnnotationViewer from '../../components/AnnotationViewer'
import BackButton from '../../components/AppHeader/BackButton'

import ReviewModel from '../../models/Review'
import DatasetModel from '../../models/Dataset';

import sys from '../../system';



const Component = (props) => {
  const params = useParams();

  const user = useStore.getState().user
  const [dataset, setDataset] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [index, setIndex] = useState()
  const [annotation, setAnnotation] = useState()
  const [annotations, setAnnotations] = useState([])
  const [showNotOkOptions, setShowNotOkOptions] = useState(false)

  const [showOthers, setShowOthers] = useState(true);
  const [showNotOk, setShowNotOk] = useState(true);
  const [showOk, setShowOK] = useState(true);
  
  const nextIndex = (a = annotations) => {
    let i = index == null ? 0 : index + 1;
    while (true) {
      const annotation = a[i];
      if (annotation == null || annotation.result == null || annotation.result === 'CHANGED' ) {
        setIndex(i)
        return
      }
      i++;
    }
  }
  
  useAsyncEffect(async () => {
    const datasetId = params.datasetId;
    if (datasetId == null) {
      return;
    }
    const dataset = await DatasetModel.get(datasetId);
    setDataset(dataset);

    const annotations = await sys.orm({
      select: `
        select 
          annotation.id,
          annotation.image_id,
          annotation.created_at,
          annotation.name,
          review.result,
          review.comment
        from 
          annotation 
          left join review on (review.annotation_id = annotation.id and review.user_id = $1)
        where
          name in (${dataset.getClassesWhere()})
          and exists (
            select
              * 
            from 
              tag 
            where 
              tag.ref_id = annotation.image_id
              and name in (${dataset.getTagsWhere()})
          )
        order by
          annotation.name,
          annotation.image_id,
          annotation.created_at
      `,
      values: [ user.id ]
    })

    setAnnotations(annotations)
    nextIndex(annotations)
  }, []);

  useAsyncEffect(
    async () => {
      setAnnotation(annotations[index])
    },
    [index]
  )



  const onSave = async () => {
    let userReview = await ReviewModel.selectFirst('annotation_id = $1 and user_id = $2', [annotation.id, user.id]);
    if (userReview == null) {
      userReview = ReviewModel.create({
        annotation_id: annotation.id,
        user_id: user.id,
      })
    }
    userReview.result = annotation.result;
    userReview.comment = annotation.comment;
    await userReview.save()
  }

  let progress = null;
  let count;
  let done;
  if (annotations != null) {
    count = annotations.length;
    done = annotations.reduce((a, c) => c.result != null ? a + 1 : a, 0)
    progress = (done / annotations.length * 100).toFixed(2)
  }

  const onNotOk = (comment) => {
    setShowNotOkOptions(false)
    annotation.result = 'NOT OK';
    annotation.comment = comment;
    onSave();
    nextIndex()
  }

  let textShadowColor = 'white';
  if (annotation != null && annotation.result != null) {
    if (annotation.result === 'OK') {
      textShadowColor = 'green';
    } else if (annotation.result === 'NOT OK') {
      textShadowColor = 'red';
    } else {
      textShadowColor = 'orange';
    }
  }

  return (
    <div style={{
      width: '100%', 
      height: '100%',
      textAlign: '-webkit-center'
    }}
    >
      <PageHeader>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '100%', gap: 20}}>
          <BackButton/>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: '100%', alignItems: 'center'}}>
            <Typography.Title 
              style={{
                margin: 0,
              }} level={5} 
            >
              Reviewing {dataset?.name}
            </Typography.Title>
            <Typography.Title 
              style={{
                margin: 0,
                flex: 'auto'
              }} level={5}
            >
              {done} of {count}
            </Typography.Title>
          </div>
        </div>
      </PageHeader>

    <div
      style={{
        height: 'calc(100vh - 110px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
        maxWidth: '600px',
        gap: 10,
      }}
    >
    {
      annotation != null ? 
        [
          <AnnotationViewer
            key={annotation.id} 
            id={annotation.id}
          />,
          <div
              key={'action-1'}
              style={{
                padding: 16,
                backgroundColor: imageUtils.stringToColor(annotation?.name),
                color: 'black',
                fontWeight: 800,
                border: '1px solid black',
                boxShadow: '2px 2px 3px gray',
                fontSize: 24,
                textShadow: `0px 0px 10px ${textShadowColor}, 0px 0px 10px ${textShadowColor}`
              }}
            >
              {annotation?.name}
          </div>
        ]
      : <div style={{height:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
        </div>
    }
  
      <Progress
        percent={progress} 
        size={[,20]} 
        showInfo={false} 
        strokeColor={{ from: '#108ee9', to: '#87d068' }}
        status={annotation == null ? 'success' : 'normal'}
      />

      <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
      }}>
          <Button
            danger
            type="primary"
            shape='circle'
            style={{ width: '5rem', height: '5rem' }}
            icon={<DislikeOutlined/>}
            onClick={() => {
              setShowNotOkOptions(true);
            }}
            disabled={annotation == null}
          />
          <div 
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Button 
                style={{ width: '4rem', height: '4rem', }}
                icon={<StepBackwardFilled />}
                onClick={() => {
                  setIndex(index - 1)
                }}
              >
            </Button>
            <Button
              icon={<UnorderedListOutlined/>}
              style={{ width: '4rem', height: '4rem', }}
              onClick={() => {
                setOpenDrawer(true)
              }}
              loading={openDrawer}
            />
            <Button 
                style={{ width: '4rem', height: '4rem', }}
                icon={<StepForwardOutlined />}
                onClick={() => {
                  setIndex(index + 1)
                }}
              >
            </Button>
          </div>

          <Button
            shape='circle'
            type="primary"
            style={{ width: '5rem', height: '5rem', backgroundColor: '#3ccc08'}}
            icon={<LikeOutlined />}
            onClick={() => {
              annotation.result = 'OK';
              onSave();
              nextIndex()
            }}    
            disabled={annotation == null}
          />
        </div>






    
      <Drawer
        title={
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{flex:'auto'}}>
              Review Progress
            </div>
            { 
              /*
              <div style={{display: 'flex', gap: 4}}>
                <Button type={showOthers && 'primary'} icon={<QuestionOutlined/>} onClick={()=> setShowOthers(!showOthers)}></Button>
                <Button type={showNotOk && 'primary'} icon={<DislikeOutlined/>}  onClick={()=> setShowNotOk(!showNotOk)}></Button>
                <Button type={showOk && 'primary'} icon={<LikeOutlined/>}  onClick={()=> setShowOK(!showOk)}></Button>
              </div>
              */
            }
          </div>
        }
        placement='bottom'
        closable={true}
        open={openDrawer}
        afterOpenChange={(open) => {
          if (open === true) {
            const button = document.getElementById(`review-btn-${index}`)
            if (button != null) {
              button.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
            }
          }
        }}
        style={{
          margin: 0
        }}
        size='large'
        onClose={() => {
          setOpenDrawer(false)
        }}
      >
        {
          openDrawer &&
          <Timeline
            key='timeline'
            mode='alternate'    
            items={
              annotations.map((a, i) => {
                const item = {
                  children: 
                    <Button 
                      id={`review-btn-${i}`}
                      type={index === i ? 'primary' : 'dashed'}
                      style={{overflow: 'hidden'}}
                      block 
                      onClick={() => {
                        setIndex(i);
                        setOpenDrawer(false);
                        a.result = 'OK'
                      }}
                    >
                      {a.name}
                    </Button>
                }
                const result = a.result;
                if (result == null) {
                  item.color = 'gray';
                } else if (result === 'OK') {
                  item.color = 'green';
                } else if (result === 'NOT OK') {
                  item.color = 'red';
                } else {
                  item.color = 'orange';
                }
                return item;
              })}
          />
        }
      </Drawer>

      </div>
        <Modal
          title="Why is it wrong?"
          open={showNotOkOptions}
          okButtonProps={{ style:{display: 'none'}}}
          onCancel={() => {
            setShowNotOkOptions(false)
          }}
          centered
        >
          <div style={{display:'flex', flexDirection: 'column', gap: 20, padding: 20}}>
            <Button danger onClick={() => onNotOk('Adjust Annotation')} style={{height: 50}}>
              Adjust Annotation
            </Button>
            <Button danger onClick={() => onNotOk('Wrong Classe')} style={{height: 50}}>
              Wrong Classe
            </Button>
            <Button danger onClick={() => onNotOk('Unnecessary')} style={{height: 50}}>
              Unnecessary
            </Button>
            <Button danger onClick={() => onNotOk("Let me think about it")} style={{height: 50}}>
              Let me think about it
            </Button>
            <Button type='primary' danger onClick={() => onNotOk('Review Please')} style={{height: 50}}>
              Review Please
            </Button>
          </div>
        </Modal>
    </div>
  );
};
export default Component;