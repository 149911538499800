import sys, { type } from '../system';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = type;





export default Table({
    name: 'Process',
    table: 'process',
    columns: {
        name: Text({
            defaultsTo: 'NEW-PROCESS',
            onChange: (value, row) => {
                if (value != null) {
                    value = value.replace(/[^a-z0-9\-\._]/ig,'');
                }
                return value;
            }
        }),
        description: Text(),
        created_at: DateTime({
            defaultsTo: () => { return sys.now() }
        }),
        status: type.Text({
            defaultsTo: 'ACTIVE',
            values: ['ACTIVE', 'INACTIVE']
        }),
        state: Json(),
        draft_state: Json(),
        external_ref: Text(),
    }
})