import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu } from 'antd';
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store';

import Input from '../components/Input'

import sys from '../system';

import ScheduleModel from '../models/Schedule';


const Component = forwardRef((props, ref) => {
 
  const [schedule, setSchedule] = useState();

  useImperativeHandle(ref, () => ({
    save: async () => {
      await schedule.save(); 
    }
  }));
  
  useAsyncEffect(async () => {
    let schedule;
    if (props.id == null) {
      schedule = await ScheduleModel.create();
    } else {
      schedule = await ScheduleModel.get(props.id);
    }
    setSchedule(schedule);
  }, []);


  return (
    <div>
      <Row gutter={20}>
        <Col span={8}>
          <Input model={schedule} column={'id'} disabled></Input>
          <Input model={schedule} column={'name'} required></Input>
          <Input model={schedule} column={'description'}></Input>
        </Col>
        <Col span={8}>
          <Input model={schedule} column={'start'}></Input>
          <Input model={schedule} column={'end'}></Input>
          <Input model={schedule} column={'expression'}></Input>
          <Input model={schedule} column={'timeout'}></Input>
        </Col>
        <Col span={8}>
          <Input model={schedule} column={'queue_size'} required></Input>
          <Input model={schedule} column={'status'}></Input>
          <Input model={schedule} column={'priority'}></Input>
        </Col>
      </Row>  
      <Row>
        <Col span={24}>
          <Input model={schedule} column={'process_id'}></Input>
        </Col>
      </Row>
    </div>
  );
});

Component.displayName = 'Component';
export default Component;