import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';
import PageHeader from '../components/PageHeader'
import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';

import Table from '../components/Table';

import WorkerForm from './../forms/Worker';
import sys from './../system'
const { Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = sys.type;

const Component = () => {
  const navigate = useNavigate();

  const [workerId, setWorkerId] = useState()
  const workerForm = useRef();
  const table = useRef();

  return (
    <div>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>      
          <Typography.Title style={{margin: 0}} level={5} >List of Workers</Typography.Title>
        </div>
      </PageHeader>
      <Table
        ref={table}
        title="Workers"
        query={{
          count: "select count(*) from worker",
          select: `
            select 
              worker.id, 
              worker.name, 
              worker.description, 
              worker.user_id, 
              worker.status,
              worker.created_at,
              "user".username 
            from 
              worker 
              join "user" on (worker.user_id = "user".id)`,
          limit: 20,
          orderBy: {
            name: 'desc'
          }
        }}
        columns={[
          { 
            title: 'Name', 
            sorter: true,
            name: 'name',
            type: Text,
            width: '160px',
          },
          { 
            title: 'Description', 
            sorter: true,
            name: 'description',
            type: Text,
          },
          { 
            title: 'User', 
            name: 'username',
            sorter: true,
            type: Text,
            width: '140px',
          },
          { 
            title: 'CPU Count', 
            sorter: true,
            type: Text,
            width: '140px',
          },
          { 
            title: 'CPU Memory', 
            sorter: true,
            type: Text,
            width: '140px',
          },
          { 
            title: 'GPU Count', 
            sorter: true,
            type: Text,
            width: '140px',
          },
          {
            title: 'GPU Memory', 
            sorter: true,
            type: Text,
            width: '140px',
          },
          { 
            title: 'Status', 
            name: 'status',
            sorter: true,
            type: Text,
            width: '140px',
          },
          { 
            title: 'Created At', 
            sorter: true,
            name: 'created_at',
            type: DateTime,
          },
          {
            title: 'Actions',
            width: '300px',
            render: (record) => {
              return (
                <div>
                  <Button type='link' onClick={() => {
                    setWorkerId(record.id)
                  }}>Edit</Button>
                </div>
              )
            }
          }
        ]}
        footer={
          <Button type='primary' onClick={() => setWorkerId(null)}>New Worker</Button>
        }
      />    
      <Modal 
        open={workerId !== undefined} 
        onOk={async () => {
          await workerForm.current.save();
          table.current.refresh();
          setWorkerId(undefined);
        }}
        onCancel={() => setWorkerId(undefined)}
        maskClosable={false}
        destroyOnClose={true}
      >
        <WorkerForm ref={workerForm} id={workerId}/>
      </Modal>
    </div>
  );
};
export default Component;