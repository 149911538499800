
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, Carousel, Row, Modal, Col, Typography, Avatar, Tag, Space, Transfer, Tabs, theme, List, Menu, Input, Dropdown, Collapse, Select, InputNumber, Tooltip  } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import useAsyncEffect from 'use-async-effect';
import TagModel from '../models/Tag';

import ImageClass from './../components/ImageAnnotator/ImageClass';
import ImageTags from './../components/ImageTags';
import ImageViewer from './../components/ImageViewer';
import Uuid from './../components/Uuid';

import Table from './Table';
import { FaImage } from 'react-icons/fa'
import { PictureOutlined, PlusCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';

import ImageModel from './../models/Image'

import sys from './../system'

import { DownOutlined } from '@ant-design/icons'

const carouselStyle = {
  height: '50px',
  width: '100px',
  border: '1px solid black',
};

const ImageAvatar = props => {

  const [src, setSrc] = useState();
  useAsyncEffect(
    async () => {
      if (props.imageId == null) {
        return;
      }
      const image = await ImageModel.get(props.imageId);
      const url = sys.image.url(image.src);
      setSrc(url);
    }, [props.imageId, props.selected])

  return (
    <Dropdown
      arrow
      placement="bottom"
      onClick={() => {
        if (typeof props.onClick === 'function') {
          props.onClick(props.imageId, props.imageIndex);
        }
      }}
      menu={{
        items: [
          {
            key: 1,
            label: 'Select',
            disabled: props.selected,
            onClick: () => {
              if (typeof props.onChange === 'function') {
                props.onSelect(props.imageId);
              }
            }
          },
          { 
            type: 'divider',
          },
          {
            key: 2,
            label: 'Change',
            onClick: () => {
              if (typeof props.onChange === 'function') {
                props.onChange(props.imageId);
              }
            }
          },
          { 
            key: 3,
            danger: true,
            label: 'Remove',
            onClick: () => {
              if (typeof props.onRemove === 'function') {
                props.onRemove(props.imageId);
              }
            }
          },
        ]
      }}
    >
      <div style={{
        ...carouselStyle,
        cursor: 'pointer',
        boxShadow: `2px 2px 3px gray`,
        backgroundImage: `url(${src})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        transition: 'background-image 0.1s ease-in-out',
        border: props.selected ? '3px solid #1677ff' : carouselStyle.border,
      }}>
      </div>
    </Dropdown>
  )
}

const Component = (props) => {

  const [openModal, setOpenModal] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();
  const [value, setValue] = useState([]);
  const tableRef = useRef();


  console.log('ImagePicker.props', props)

  useAsyncEffect(async () => {
    let value = [props.value].flat().filter(e => e != null);
    setValue(value);
  }, [props.value, props.select]);


  const onChange = (value) => {
    setValue(value);
    if (typeof props.onChange === 'function') {
      props.onChange(value);
    }
  }


  const avatars = value
    .filter(i => i != null)
    .map((imageId, i) => {
    return (
      <ImageAvatar
        key={`image-avatar-${-i}`}
        imageIndex={i}
        imageId={imageId}
        onRemove={() => {
          const newValue = value.filter(i => i.id === imageId);
          onChange(newValue);
        }}
        selected={props.selected == imageId}
        onClick={props.onSelect}
        onSelect={props.onSelect}
        onChange={() => {
          setSelectedRowKeys([imageId]);
          setSelectedIndex(i);
          setOpenModal(true)
        }}
      >
      </ImageAvatar>
    )
  })

  return (
    <div style={{paddingBottom: '0.4rem'}}>
      <Row justify="space-between" >
        <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
          {props.required === true ? <Typography.Text type="danger" style={{paddingRight:4}}>*</Typography.Text> : null}
          <Typography.Text>{props.title}</Typography.Text>
        </div>
      </Row>

      <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>

        {avatars}

        {
          props.maxCount != null && avatars.length < props.maxCount
          ? <div style={{ ...carouselStyle, backgroundColor: 'initial', border: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 3}}>
              <Button
                style={{ width: '100%', height: '100%'}}
                size='small'
                type='dashed'
                onClick={() => {
                  setSelectedRowKeys([]);
                  setOpenModal(true)
                }}
              >
                +
              </Button>
            </div>
          : undefined

        }

        <Modal
          title="Select a Image"
          open={openModal} 
          width={'80%'}
          bodyStyle={{
          }}
          maskClosable={false}
          onCancel={() => setOpenModal(false)}
          onOk={() => {
            let imageId = selectedRowKeys[0];
            if (imageId == null) {
              return;
            }
            if (selectedIndex == null) {
              onChange([...value, imageId]);
            } else {
              value[selectedIndex] = imageId;
              onChange([...value])
            }
            setSelectedIndex();
            setOpenModal(false);
          }}
          centered
        >
          <Table
            ref={tableRef}
            title="Images"
            scroll={{ y: 'calc(100vh - 400px)' }}
            rowSelection={{
              selectedRowKeys,
              type: 'radio',
              onChange: (newSelectedRowKeys) => {
                setSelectedRowKeys(newSelectedRowKeys);
              },
            }}
            query={{
              select: `
                select * from
                  (
                    select 
                    *,
                    (select json_agg(distinct(name))::text from annotation where image_id = image.id) annotations,
                    (select name from source where id = image.source_id) source_name
                  from
                    image
                  where
                    exists (select * from annotation where image_id = image.id and model_id is null)
                    or exists (select * from tag where ref_id = image.id)
                  )
                as image
              `,
              where: {},
              orderBy: { created_at: 'desc' },
              limit: 20,
            }}
            columns={[
              { 
                title: 'Image', 
                name: 'src',
                width: '270px',
                render: (record) => {
                  return <ImageViewer width={240} height={136}  quality={75} imageId={record.id} showBoxes={true} showPolygons={true}/>
                },
              },
              { title: 'Classes', name: 'annotations',
                render: (record) => {
                  return (
                  <Row gutter={[0,8]}>
                    { 
                      (JSON.parse(record.annotations) || []).sort().map((t,i) => <ImageClass key={`class-${i}`} name={t}/>)
                    }
                  </Row>)
                },
                type: sys.type.Text,
                filter: true,
                options: async () => {
                  const rows = await sys.orm('select distinct name from annotation where model_id is null');
                  return rows.sort((a, b) => a.name > b.name).map(r => r.name);
                },
                toWhere: (value) => {
                  return `exists(select * from annotation where image_id = image.id and ${sys.sql.toWhere('name', Text, value)})`
                }
              },
              { title: 'Tags', name: 'tags',
                render: (record) => <ImageTags id={record.id}/>,
                type: sys.type.Text,
                filter: true,
                options: async () => {
                  const rows = await sys.orm('select distinct name from tag');
                  return rows.map(r => r.name);
                },
                toWhere: (value) => {
                  return `exists(select * from tag where ref_id = image.id and ${sys.sql.toWhere('name', Text, value)})`
                }
              },
              { 
                title: 'Resolution',
                width: '120px',
                align: 'center',
                render: (record) => {
                  if (record.width && record.height) {
                  return <>
                      <div>{record.width} x {record.height}</div>
                    </>
                  }
                  return null
                }
              },
              { 
                title: 'Source',
                name: 'source_name', 
                type: sys.type.Text,
                width: '120px',
                align: 'center',
                filter: true,
                sorter: true,
              },
              { 
                title: 'Updated At', 
                name: 'updated_at', 
                width: '180px',
                align: 'center',
                type: sys.type.DateTime,
                filter: true,
                sorter: true,
              },
              { 
                title: 'Created At', 
                name: 'created_at', 
                width: '180px',
                align: 'center',
                type: sys.type.DateTime,
                filter: true,
                sorter: true,
              }
            ]}      
          />

        </Modal>
      </div>
    </div>
  )
};

  
Component.displayName = 'ImagePicker';
export default Component;