import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from './../../../components/ProcessEditor/Handle'
import { Select, Space, Form, Card, Input, Tooltip, InputNumber, Button, Divider, Slider, Row, Tabs, Col, Switch, Checkbox, Typography } from 'antd';
import useAsyncEffect from 'use-async-effect';
import {
  EyeOutlined, EyeInvisibleOutlined, EyeFilled, EyeTwoTone 
} from '@ant-design/icons';
import sys from '../../../system'
import SourceModel from '../../../models/Source'
import NodeCard from './../../../components/ProcessEditor/NodeCard'
import ImageModel from './../../../models/Image';

import RegionPicker from '../../../components/RegionPicker'
import ImageViewer from './../../../components/ImageViewer'
import ResolutionPicker from './../../../components/ResolutionPicker'

const svgZoneProps = {
  fill: "#2ee672",
  fillOpacity: "0.3",
  stroke: "darkgreen",
  strokeWidth: "0.2",
  strokeDasharray: "1",
}

const regionDefaults = {
  top: 'w',
  height: 'h',
  base: 'w*2',
  rotate: 0,
}

const originDefaults = {
  x: 0,
  y: 0
}

const bboxDefaults = {
  x: 40,
  y: 30,
  width: 20,
  height: 40,
}



const Component = (props => {
  
  const [value, setValue] = useState(props.data);

  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
        key='1'
        name='activation'
        type="source"
        accepts={['any']}
        title='Activate'
        activation
        process={props.process}
        subprocess={props.subprocess}
      ></Handle>,
      <Handle
        key='2'
        name='out'
        type="source"
        produces={['polyset']}
        title='Produces [Polyset]'
        activation
        process={props.process}
        subprocess={props.subprocess}
      ></Handle>
    ]}
    >
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <RegionPicker
          width={240}
          height={136}
          tools={['box', 'polygon']}
          value={value}
          onChange={(value) => {
            console.log('RegionPicker onChange value', value)
            props.data.images = value.images;
            props.data.selected = value.selected;
            props.data.width = value.width;
            props.data.height = value.height;
            props.data.region = value.region;
            setValue(value);
            props.onChange();
          }}
        />

      </div>
    </NodeCard>
  );
});

Component.displayName = 'DefineRegionNode';
export default Component;

