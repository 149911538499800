import sys, { type } from '../system.js';

import Person from './Person.js';

export default type.Table({
    name: 'Job',
    table: 'job',
    columns: {
        created_at: type.DateTime({
            defaultsTo: sys.now()
        }),
        start_after: type.DateTime(),
        start: type.DateTime(),
        end: type.DateTime(),
        priority: type.Integer({
            defaultsTo: 0,
        }),
        timeout: type.DateTime(),
        status: type.Text({
            defaultsTo: 'QUEUED',
            values: ['QUEUED', 'RUNNING', 'ERROR', 'SUCCESS', 'TIMEOUT', 'CANCELLED']
        }),
        workder_id: type.Uuid(),
        process_id: type.Uuid(),
        process: type.Relation({
            to: 'Process',
            cardinality: '1',
            where: "id = $process_id"
        }),
        next: type.DateTime(),
    }
})