import React, { useState, useRef, useEffect, useCallback, useMemo, memo } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Tabs, Checkbox, Button, Modal, Space, Tag, Alert, Menu, Statistic, Row, Col, Badge, Card, Spin, Select, Drawer, Tooltip, Transfer    } from 'antd';
import imageUtils, { stringToColor } from '../../utils/image';

import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";
import { useStore } from '../../store';
import PageHeader from '../../components/PageHeader'
import { SaveOutlined, EditOutlined, DeleteOutlined, SyncOutlined as RefreshIcon } from '@ant-design/icons';

import ClasseCountChart from '../../components/DatasetEditor/ClassCountChart';
import ClasseCountTreemap from '../../components/DatasetEditor/ClassCountTreemap';
import BackButton from '../../components/AppHeader/BackButton'

import ClassSelector from './ClassSelector';
import TagSelector from './TagSelector';

import Dataset from '../../models/Dataset';

import sys from '../../system'

import './DatasetEditor.less'

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Panel
} from 'reactflow';

import { Handle, Position } from 'reactflow';



const Component = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [toBeSaved, setToBeSaved] = useState(false);
  const [availableClasses, setAvalableClasses] = useState([]);
  const [allClasses, setAllClasses] = useState();
  const [datasetClasses, setDatasetClasses] = useState([]);
  const [dataset, setDataset] = useState();
  const [update, setUpdate] = useState();

  const [allTags, setAllTags] = useState([]);
  const [datasetTags, setDatasetTags] = useState([])

  const refresh = () => {
    setUpdate(sys.uuid());
  }


  const save = async () => {
    console.log('saving');

    await dataset.save();
    console.log(dataset);
    
    setToBeSaved(false);
  }
  

  useAsyncEffect(async () => {
    const datasetId = params.datasetId;
    if (datasetId == null) {
      return;
    }
    const dataset = await Dataset.get(datasetId);
    setDataset(dataset);

    const allTags = await sys.orm({ select: `select distinct name from tag` });
    allTags.forEach(tag => {
      tag.key = tag.name;
      tag.value = tag.name
    })
    setAllTags(allTags);

  }, []);

  useAsyncEffect(async () => {
    if (dataset == null) {
      return
    }

    let datasetClasses = dataset.classes || [];
    datasetClasses.sort();

    let datasetTags = dataset.tags || [];
    let inTags;
    let i = 0;
    let values = []
    if (datasetTags == null) {
      inTags = 'and 1 = 1';
    } else if (datasetTags.length == 0) {
      inTags = 'and 1 = 2';
    } else if (datasetTags.length > 0) {
      inTags = `and name in (${datasetTags.map(_ => `$${++i}`)})`;
      values.push(datasetTags);
    }
    let availableClasses = await sys.orm({ 
      select: `
        select
          name "key",
          name,
          count(*)
        from 
          annotation 
        where 
          model_id is null 
          and exists (select * from tag where ref_id = image_id ${inTags})
        group by
          name
      `,
      values: values.flat()
    });
    setAvalableClasses(availableClasses);

    let allClasses = [...availableClasses];
    datasetClasses.forEach(cls => {
      if (!allClasses.find(e => e.name == cls)) {
        allClasses.push({ key: cls, name: cls, count: '0' });
      }
    });
    allClasses.sort((a,b) => ('' + a.name).localeCompare(b.name));
    setAllClasses(allClasses);


  }, [update, dataset]);











  const DatasetWarnings = (props) => {
    if (allClasses == null) {
      return null;
    }
    const warnings = [];
    for (const cls of allClasses) {
      
      if (!dataset?.classes?.includes(cls.name)) {
        continue;
      }

      if (cls.count == 0) {
        warnings.push(
          <Alert key={`dtw-${warnings.length}`}
            style={{margin: 20}}
            message={`Classe ${cls.name} must have at least 1 annotation`}
            type="error"
          />
        );
      } else
      if (cls.count < 10) {
        warnings.push(
          <Alert key={`dtw-${warnings.length}`}
            style={{margin: 20}}
            message={`Classe ${cls.name} must have at least 10 annotations`}
            type="warning"
          />
        );
      }

    }




    return <div>
      {warnings}
    </div>
  }






  return (

    <div style={{width: '100%', height: '100%'}}>
      <PageHeader>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', gap: 20}}>
          <BackButton/>

          <Tooltip title='Refresh' placement='bottom'>
            <Button
              type='text'
              disabled={!toBeSaved}
              icon={<RefreshIcon />}
              onClick={async () => {
                const datasetId = params.datasetId;
                const dataset = await Dataset.get(datasetId);
                setDataset(dataset);
                setToBeSaved(false)
                refresh();
              }}
            />
          </Tooltip>

          <Tooltip title='Save' placement='bottom'>
            <Button 
              type={toBeSaved ? 'default' : 'text' }
              danger={toBeSaved}
              disabled={!toBeSaved}
              icon={<SaveOutlined />} 
              onClick={async () => {
                await save();
              }}
            />
          </Tooltip>
          <Typography.Title style={{margin: 0}} level={5} >{dataset?.name}</Typography.Title>
          <Typography.Text 
            style={{margin: 0, minWidth: 300 }} 
            level={5} 
            editable={{
              autoSize: { maxRows: 1 },
              onChange: (value) => {
                if (value === dataset.description) {
                  return;
                }
                dataset.description = value;
                refresh();
                setToBeSaved(true);
            }
          }}>{dataset?.description}</Typography.Text>
        </div>
      </PageHeader>

      <Card style={{width:'100%', height: '100%'}} bodyStyle={{width:'100%', height: '100%'}}>


        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: 'Filters',
              key: '1',
              children: 
                <>
                  <Row gutter={20}>
                    <Col span={4}>
                      <TagSelector 
                        tags={dataset?.tags}
                        allTags={allTags}
                        availableClasses={availableClasses}
                        onChange={tags => {
                          dataset.tags = tags;
                          setUpdate(sys.uuid());
                          setToBeSaved(true);                          
                        }}
                      />
                    </Col>
                    <Col>
                      <ClassSelector
                        allClasses={allClasses}
                        classes={dataset?.classes}
                        rename={dataset?.rename}
                        availableClasses={availableClasses}
                        onChange={(classes, rename) => {
                          dataset.classes = classes
                          dataset.rename = rename
                          console.log('dataset', dataset)
                          setUpdate(sys.uuid());
                          setToBeSaved(true);
                        }}
                      />
                    </Col>
                      
                    <Col>
                      <DatasetWarnings></DatasetWarnings>
                    </Col>
                  </Row>
                </>,
            },
          ]}
        />





      </Card>
    </div>
  )
};

Component.displayName = 'DatasetEditor';
export default Component;