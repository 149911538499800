import React, { useState, useRef, useEffect } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  DashboardOutlined
} from '@ant-design/icons';
import { FaMoon, FaSun } from 'react-icons/fa';
import sys from './system';

import { Layout, Menu, theme, Row, Col, Switch, ConfigProvider, Button, Card, Spin, App  } from 'antd';
import { BiLogOut } from 'react-icons/bi';
import useAsyncEffect from 'use-async-effect';

import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
  Redirect,
  useLocation,
  Outlet
} from "react-router-dom";
import Login from './pages/Login';
import HomePage from './pages/Home';

import { usePresistentStore, useStore } from './store';


import 'antd/dist/reset.css';
import 'react-js-cron/dist/styles.css'

const PrivateRoutes = () => {
  const accessToken = useStore(state => state.accessToken);
  return accessToken ? <Outlet/> : <Navigate to={'/login'}/>;
}


const Loading = () => {
  return (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-50px',
      marginLeft: '-50px',
      width: '110px',
      height: '100px',
    }}>
      <Spin style={{paddingRight: 10}} size="large"/> Loading...
    </div>
  );
}

const Neuroengine = () => {
  const themeName = usePresistentStore(state => state.theme);
  const setThemeName = usePresistentStore(state => state.setTheme);
  const { defaultAlgorithm, darkAlgorithm } = theme;
  
  const location = useLocation();
  const startLocation = useRef(location);
  const [loading, setLoading] = useState(true);

  useAsyncEffect(() => {
    setLoading(true);
    sys.refreshAccessToken().then(async (success) => {
      if (success) {
        await sys.refreshUserFromToken();
        await sys.onLogin();
      }
    }).finally(() => {
      setLoading(false);
    });
  },[])

  return (
    <ConfigProvider
      // https://ant.design/theme-editor
      theme={{
        algorithm: themeName === 'dark' ? darkAlgorithm : defaultAlgorithm,
        "token": {
          "borderRadius": 0
        }
      }}
    >
      <App>
      {
        loading 
        ? <Loading/>
        : <Routes>
            <Route element={<PrivateRoutes/>}>
              <Route path="/*" element={<HomePage/>}/>
            </Route>
            <Route path='/login' element={<Login navigate={startLocation}/>}/>
          </Routes>
      }
      </App>
    </ConfigProvider>
  );
};
export default Neuroengine;