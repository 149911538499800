import { Button, Card, Checkbox, Form, Layout, Row, theme, Col, Radio, Typography, Space, Badge, Select, Divider, Transfer, Tabs, List, Menu,Tag, Modal, Input } from 'antd';
const { useToken } = theme;
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';
import ReactImageAnnotate from "react-image-annotate";

import moment from 'moment';
import _ from 'lodash';
import './index.less';

import sys from '../../system'
import ImageModel from './../../models/Image';

import { PictureOutlined } from '@ant-design/icons';

import { TbPolygon as PolygonIcon } from 'react-icons/tb'

import imageUtils from '../../utils/image';
import ImageViewer from './../../components/ImageViewer'
import ResolutionPicker from './../../components/ResolutionPicker'
import ImagePicker from '../ImagePicker'
import Annotator from './../../components/ImageAnnotator/Annotator'

const createImage = (width, height) => {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height;
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = 'gray';
  ctx.fillRect(0, 0, width, height)
  return canvas.toDataURL()
}

const Component = forwardRef((props, ref) => {
  const { token } = useToken();

  const image = useRef();
  const annotatorRef = useRef();
  const [update, setUpdate] = useState();
  const [openModal, setOpenModal] = useState(false)
  const [images, setImages] = useState(props.value.images || [])
  const [selected, setSelectedImage] = useState(props.value.selected)


  const refresh = () => {
    setUpdate(sys.uuid());
  }

  useAsyncEffect(async () => {
    if (props.value?.selected != null) {
      image.current = await ImageModel.get(props.value.selected);
      if (image.current == null) {
        return;
      }
    } else {
      image.current = await ImageModel.create({
        width: props.value?.width || 1920,
        height: props.value?.height || 1080,
      });
      image.current.id = undefined;
    }
    const refImage = image.current;
    
    refImage.annotations = props.value?.region?.map((a, i) => ({
      id: `region-${i}`,
      key: `region-${i}`,
      name: `region-${i}`,
      color: 'red',
      ...a,
    })) || [];
    
    if (refImage.src == null) {
      refImage.src = createImage(refImage.width, refImage.height)
    }
    refImage.url = sys.image.url(refImage.src);
    refImage.reactImageAnnotateRegions = imageUtils.toReactImageAnnotations(refImage.annotations);
    refresh();
  }, [props.value]);

  useAsyncEffect(() => {
    if (image.current == null) {
      return;
    }
    if (image.current.src == null) {
      image.current.src = createImage(image.current.width, image.current.height)
      image.current.url = sys.image.url(image.current.src);
      sys.image.preload(image.current.url);
    }
  }, [update])

  const onOk = () => {
    image.current.annotations = annotatorRef.current.getAnnotations();
    const region = image.current.annotations
    if (typeof props.onChange === 'function') {
      props.onChange({
        images: images,
        selected: selected,
        width: image.current.width,
        height: image.current.height,
        region
      });
    }
    setOpenModal(false);
  }


  const enabledTools = ['select'];
  if (Array.isArray(props.tools)) {
    if (props.tools.includes('box')) {
      enabledTools.push('create-box');
    }
    if (props.tools.includes('polygon')) {
      enabledTools.push('create-polygon');
    }
  }

  return (
    <div
      style={{width: props.style?.width || 240, height: props.style?.height || 136}}
    >
      {
        update != null 
        ? <ImageViewer
            width={props.style?.width || 240}
            height={props.style?.height || 136}
            image={image.current}
            showBoxes={true}
            showPolygons={true}
            showTags={true}
            onClick={async () => {
              setOpenModal(true)
            }}
            showAnnotations={true}
        />
        : null
      }

      <Modal 
        title={
          <Row style={{marginRight: 50}}>
            <Col>Define your Regions</Col>
            <Col flex='auto' >
              <Space style={{ display: 'flex', justifyContent: 'end',}}>
                <Radio.Group
                  optionType='button'
                  onChange={e => {
                    const value = e.target.value.split(/[^0-9.]/g);
                    image.current.width = parseInt(value[0]);
                    image.current.height = parseInt(value[1]);
                    image.current.src = null;
                    image.current.id = null;
                    setSelectedImage()
                    refresh();
                  }}
                  value={`${image.current?.width}x${image.current?.height}`}
                  options={[
                    { label: '1:1', value: '100x100' },
                    { label: '4:3', value: '640x480' },
                    { label: '16:9', value: '640x360' },                      
                  ]}
                >
                  <Radio.Button value={{ width: 100, height: 100 }}>1:1</Radio.Button>
                  <Radio.Button value={{ width: 640, height: 480 }}>4:3</Radio.Button>
                  <Radio.Button value={{ width: 640, height: 360 }}>16:9</Radio.Button>
                </Radio.Group>

                <ResolutionPicker
                  width={image.current?.width}
                  height={image.current?.height}
                  onChange={
                    (width, height) => {
                      image.current.width = width;
                      image.current.height = height;
                      image.current.src = null;
                      image.current.id = null;
                      setSelectedImage()
                      refresh();
                    }
                  }
                />

                <ImagePicker
                  value={images}
                  selected={selected}
                  maxCount={10}
                  onSelect={async (imageId, index) => {
                    setSelectedImage(imageId)
                    const newImage = await ImageModel.get(imageId)
                    newImage.annotations = image.current.annotations;
                    image.current = newImage;
                    refresh();
                  }}
                  onChange={async (images) => {
                    setImages(images)
                  }}
                />

              </Space>
            </Col>
          </Row>
        }
        open={openModal} 
        width={'80%'}
        bodyStyle={{
        }}
        onOk={onOk}
        maskClosable={false}
        onCancel={() => {
          refresh();
          setOpenModal(false)
        }}
        centered
        destroyOnClose={true}
      >
        <div className='region-annotator' style={{width: '100%', height: '80vh'}}>
          {
            image != null && openModal ?
            <Annotator
              id={update}
              key={update}
              ref={annotatorRef}
              classes={[]}
              enabledTools={enabledTools}
              maxRegions={props.maxRegions}
              // https://www.npmjs.com/package/react-image-annotate
              image={image.current}
            />
            : null
          }
        </div>
      </Modal>



    </div>
  );
});

Component.displayName = 'RegionPicker';
export default Component;