import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from '../../../components/ProcessEditor/Handle'

import { Space, Form, Card, Select, InputNumber, Divider, Tabs, TimePicker, Button  } from 'antd';
import NodeCard from '../../../components/ProcessEditor/NodeCard'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import system from '../../../system';
import Input from '../../../components//Input';
dayjs.extend(customParseFormat)

import { TbFlag as FlagUp } from "react-icons/tb";
import { TbFlagOff as FlagDown } from "react-icons/tb";
import { sys } from 'typescript';


const Component = props => {

  if (props.data.if === undefined) {
    props.data.if = null
  }
  if (props.data.params === undefined) {
    props.data.params = {}
  }

  const [ifValue, setIf] = useState(props.data.if);
  const [params, setParams] = useState(props.data.params);
  

  const flags = []
  props.process._state.subprocess.forEach(sp => sp.nodes.forEach(n => {
    if (n.type === 'Flag' && !system.isEmpty(n.data.name)) {
      flags.push({label: n.data.name, value: n.data.name, node: n});
    }
  }));

  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
            key='1'
            name='in'
            title="Activate"
            accepts={['any']}
            activation
            process={props.process}
            subprocess={props.subprocess}
        />,
        <Handle
          key='2'
          name='true'
          title="Produces a [Activation] if True"
          style={{ top: '40%' }}
          produces={['any']}
          activation
          process={props.process}
          subprocess={props.subprocess}
        />,
        <Handle
          key='3'
          name='false'
          title="Produces a [Activation] if False"
          style={{ top: '75%', backgroundColor: 'red' }}
          produces={['any']}
          activation
          process={props.process}
          subprocess={props.subprocess}
        />
      ]}
    >
    <div style={{padding: '10px', display: 'flex', justifyContent: 'center', minWidth: '140px'}}>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          style={{width: 200}}
        >
          <Form.Item label="If" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} required colon={false}>
            <Select
              value={ifValue}
              options={[
                { value: 'is Flag Raised' },
                { value: 'is Flag Lowered' }
              ]}
              onSelect={(value) => {
                props.data.if = value;
                if (value === 'is Flag Raised' || value === 'is Flag Lowered') {
                  props.data.params = { 
                    flagName: props.data.params.flagName, 
                    moreThan: props.data.params.moreThan, 
                    lessThan: props.data.params.lessThan
                  };
                }
                setIf(props.data.if);
                setParams(props.data.params);
                props.onChange();
              }}
            />
          </Form.Item>
          { ['is Flag Raised', 'is Flag Lowered']?
            <div>
              <Form.Item label="Flag" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
                <Select
                  placeholder="Select a Flag"
                  value={params?.flagName}
                  onSelect={(value) => {
                    props.data.params = {
                      ...props.data.params,
                      flagName: value,
                    };
                    setParams(props.data.params);
                    props.onChange();
                  }}
                  options={flags}
                />
              </Form.Item>
              <div style={{display:'flex', flexDirection: 'row', gap: 20 }}>
                <Input 
                  title="for more than"
                  type={system.type.Duration} 
                  value={params?.moreThan || null} 
                  onChange={(value) => {
                    props.data.params = {
                      ...props.data.params,
                      moreThan: value
                    };
                    setParams(props.data.params);
                    props.onChange();
                  }}
                  negative={false}
                />
                <Input 
                  title="and less than"
                  type={system.type.Duration} 
                  value={params?.lessThan || null} 
                  onChange={(value) => {
                    props.data.params = {
                      ...props.data.params,
                      lessThan: value
                    };
                    setParams(props.data.params);
                    props.onChange();
                  }}
                  negative={false}
                />
              </div>
            </div>
            : null
          }

        </Form>
      </div>
    </NodeCard>
  );
};

Component.displayName = 'FlagNode';
export default Component;

