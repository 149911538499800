import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from './../../../components/ProcessEditor/Handle'

import { Select, Space, Form, Card, Modal, Button, Typography, InputNumber } from 'antd';
import NodeCard from './../../../components/ProcessEditor/NodeCard'
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { vscodeDark, vscodeDarkInit } from '@uiw/codemirror-theme-vscode';
import system from '../../../system';

const Component = props => {

  if (props.data.script === undefined) {
    props.data.script = null
  }
  if (props.data.inputCount == null) {
    props.data.inputCount = 0
  }
  if (props.data.outputCount == null) {
    props.data.outputCount = 0
  }


  const [inputCount, setInputCount] = useState(props.data.inputCount);
  const [outputCount, setOuputCount] = useState(props.data.outputCount);

  const [script, setScript] = useState(props.data.script);
  const [openModal, setOpenModal] = useState(false);

  const handles = [
    <Handle
      style={{ top: '35%' }}
      key='1'
      title="Activation"
      name='in'
      accepts={['any']}
      activation
    />,
    <Handle
      key='2'
      name='null'
      title="Produces one [Activation] if 'result' is null"
      style={{ top: '35%' }}
      produces={['any']}
      activation
    />,
    <Handle
      key='3'
      name='true'
      title="Produces one [Activation] if 'result' is True"
      style={{ top: '50%', backgroundColor: 'green' }}
      produces={['any']}
      activation
    />,
    <Handle
      key='4'
      name='false'
      style={{ top: '65%', backgroundColor: 'blue' }}
      title="Produces one [Activation] if 'result' is False"
      produces={['any']}
      activation
    />,
    <Handle
      key='5'
      name='error'
      style={{ top: '80%', backgroundColor: 'red' }}
      title="Produces one [Activation] on Error"
      produces={['any']}
      activation
    />
  ]

  for (let i = 0; i < inputCount; i++) {
    const min = 45;
    const max = 90;
    const step = (max - min) / inputCount;
    const top =  min + step/2 + (step * i) + '%'
    handles.push(
      <Handle
        key={`input-${i}`}
        title={`Input ${i}`}
        name={`input-${i}`}
        accepts={['any']}
        style={{ top }}
        process={props.process}
        subprocess={props.subprocess}
        onConnect={(connection) => {
          console.log('onConnect', connection, inputCount)
          props.data.inputCount = inputCount + 1;
          setInputCount(props.data.inputCount);
          props.onChange();
        }}
      />
    )
  }


  return (
    <NodeCard key={inputCount} {...props} handles={handles}>
      <div style={{padding: '10px', display: 'flex', justifyContent: 'center', minWidth: '320px', minHeight: '270px', flexDirection: 'column'}}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 20}}>
            <Form.Item label="Inputs" required>
              <InputNumber
                style={{width: '60px'}}
                value={inputCount}
                min={0}
                onChange={(value) => {
                  props.data.inputCount = value;
                  setInputCount(value);
                  props.onChange();
                }}
              />
            </Form.Item>
            <Form.Item label="Outputs" required>
              <InputNumber
                style={{width: '60px'}}
                value={outputCount}
                min={0}
                onChange={(value) => {
                  props.data.outputCount = value;
                  setOuputCount(value);
                  props.onChange();
                }}
              />
            </Form.Item>
         </div>
        <CodeMirror
          onClick={() => {
            setOpenModal(true)
          }}
          theme={vscodeDarkInit({
            settings: {
              caret: '#c6c6c6',
              fontFamily: 'monospace',
            }
          })}
          extensions={[langs.python()]}
          value={script || ''}
          height={'180px'}
          width={'295px'}
          editable={false}
          basicSetup={{
            lineNumbers: false,
            foldGutter: false,
            allowMultipleSelections: false,
          }}
        />
      </div>
      <Modal
        open={openModal}
        title="Edit Script"
        width={'60vw'}
        height={600}
        onOk={() => {
          setOpenModal(false);
          props.data.script = script;
          setScript(script)
        }}
        onCancel={() => {
          setOpenModal(false);
          setScript(props.data.script);
        }}
        destroyOnClose={true}
      >
        <CodeMirror
          extensions={[langs.python()]}
          value={script || ''}
          theme={vscodeDarkInit({
            settings: {
              caret: '#c6c6c6',
              fontFamily: 'monospace',
            }
          })}
          onChange={(value) => {
            if (value != null && value.trim() === '') {
              value = null
            }
            setScript(value)
          }}
          height={'800px'}
          onBlur={ev => {
            props.onChange();
          }}
          basicSetup={{
            foldGutter: true,
            allowMultipleSelections: false,
            autocompletion: false,
          }}
        />
      </Modal>
    </NodeCard>
  );
};

Component.displayName = 'ScriptNode';
export default Component;

