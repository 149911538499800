import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from './../../../components/ProcessEditor/Handle'

import { Select, Space, Form, Card, Input, Tooltip, InputNumber, Button, Divider, Slider, Row, Tabs, Col, Switch, Checkbox, Typography } from 'antd';
import useAsyncEffect from 'use-async-effect';
import {
  EyeOutlined, EyeInvisibleOutlined, EyeFilled, EyeTwoTone 
} from '@ant-design/icons';
import sys from '../../../system'
import SourceModel from '../../../models/Source'
import NodeCard from './../../../components/ProcessEditor/NodeCard'

import SVGPoint from './../../../components/ProcessEditor/SVGPoint'
import SVGBBox from './../../../components/ProcessEditor/SVGBBox'
import SVGCard from './../../../components/ProcessEditor/SVGCard'

const svgZoneProps = {
  fill: "#2ee672",
  fillOpacity: "0.3",
  stroke: "darkgreen",
  strokeWidth: "0.2",
  strokeDasharray: "1",
}

const regionDefaults = {
  top: 'w',
  height: 'h',
  base: 'w*2',
  rotate: 0,
}

const originDefaults = {
  x: "0",
  y: "0"
}

const bboxDefaults = {
  x: 40,
  y: 30,
  width: 20,
  height: 40,
}


const TrapezoidTriggerCard = (props) => {
  const bbox = props.bbox;
  const mathScope = {
    w: bbox.width, 
    h: bbox.height,
    d: Math.sqrt( Math.pow(bbox.width, 2) + Math.pow(bbox.height, 2) )
  }
  const origin = {
    x: bbox.x + bbox.width/2 + sys.evalMath(props?.origin?.x, mathScope, 0),
    y: bbox.y + bbox.height/2 + sys.evalMath(props?.origin?.y, mathScope, 0)
  }
  const b = sys.evalMath(props.top, mathScope, 0);
  const w = sys.evalMath(props.base, mathScope, 0);
  const h = sys.evalMath(props.height, mathScope, 0);
  const p1 = { 
    x: origin.x, 
    y: origin.y - b/2
  }
  const p2 = { 
    x: origin.x + h,
    y: origin.y - w/2
  }
  const p3 = { 
    x: origin.x + h, 
    y: origin.y + w/2
  }
  const p4 = { 
    x: origin.x, 
    y: origin.y + b/2
  }

  const trapezoidSVGId = `trapezoidSvg-${_.uniqueId()}`;
  const trapezoidSVG = <path 
    id={trapezoidSVGId}
    key={trapezoidSVGId}
    {...svgZoneProps}
    d={`
      M${p1.x},${p1.y}
      L${p2.x},${p2.y}
      L${p3.x},${p3.y}
      L${p4.x},${p4.y}
      Z
    `}
  />;

  const heightPathId = `heightPath-${_.uniqueId()}`;
  const heightPath = <g>
    <path 
      id={heightPathId}
      key={heightPathId}
      d={`M${origin.x},${origin.y} ${origin.x + h},${origin.y}`}
      fill="#4ba9d1"
      fillOpacity="1.0"
      stroke="red"
      strokeWidth="0.3"
      strokeDasharray="0"
    />
    <text style={{ fontSize: '3px' }}>
      <textPath href={`#${heightPathId}`} startOffset="50%" textAnchor="middle">
        height
      </textPath>
    </text>
  </g>

  const topPathId = `topPath-${_.uniqueId()}`;
  const topPath = <g>
    <path 
      id={topPathId}
      key={topPathId}
      d={`M${p4.x},${p4.y} ${p1.x},${p1.y}`}
      fill="#4ba9d1"
      fillOpacity="1.0"
      stroke="red"
      strokeWidth="0.3"
      strokeDasharray="0"
    />
    <text style={{ fontSize: '3px' }}>
      <textPath href={`#${topPathId}`} startOffset="50%" textAnchor="middle">
        top
      </textPath>
    </text>
  </g>

  const basePathId = `widthPath-${_.uniqueId()}`;
  const basePath = <g>
    <path 
      id={basePathId}
      key={basePathId}
      d={`M${p3.x},${p3.y} ${p2.x},${p2.y}`}
      fill="#4ba9d1"
      fillOpacity="1.0"
      stroke="red"
      strokeWidth="0.3"
      strokeDasharray="0"
    />
    <text style={{ fontSize: '3px' }}>
      <textPath href={`#${basePathId}`} startOffset="50%" textAnchor="middle">
        base
      </textPath>
    </text>
  </g>

  return (
    <SVGCard>
      <SVGBBox key={`trapezoid-svg-box-${_.uniqueId()}`} {...bbox} showLabels={true}/>
      <g transform={`rotate(${props.rotate || 0} ${origin.x} ${origin.y})`}>
        {trapezoidSVG}
    
        {heightPath}
        {basePath}
        {topPath}

        <SVGPoint x={p1.x} y={p1.y}/>
        <SVGPoint x={p2.x} y={p2.y}/>
        <SVGPoint x={p3.x} y={p3.y}/>
        <SVGPoint x={p4.x} y={p4.y}/>
      </g>
    </SVGCard>
  )
}



const Component = props => {

  if (props.data.top == null) {
    props.data.top = regionDefaults.top;
  }
  if (props.data.height == null) {
    props.data.height = regionDefaults.height;
  }
  if (props.data.base == null) {
    props.data.base = regionDefaults.base;
  }
  if (props.data.rotate == null) {
    props.data.rotate = regionDefaults.rotate;
  }
  if (props.data.origin == null) {
    props.data.origin = sys.clone(originDefaults);
  }
  if (props.data.bbox == null) {
    props.data.bbox = sys.clone(bboxDefaults);
  }

  const [top, setTop] = useState(props.data.top);
  const [height, setHeight] = useState(props.data.height);
  const [base, setBase] = useState(props.data.base);
  const [rotate, setRotate] = useState(props.data.rotate);
  const [origin, setOrigin] = useState(props.data.origin);
  const [bbox, setBbox] = useState(props.data.bbox);

  const marks = {
    0: '0°',
    90: '90°',
    180: '180°',
    270: '270°',
    360: '360°'
  };

  return (
    <NodeCard
      {...props}
      handles={[        
        <Handle
          key='1'
          name='activation'
          title='Activation'
          style={{ top: '40%', }}
          accepts={['any']}
          activation
        />,
        <Handle
          key='2'
          name='poly'
          title='Accepts a [Polygon]'
          style={{ top: '66%', }}
          accepts={['poly']}
          maxConnections={1}
        />,
        <Handle
          key='3'
          name='out'
          type="source"
          title='Produces a [Polygon]'
          produces={['poly']}
          activation
        ></Handle>
    ]}
    >
      <Row>
        <Col span={11}>
        <Tabs
            centered
            items={[{
                label: 'Region',
                key: '1',
                children:
                  <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} style={{padding: 16}}>
                    <Form.Item label="Top">
                      <Input
                        value={top}
                        onChange={ev => {
                          props.data.top = ev.target.value;
                          setTop(props.data.top);
                          props.onChange();
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Height">
                      <Input
                        value={height}
                        onChange={ev => {
                          props.data.height = ev.target.value;
                          setHeight(props.data.height);
                          props.onChange();
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Base">
                      <Input
                        value={base}
                        onChange={ev => {
                          props.data.base = ev.target.value;
                          setBase(props.data.base);
                          props.onChange();
                        }}
                      />
                    </Form.Item>
                    <Slider step={5} min={0} max={360} value={rotate} marks={marks}
                      onChange={value => {
                        props.data.rotate = value;
                        setRotate(value);
                        props.onChange();
                      }}
                    />
                  </Form>
            }, {
              label: 'Origin',
              key: '2',
              children:
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} style={{padding: 16}}>
                  <Typography.Paragraph>The Region Origin is the center of the Object</Typography.Paragraph>
                  <Divider></Divider>

                  <Form.Item label="Offset X">
                    <Input
                      value={origin.x}
                      onChange={ev => {
                        props.data.origin.x = ev.target.value;
                        setOrigin({ ...props.data.origin });
                        props.onChange();
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Offset Y">
                    <Input
                      value={origin.y}
                      onChange={ev => {
                        props.data.origin.y = ev.target.value;
                        setOrigin({ ...props.data.origin });
                        props.onChange();
                      }}
                    />
                  </Form.Item>
                </Form>
            }, {
              label: 'Object',
              key: '3',
              children:
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} style={{padding: 16}}>
                  <Typography.Paragraph>
                    Object Width and Height are just for visualization.<br/>
                    The values of w and w are relative to the image size
                  </Typography.Paragraph>
                  <Typography.Paragraph></Typography.Paragraph>
                  <Divider></Divider>
                  <Form.Item label="w">
                    <InputNumber
                      value={bbox.width}
                      min={0}
                      step={'5'}
                      onChange={value => {
                        props.data.bbox.width = value;
                        setBbox({ ...props.data.bbox });
                        props.onChange();
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="h">
                    <InputNumber
                      value={bbox.height}
                      min={0}
                      step={'5'}
                      onChange={value => {
                        props.data.bbox.height = value;
                        setBbox({ ...props.data.bbox });
                        props.onChange();
                      }}
                    />
                  </Form.Item>
                </Form>
            }
          ]}
          />
        </Col>
        <Col span={1}>
          <Divider style={{height: '100%'}} type="vertical" />
        </Col>
        <Col span={12}>
          <TrapezoidTriggerCard 
            top={top} 
            base={base} 
            height={height} 
            rotate={rotate} 
            origin={origin}
            bbox={bbox}
          />
        </Col>
      </Row>
    </NodeCard>
  );
};

Component.displayName = 'DefineRegionNode';
export default Component;

