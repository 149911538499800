import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Progress, Form, Skeleton, Checkbox, Steps, Popover, Pagination, Collapse, Upload, Button, Radio, Tabs, Popconfirm, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin, Select, Drawer, Tooltip, Divider    } from 'antd';
import dayjs from 'dayjs';
import sys from '../../system'

import { PictureOutlined } from '@ant-design/icons';

import ImageModel from '../../models/Image';

import ImageViewer2 from '../ImageViewer2'

const getDeltaµs = (start, finish) => {
  if (start == null || finish == null) {
    return 0
  }
  
  const s = dayjs(start)
  const e = dayjs(finish)
  const duration = e.diff(s, 'millisecond', true) * 1000;
  return duration;
  /**
  const startMicros = parseInt(start.toISOString().split('.')[1].replace(/Z$/,'').padEnd(6, '0').substring(3))
  const endMicros = parseInt(finish.toISOString().split('.')[1].replace(/Z$/,'').padEnd(6, '0').substring(3))

  let microns;

  if (duration == 0) {
    microns = endMicros - startMicros;
  } else {
    microns = endMicros + (1000 - startMicros);
  }
  
  return duration * 1000 + microns;
  */
}

const ImageViewer = (props) => {

  const process = props.process;
  const exec = props.exec;

  const [image, setImage] = useState(null)

  useAsyncEffect(async () => {
    if (props.process == null || props.exec == null) {
      return;
    }
    const image = await ImageModel.get(process?.options?.image_id);

    const geometry = []
    if (exec.poly != null) {
      geometry.push(exec.poly)
    }
    if (exec.polyset != null) {
      geometry.push(...exec.polyset)
    }
    image.geometry = geometry;
    
    console.log(image)
    setImage(image)
  }, [props.image, props.exec]);



  return <ImageViewer2 image={image} showGeometry={true} showPolygons={true} showBoxes={true} showLabels={true}/>;
}


const humanizeDuration = (duration) => {
  if (duration >= 1_000_000){ 
    return (duration / 1_000_000).toFixed(1) + 's';
  }
  if (duration >= 1_000){
    return (duration / 1_000).toFixed(1) + 'ms';
  } else {
    return duration + 'µs';
  }
}

const Component = (props) => {


  const [items, setItems] = useState([])
  const [current, setCurrent] = useState(null)

  
  
  useAsyncEffect(async () => {

    const process = props.process;

    if (props.subprocess == null) {
      return;
    }

    const total = getDeltaµs(process.start, process.finish)
    let success = 0;

    const nodes = {}
    process._state.subprocess.forEach((sp) => {
      sp.nodes.forEach((node) => {
        nodes[node.id] = ({
          ...node,
          nodeId: node.id,
          subprocessId: sp.id,
        })
      })
    });

    const items = []

    process._state.execution.forEach((exec) => {
      const item = {}
      const node = nodes[exec.id]
  
      const duration = getDeltaµs(exec.start, exec.finish);
      item.nodeId = node?.nodeId;
      item.subprocessId = node?.subprocessId;          
      item.title = node?.data?.title;
      item.description =
      <div style={{fontSize: 12}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{flexGrow: 1}}>
            {exec?.message}
          </div>
          <div style={{flexShrink: 1}}>
            {
              exec?.polyset != null || exec?.poly
              ? <div>
                  <Popover
                    placement="right"
                    content={
                      <div style={{width: 1024, height: 576}}>
                        <ImageViewer
                          process={process}
                          exec={exec}
                        />
                      </div>
                    }
                    title="Title"
                    trigger="click"
                  >
                    <Button size='small' type='text' icon={<PictureOutlined />}></Button>
                  </Popover>
              </div>
              : null
            }
            </div>
        </div>
        <Progress 
          size={[280, 5]}
          percent={ (success + duration) / total * 100}
          success={{ percent: success / total * 100}}
          title={humanizeDuration(duration)}
          //trailColor={'white'}
          //strokeLinecap={'square'}
          //strokeColor={'#1890ffff'}
          showInfo={false}
          //format={() => <div style={{fontSize:8}}>{humanizeDuration(duration)}</div>}
        />
      </div>;
      success += duration
      

      items.push(item)
    })

    setItems(items)


  }, [props.process]);


  return <div 
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
  >
    <div
      style={{
        flexGrow: 1,
        overflow: 'auto',
        paddingRight: 10,
      }}
    >
      <Steps
        direction="vertical"
        size="small"
        current={current}
        items={items}
        onChange={(current) => {
          setCurrent(current)
          if (typeof props.onChange === 'function') {
            props.onChange(items[current].nodeId, items[current].subprocessId)
          }
        }}
      />
    </div>
    <div style={{
      height: 60,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 20,
    }}>
      <Divider style={{ width: '100%', marginTop: 20, marginBottom: 16}}/>
      <Pagination
        simple 
        current={current + 1} 
        pageSize={1}
        total={items.length} 
        onChange={(current) => {
          current = current - 1
          setCurrent(current)
          if (typeof props.onChange === 'function') {
            props.onChange(items[current].nodeId, items[current].subprocessId)
          }
        }}
      />
    </div>
  </div>
}

Component.displayName = 'Timeline';
export default Component;