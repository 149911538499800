import { defaults } from 'lodash';
import sys, { type } from '../system';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime, Integer, Cron } = type;


export default Table({
    name: 'Schedule',
    table: 'schedule',
    columns: {
        name: Text({
            title: 'Name',
            onChange: (value, row) => {
                if (value != null) {
                    value = value.replace(/[^a-z0-9\-\._]/ig,'');
                }
                if (row.description == null || row.name == row.description) {
                    row.description = value;
                }
                return value;
            }
        }),
        description: Text({
            title: 'Description',
            description: "Should describe the Schedule, the frequency, the process and what's for.",
        }),
        start: DateTime({
            title: 'Start',
            description: 'Defines the date that the Scheduler starts creating Jobs',
            onChange: (start, row) => {
                if (start != null) {
                    const end = row.end;
                    if (end != null) {
                        if (start.isAfter(end)) {
                            row.end = null;
                        }
                    }
                }
                return start;
            }
        }),
        end: DateTime({
            title: 'End',
            description: 'Defines the date that the Scheduler should not create anymore Jobs',
            onChange: (end, row) => {
                if (end != null) {
                    const start = row.start;
                    if (start != null) {
                        if (end.isBefore(start)) {
                            row.start = null;
                        }
                    }
                }
                return end;
            }
        }),
        priority: Integer({
            title: 'Priority',
            description: 'Prioritizes Jobs among other Jobs, Workers will always pick a Job with higher priority',
            defaultsTo: 0,
        }),
        expression: Cron({
            title: 'Frequency'
        }),
        status: Text({
            title: 'Status',
            values: ['ACTIVE', 'INACTIVE'],
            defaultsTo: 'ACTIVE',
        }),
        queue_size: Integer({
            title: 'Queue Size',
            description: 'By defining a Queue size you can prevent waiting times between Jobs executions',
            defaultsTo: 0,
        }),
        process_id: Uuid({
            title: 'Process',
            description: 'Process selected to Run by the Scheduler',
            values: (row) => {
                return sys.orm({
                    select: 'select id as value, name as label from process'
                })
            }
        }),
        timeout: Integer({
            title: 'Timeout (ms)',
            description: 'Timeout in milliseconds, that the Job should start within, if not, the job will be cancelled.',
            defaultsTo: 60_000,
        }),
        created_at: DateTime({
            title: 'Created At',
            defaultsTo: () => sys.now(),
        }),
        process: Relation({
            to: 'Process',
            cardinality: '?',
            where: "id = $process_id"
        })
    }
})