import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Form, Popconfirm, Skeleton, Checkbox, Button, Modal, Tooltip, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import Input from '../components/Input'

import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import { SyncOutlined as RefreshIcon } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';

import Table from '../components/Table';
import PageHeader from '../components/PageHeader'
import ScheduleForm from '../forms/Schedule'
import ScheduleModel from './../models/Schedule.js'
import JobModel from './../models/Job.js'
import sys from '../system'
const { Text, DateTime, Integer } = sys.type;


const NextRun = (props) => {
  
  useAsyncEffect(async () => {
    
  }, []);
  
  return <div>nada</div>;
}

const Component = () => {

  const [scheduleId, setScheduleId] = useState()
  const table = useRef();
  const scheduleForm = useRef();

  const [activeSchedules, setActiveSchedules] = useState({
    id: sys.uuid(),
    scheds: []
  })


  const refetchScheds = async () => {
    const response = await sys.api('/schedules', { method: 'get' });
    if (response.ok) {
      const json = await response.json();
      setActiveSchedules({
        id: sys.uuid(),
        scheds: json
      })
    }
  }

  useAsyncEffect(async () => {
    refetchScheds();
  }, [])


  if (activeSchedules == null) {
    return <></>;
  }

  return (
    <div>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>
          <Tooltip title='Synchronize schedule instances' placement='bottom'>
            <Button type='text' icon={<RefreshIcon />} onClick={async () => {
              await sys.api('/schedules', { method: 'PUT' });
              await refetchScheds();
              sys.notify({
                type: 'success',
                placement: 'top',
                message: 'Schedule instances synchronized!'
              })
            }}/>
          </Tooltip>        
          <Typography.Title style={{margin: 0}} level={5} >List of Schedules</Typography.Title>
        </div>
      </PageHeader>
      <Table
        key={activeSchedules?.id}
        title="Schedules"
        ref={table}
        query={{
          count: "select count(*) from schedule",
          select: `
            select * from schedule`,
          limit: 20,
          orderBy: {
            name: 'desc'
          }
        }}
        columns={[
          { 
            title: 'Name', 
            sorter: true,
            name: 'name',
            type: Text,
            width: '140px',
          },
          { 
            title: 'Description', 
            sorter: true,
            name: 'description',
            type: Text,
          },
          { 
            title: 'Schedule', 
            sorter: true,
            name: 'expression',
            width: '140px',
            type: Text,
          }, { 
            title: 'Priority', 
            sorter: true,
            name: 'priority',
            type: Integer,
          },
          { 
            title: 'Timeout', 
            sorter: true,
            name: 'timeout',
            type: Integer,
          },
          { 
            title: 'Queue Size', 
            sorter: true,
            name: 'queue_size',
            type: Integer,
          },
          { 
            title: 'Status', 
            sorter: true,
            name: 'status',
            width: '140px',
            type: Text,
          },
          { 
            title: 'Created At', 
            sorter: true,
            name: 'created_at',
            type: DateTime,
          },
          {
            title: 'In Sync?',
            width: '140px',
            render: (e) => {
              const schedule = activeSchedules?.scheds.find(s => e.id === s.id)
              return <Input value={e.stamp === schedule?.stamp} type={sys.type.Boolean} disabled={true}></Input>
            }
          },
          {
            title: 'Actions',
            width: '300px',
            render: (record) => {
              return (
                <div>
                  <Button type='link' onClick={() => setScheduleId(record.id)}>Edit</Button>
                  <Button type='link' danger
                    onClick={() => {
                      Modal.confirm({
                        type: 'warning',
                        title: 'Are you sure?',
                        content: `Are you sure you want to Delete Jobs in status: CANCELLED, ERROR, SUCCESS of this Schedule?`,
                        onOk: async () => {
                          const jobs = await JobModel.select(`
                              schedule_id = $1 
                              and (
                                  status in ('CANCELLED', 'ERROR', 'SUCCESS')
                                  or (status = 'RUNNING' and timeout < now())
                              )
                            `, 
                            [record.id]
                          );
                          const trx = sys.type.Transaction();
                          jobs.forEach(job => {
                            console.log(job.id, job.status)
                            job.delete = true;
                            trx.add(job);
                          });
                          await trx.save();
                        },
                        okText: "Yes I'm sure!",
                        okType: 'danger',
                      });
                    }}
                  >
                    Clean Jobs
                  </Button>

                  <Button type='link' danger
                    onClick={() => {
                      Modal.confirm({
                        type: 'warning',
                        title: 'Are you sure?',
                        content: `Are you sure you want to Delete this Schedule?`,
                        onOk: async () => {
                          const sched = await ScheduleModel.get(record.id)
                          sched.delete = true;
                          await sched.save();
                          table.current.refresh();
                        },
                        okText: "Yes I'm sure!",
                        okType: 'danger',
                      });
                    }}
                  >
                    Delete
                  </Button>

              
                </div>
              )
            }
          }
        ]}
        footer={<div>
          <Button type='primary' onClick={() => setScheduleId(null)}>New Schedule</Button>
        </div>}
      />
      <Modal 
        width={800}
        open={scheduleId !== undefined} 
        onOk={async () => {
          await scheduleForm.current.save();
          setScheduleId(undefined);
          table.current.refresh();
        }}
        onCancel={() => setScheduleId(undefined)}
        maskClosable={false}
        destroyOnClose={true}
      >
        <ScheduleForm ref={scheduleForm} id={scheduleId}/>
      </Modal>
    </div>
  );
};
export default Component;