import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';
import PageHeader from '../components/PageHeader'

import Table from '../components/Table';

import sys from '../system'
const { Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = sys.type;




const Component = () => {
  const navigate = useNavigate()

  return (
    <div>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>
          <Typography.Title style={{margin: 0}} level={5} >Dataset List</Typography.Title>
        </div>
      </PageHeader>
      <Table
        title='Datasets'
        query={{
          count: "select count(*) from dataset",
          select: `
            select * from dataset`,
          limit: 20,
          orderBy: {
            name: 'desc'
          }
        }}
        columns={[
          { 
            title: 'Name', 
            sorter: true,
            name: 'name',
            type: Text,
            width: '200px',
            render: (record) => {
              return <Link to={`/dataset/${record.id}`}>{record.name}</Link>
            }
          },
          { 
            title: 'Description', 
            sorter: true,
            name: 'description',
            type: Text,
            width: 'auto',
            mobile: false,
          },
          { 
            title: 'Status', 
            name: 'status',
            sorter: true,
            type: Text,
            width: '140px',
          },
          { 
            title: 'Created At', 
            sorter: true,
            name: 'created_at',
            type: DateTime,
            mobile: false,
          },
          {
            title: 'Actions',
            render: (record) => {
              return (
                <div>
                  <Link to={`/dataset/${record.id}`}><Button type='link'>Edit</Button></Link>
                  <Link to={`/dataset/${record.id}/statistic`}><Button type='link'>Statistics</Button></Link>
                  <Link to={`/dataset/${record.id}/review`}><Button type='link'>Review</Button></Link>
                  <Link to={`/dataset/${record.id}/result`}><Button type='link'>Problems</Button></Link>
                </div>
              )
            }
          }
        ]}
        footer={
          <Button type='primary' onClick={() => setSourceId(null)}>New Dataset</Button>
        }
    />
    </div>
  );
};
export default Component;