import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu, Input, Spin } from 'antd';
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store';
import sys from '../system';

const Component = (props) => {

  const [src, setSrc] = useState();

  useAsyncEffect(async () => {

    const response = await sys.api('birt', {
      method: 'POST',
      body: {
        rptDesign: props.rptDesign,
        parameters: props.parameters,
      }
    });

    setSrc(response);

  }, []);

  return (
    <div style={{minWidth: 300, minHeight: 300}}>
      { 
        src == null ? 
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-50px',
            marginLeft: '-50px',
            width: '100px',
            height: '100px',
        }}>
        <Spin tip="Generating Report"></Spin>
      </div>
      :
      <iframe style={{width:'100%', height: '100%'}} src={`/reports/${src}`}/>
      }      
    </div>
  )
};

export default Component;