import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu } from 'antd';
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store';

import Input from './../components/Input'

import SourceModel from '../models/Source';


const Component = forwardRef((props, ref) => {
 

  const [source, setSource] = useState();
  

  useImperativeHandle(ref, () => ({
    save: async () => {
      await source.save();
    }
  }));

  useAsyncEffect(async () => {
    let source;
    if (props.id == null) {
      source = await SourceModel.create();
    } else {
      source = await SourceModel.get(props.id);
    }
    console.log(source)
    setSource(source);
  }, [props.id]);


  


  return (
    <>
      <Input model={source} column='name' ></Input>
      <Input model={source} column='description' ></Input>
      <Input model={source} column='config' />
      <Input model={source} column='status' />
    </>
  );
});

Component.displayName = 'Component';
export default Component;