import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';
import PageHeader from '../components/PageHeader'

import Table from '../components/Table';

import sys from '../system'
const { Uuid, Text, Upper, Number, Relation, Json, DateTime, Integer, Duration, Boolean } = sys.type;




const Component = () => {

  return (
    <div>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>
          <Typography.Title style={{margin: 0}} level={5} >Process List</Typography.Title>
        </div>
      </PageHeader>
      <Table
        title='Processes'
        scroll={{y: 'calc(100vh - 280px)'}}
        query={{
          count: "select count(*) from process",
          select: `
            select 
              *
            from (
              select 
                process_run.id,
                process.name,
                process.description,
                process_run.start,
                process_run.finish,
                process_run.created_at,
                case 
                  when process_run.simulation_id is not null then true 
                  else false 
                end is_simulation
              from 
                process_run
                left join process on (process_run.process_id = process.id)
            ) as process_run
          `,
          limit: 20,
          orderBy: {
            created_at: 'desc'
          }
        }}
        columns={[
          { 
            title: 'Name', 
            sorter: true,
            name: 'name',
            type: Text,
            width: '280px',
            render: (record) => {
              return <Link to={`/processRun/${record.id}`}>{record.name}</Link>
            }
          },
          { 
            title: 'Description', 
            sorter: true,
            name: 'description',
            type: Text,
            width: 'auto',
          },
          { 
            title: 'Start', 
            sorter: true,
            name: 'start',
            type: DateTime,
          },
          { 
            title: 'Finish', 
            name: 'finish',
            sorter: true,
            type: DateTime,
          },
          { 
            title: 'Duration',
            width: '120px',
            align: 'center',
            render: (record) => {
              if (record.start != null && record.finish != null) {
                const start = new Date(record.start);
                const finish = new Date(record.finish);
                const diff = (finish - start) / 1000;
                return diff.toFixed(3) + ' secs';
              }
              return '--'
            }
          },
          { 
            title: 'Simulation', 
            width: '120px',
            align: 'center',
            sorter: true,
            name: 'is_simulation',
            type: Boolean
          },
          { 
            title: 'Created At', 
            sorter: true,
            name: 'created_at',
            type: DateTime,
          },
          {
            title: 'Actions',
            width: '300px',
            render: (record) => {
              return (
                <div>
                  <Link to={`/processRun/${record.id}`}>View</Link>
                </div>
              )
            }
          }
        ]}
    />
    </div>
  );
};
export default Component;