import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu, Input, Collapse  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { EyeOutlined } from '@ant-design/icons'

const Component = (props) => {
  const rectangle = <rect 
    id={`bbox-1`} key={`bbox-1`}
    x={`${props.x}`} 
    y={`${props.y}`} 
    width={`${props.width}`} 
    height={`${props.height}`}

    fill="#4ba9d1"
    fillOpacity="1.0"
    stroke="black"
    strokeWidth="0.8"
  />;

  const diagonal = <path 
    id={`diagonal-1`} key={`diagonal-1`}
    d={`
      M${props.x},${props.y+props.height}
      L${props.x+props.width},${props.y}
      Z
    `}
    stroke="black"
    strokeWidth="0.3"
    strokeDasharray="0"
  />;

  const offset = 1;

  // WIDTH
  const widthPathId = `widthPathId${_.uniqueId()}`
  const widthLabel = <g id={widthPathId} key={widthPathId}>
    <defs>
    <path 
      d={`M${props.x},${props.y-offset} ${props.x + props.width},${props.y-offset}`}
    />
    </defs>
    <text style={{ fontSize: '3px' }}
    >
      <textPath href={`#${widthPathId}`} startOffset="50%" textAnchor="middle">
        w
      </textPath>
    </text>
  </g>;

  // HEIGHT
  const heightPathId = `heightPath${_.uniqueId()}`
  const heightLabel = <g id={heightPathId} key={heightPathId}>
    <path 
      d={`M${props.x-offset},${props.y + props.height} ${props.x-offset},${props.y}`}
    />
    <text style={{ fontSize: '3px' }}>
      <textPath href={`#${heightPathId}`} startOffset="50%" textAnchor="middle">
        h
      </textPath>
    </text>
  </g>

  // DIAGONAL
  const diagonalPathId = `diagonalPath${_.uniqueId()}`
  const diagonalLabel = <g id={diagonalPathId} key={diagonalPathId}>
    <path 
      d={`M${props.x-offset},${props.y-offset + props.height} ${props.x-offset + props.width},${props.y}`}
    />
    <text style={{ fontSize: '3px' }}>
      <textPath href={`#${diagonalPathId}`} startOffset="50%" textAnchor="middle">
        d
      </textPath>
    </text>
  </g>

  return [
    rectangle, diagonal,
    props.showLabels && widthLabel,
    props.showLabels && heightLabel,
    props.showLabels && diagonalLabel
  ]
};

Component.displayName = 'SVGBBox';
export default Component;