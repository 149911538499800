import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card } from 'antd';
import { useNavigate, useParams } from "react-router-dom";

import useAsyncEffect from 'use-async-effect';
import sys from '../../system'


import imageUtils, { stringToColor } from '../../utils/image';


const Component = (props) => {

  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState();
  const [sources, setSources] = useState([])


  useAsyncEffect(async () => {
    
    const dataset = props.dataset;
    if (dataset == null) {
      return;
    }
    let sourceClassCount = await dataset.getSourceClassCount()

    let sources = {}
    let data = {}
    sourceClassCount.forEach(a => {
      if (data[a.class_name] == null) {
        data[a.class_name] = { 
          name: a.class_name,
          [a.source_name]: 0
        }
      }
      if (data[a.class_name][a.source_name] == null) {
        data[a.class_name][a.source_name] = 0;
      }
      data[a.class_name][a.source_name] += a.count;
      sources[a.source_name] = null;
    });
    
    setSources(Object.keys(sources))
    setData(Object.values(data))

  }, [props.dataset])

  
  return (
    <ResponsiveContainer width="100%" height="50%">
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {
          sources.map(s => 
            <Bar 
              key={s} 
              dataKey={s} 
              stackId="a" 
              fill={imageUtils.stringToColor(s.split('').reverse().join())} 
              onClick={(barParams) => {
                navigate(`/dataset/${params.datasetId}/${barParams.name}/view`)
              }}
            />
          )
        }
      </BarChart>
    </ResponsiveContainer>
  )
};

export default Component;