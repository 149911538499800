import { type } from '../system.js';
const { Text, Integer, Table, Uuid, DateTime, Relation } = type;


export default Table({
    name: 'Log',
    table: 'log',
    columns: {
        level: Text(),
        name: Text(),
        message: Text(),
        instance: Uuid(),
        index: Integer(),
        created_at: DateTime(),
    }
})