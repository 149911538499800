import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, theme, Row, Col, Dropdown, Tooltip, Typography, Space, Transfer, Tabs, List, Menu, Input, Collapse  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { EyeOutlined } from '@ant-design/icons'
import { BsCameraFill as CaptureIcon } from 'react-icons/bs'
import { BiDotsVertical as SettingsIcon } from 'react-icons/bi'
import { BiExitFullscreen as ExitFullScreenIcon } from 'react-icons/bi'
import { BiFullscreen as FullScreenIcon } from 'react-icons/bi'
import { FaPlay  as PlayIcon } from 'react-icons/fa';
import { TbPolygon as PolygonIcon } from 'react-icons/tb'
import { BsBoundingBox as BoxIcon } from 'react-icons/bs'
import { AiOutlineTag as TagIcon } from 'react-icons/ai'
import { BiText as TextIcon } from 'react-icons/bi'
import { PercentageOutlined as ConfidenceIcon } from '@ant-design/icons'
import { BiSolidEditAlt  as OpenEditorIcon} from "react-icons/bi";


import ImageModel from '../../models/Image';
import imageUtils from '../../utils/image';
import sys from '../../system';

import './ImageViewer.less';

const { useToken } = theme;


const Component = (props) => {

  const containerRef = useRef(null);
  const timerName = useRef(sys.uuid());
  const [update, setUpdate] = useState(sys.uuid());
  const [image, setImage] = useState();
  const [source, setSource] = useState();
  const [url, setUrl] = useState()
  const [svgBoundigBox, setSvgBoundigBox] = useState([])

  const [showPolygons, setShowPolygons] = useState(props.showPolygons);
  const [showBoxes, setShowBoxes] = useState(props.showBoxes);
  const [showLabels, setShowLabels] = useState(props.showLabels);
  const [showConfidence, setShowConfidence] = useState(props.showConfidence);
  const [showTags, setShowTags] = useState(props.showTags);

  const [showGeometry, setShowGeometry] = useState(props.showGeometry);
  const [showAnnotations, setShowAnnotations] = useState(props.showAnnotations);

  const [fullscreen, setFullscreen] = useState(false);

  const [geometry, setGeometry] = useState();

  const { token } = useToken();

  useAsyncEffect(async () => {
    let image;
    if (props.imageId != null) {
      image = await ImageModel.get(props.imageId);
    } else if (props.image != null) {
      image = props.image;
    }

    if (image != null) {
      
      const width = containerRef?.current?.offsetWidth;
      const height = containerRef?.current?.offsetHeight;
      const url = sys.image.url(image.src, { width, height });
      
      await sys.image.preload(url)
      setUrl(url)
      setImage(image);
    }
  }, [props.imageId, props.image]);

  useAsyncEffect(async () => {
    if (image != null) {
      const width = containerRef?.current?.offsetWidth;
      const height = containerRef?.current?.offsetHeight;
      const url = sys.image.url(image.src, { width, height });
      
      await sys.image.preload(url)
      setUrl(url)
    }
  }, [update])

  useAsyncEffect(async () => {
    if (image != null && (showBoxes || showPolygons)) {
      const geometry = []
      if (showGeometry) {
        let geo = await image.geometry
        if (props.simulationId != null) {
          geo = geo.filter(g => {
            return g.simulation_id === props.simulationId
          })
        }
        geometry.push(...geo)
      }
      if (showAnnotations) {
        let annotations = await image.annotations || []
        geometry.push(...annotations)
      }
      image.svgBoundigBox = await imageUtils.toSvgBoundingBox(image, geometry, {
        showLabels: showLabels,
        showConfidence: showConfidence,
        showBoxes: showBoxes,
        showPolygons: showPolygons
      });
      setSvgBoundigBox(image.svgBoundigBox)
    }

  }, [image, showBoxes, showPolygons, showLabels, showConfidence, showGeometry, showAnnotations, props.simulationId])


  useAsyncEffect(async () => {
    if (image != null && props.showSource === true) {
      const source = await image.source
      setSource(source)
    }

  }, [image, props.showSource])



  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      sys.timers.start(`Viewer-Refresh-${timerName.current}`, () => {
        setUpdate(sys.uuid())
      }, 1000, 1)
    });
    resizeObserver.observe(containerRef.current);
    return () => { 
      resizeObserver.disconnect();
    }
  }, []);
  
  const shortcuts = e => {
    if (e.key === 'Enter') {
      if (!fullscreen) {
        setFullscreen(true)
      }
    } else if (e.key === 'Escape') {
      if (fullscreen) {
        setFullscreen(false);
      }
    } else if (e.key === 'l') {
      setShowLabels(!showLabels);
    } else if (e.key === 'c') {
      setShowConfidence(!showConfidence);
    } else if (e.key === 'b') {
      setShowBoxes(!showBoxes);
    } else if (e.key === 'p') {
      setShowPolygons(!showPolygons);
    } else if (e.key === 't') {
      setShowTags(!showTags);
    } else if (e.key === 's') {
      setInterval()
    } else if (e.key === 'e') {
      window.open(`/annotate/${image?.id}`, '_blank')
    }
  }


  let style = {
    ...props.style,
    backgroundColor: token.colorIconHover,
  };
  if (fullscreen) {
    style = {
      ...style,
      position: 'fixed',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      maxWidth: 'unset',
      minHeight: 'unset',
      borderWidth: 0,
      top: 0,
      left: 0,
      margin: 0,
      padding: 0,
      zIndex: 500,
    }
  } else {
    style = {
      ...style,
      position: 'relative',
      height: '100%',
      width: '100%',
      minHeight: 135,
      minWidth: 240,
    }
  }

  return (
    <div 
      tabIndex="0"
      className='image-viewer-container'
      ref={containerRef}
      style={style}
      onKeyDown={shortcuts}
      onClick={props.onClick}
    >
      { /* SOURCE */ 
        source != null && props.showSource === true || true
        ? <div style={{
            position: 'absolute',
            color: 'white',
            whiteSpace: 'nowrap',
            backgroundColor: '#00000062',
            top: '20px',
            left: '10px',
            margin: '0px',
            paddingLeft: '10px',
            paddingRight: '10px',
            transform: 'translate(0%, -50%)',
          }}>
            <div>
              {source?.name}
            </div>
          </div>
        : null
      }

      { /* timestamp */ 
        props.showTimestamp === true
        ? <div style={{
            position: 'absolute',
            color: 'white',
            backgroundColor: '#00000062',
            whiteSpace: 'nowrap',
            top: '20px',
            left: '50%',
            margin: '0px',
            paddingLeft: '10px',
            paddingRight: '10px',
            transform: 'translate(-50%, -50%)',
          }}>
            <div>
              {sys.format.datetime(image?.created_at, 'l LTS')}
            </div>
          </div>
        : null
      }

      { /* settings */ }
        <div style={{
          position: 'absolute',
          top: '20px',
          margin: '0px',
          right: '0px',
          transform: 'translate(0%, -50%)',
        }}>
          {
            props.enableSettings !== false ?
            <Dropdown 
              placement='bottomRight'
              menu={{
                items: [
                  {
                    key: '1',
                    style: { color: showLabels ? 'blue' : null },
                    label: <div>Labels <kbd>l</kbd></div>,
                    onClick: () => setShowLabels(!showLabels),
                    icon: <TextIcon/>
                  },
                  {
                    key: '2',
                    style: { color: showConfidence ? 'blue' : null },
                    label: <div>Confidence <kbd>c</kbd></div>,
                    onClick: () => setShowConfidence(!showConfidence),
                    icon: <ConfidenceIcon/>
                  },                
                  {
                    key: '3',
                    style: { color: showBoxes ? 'blue' : null },
                    label: <div>Boxes <kbd>b</kbd></div>,
                    onClick: () => setShowBoxes(!showBoxes),
                    icon: <BoxIcon/>
                  },
                  {
                    key: '4',
                    style: { color: showPolygons ? 'blue' : null },
                    label: <div>Polygons <kbd>p</kbd></div>,
                    onClick: () => setShowPolygons(!showPolygons),
                    icon: <PolygonIcon/>
                  },
                  {
                    key: '5',
                    style: { color: showTags ? 'blue' : null },
                    label: <div>Tags <kbd>t</kbd></div>,
                    onClick: () => setShowTags(!showTags),
                    icon: <TagIcon/>
                  }, 
                ]
              }}
            >
              <Button 
                type="text"
                size='large'
                style={{
                  color: 'white',
                  filter: 'drop-shadow(2px 2px 1.5px #000000FF)',
                  fontSize: '20px',
                }}
                icon={<SettingsIcon/>}>
              </Button>
            </Dropdown>
            : null
          }
          {
            props.enableEdit !== false ?
            <Tooltip
              key={sys.uuid()}
              title={<div>Annotate Image <kbd>E</kbd></div>}
            >
              <Button 
                type="text"
                size='large'
                style={{
                  color: 'white',
                  filter: 'drop-shadow(2px 2px 1.5px #000000FF)',
                  fontSize: '20px',
                }}
                onClick={() => { 
                  window.open(`/annotate/${image?.id}`, '_blank')
                }}
                icon={<OpenEditorIcon/>}
              />
            </Tooltip>
            : null
          }
          {
            props.enableFullscreen !== false ?
            <Tooltip
              key={fullscreen+sys.uuid()}
              title={fullscreen ? <div>Exit Fullscreen <kbd>Esc</kbd></div> : <div>Fullscreen <kbd>Enter</kbd></div>}
            >
              <Button 
                type="text"
                size='large'
                style={{
                  color: 'white',
                  filter: 'drop-shadow(2px 2px 1.5px #000000FF)',
                  fontSize: '20px',
                }}
                onClick={() => { 
                  setFullscreen(!fullscreen)
                }}
                icon={fullscreen ? <ExitFullScreenIcon/> : <FullScreenIcon/>}
              />
            </Tooltip>
            : null
          }
        </div>

      {
        image != null != null?
        <svg
          className='image-viewer-svg'
          style={{
            backgroundImage: `url(${url})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            transition: 'background-image 0.5s ease-in-out'
          }}
          width='100%'
          height='100%'
          viewBox={`0 0 ${image?.width} ${image?.height}`}
        >
          <g>
            {svgBoundigBox}
          </g>
        </svg>
        : null
      }
    </div>
  )
};

Component.displayName = 'ImageViewer';
export default Component;