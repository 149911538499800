import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Space, Transfer, Tabs, List, Menu, Input, Collapse  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { EyeOutlined, ZoomOutOutlined as ZoomInIcon, ZoomInOutlined as ZoomOutIcon, 
  
  FullscreenExitOutlined as CenterIcon} from '@ant-design/icons'


function getPointFromEvent (event) {
  var point = {x:0, y:0};
  if (event.targetTouches) {
    point.x = event.targetTouches[0].clientX;
    point.y = event.targetTouches[0].clientY;
  } else {
    point.x = event.clientX;
    point.y = event.clientY;
  }
  return point;
}

const Component = (props) => {
  
  const container = useRef();
  
  const [cursor, setCursor] = useState('grab');
  const [zoom, setZoom] = useState(1)
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
 
  const mouseState = useRef();

  const xmin = -(50 * (zoom-1));
  const ymin = -(50 * (zoom-1));
  const xmax = zoom * 100;
  const ymax = zoom * 100;
  const viewBox = `${xmin + offsetX} ${ymin + offsetY} ${xmax} ${ymax}`;

  const onMouseDown = (ev) => {
    mouseState.current = { 
      startX: ev.clientX,
      startY: ev.clientY,
      startOffsetX: offsetX,
      startOffsetY: offsetY,
      ratioX: xmax / container.current.getBoundingClientRect().width,
      ratioY: ymax / container.current.getBoundingClientRect().height
    };
    console.log(mouseState.current);
    setCursor('grabbing');
  };
  const onMouseMove = (ev) => {
    ev.preventDefault();
    if (mouseState.current == null) {
      return;
    }

    const currentX = ev.clientX;
    const currentY = ev.clientY;
    
    const offsetX = mouseState.current.startOffsetX + (mouseState.current.startX - currentX) * mouseState.current.ratioX;
    const offsetY = mouseState.current.startOffsetY + (mouseState.current.startY - currentY) * mouseState.current.ratioY;

    setOffsetX(Math.round(offsetX));
    setOffsetY(Math.round(offsetY));
  };
  const onMouseUp = (ev) => {
    ev.preventDefault();
    onMouseMove(ev);
    mouseState.current = null;
    setCursor('grab');
  };

  return (
    <div
      ref={container}
      style={{
        padding: 0,
        margin: 0,
        cursor: cursor
      }}
      
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}

      {...props}
    >
      <Button.Group
        style={{
          position: 'absolute',
          right: 0,
          display:'flex', 
          flexDirection: 'column',
          textAlign: 'left',
        }}
      >
        <Button 
          type="text" 
          icon={<ZoomInIcon />} 
          onClick={() => {
            setZoom(zoom*2);
          }}
        />
        <Button 
          type="text" 
          icon={<ZoomOutIcon />} 
          onClick={
          () => {
            setZoom(zoom/2);
          }}
        />
        <Button 
          type="text" 
          icon={<CenterIcon />} 
          onClick={() => {
            setZoom(1);
            setOffsetX(0);
            setOffsetY(0);
          }}
        />
      </Button.Group>
      <svg
        height="100%"
        width="100%"
        viewBox={viewBox}
      >
        {props.children}
      </svg>
    </div>
  );
};

Component.displayName = 'SVGCard';
export default Component;