import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';
import PageHeader from '../components/PageHeader'

import Table from '../components/Table';


import sys from '../system'
const { Text, Uuid, DateTime, Integer, Number } = sys.type;

import JobModel from './../models/Job.js';
import system from '../system';

const Component = () => {

  const tableRef = useRef();

  const cancelJob = (record) => {
    Modal.confirm({
      type: 'warning',
      title: 'Are you sure?',
      content: `Are you sure you want to Cancel this Job?`,
      onOk: async () => {
        const job = await JobModel.get(record.id)
        job.status = 'CANCELLED';
        await job.save()
        tableRef.current.refresh()
      },
      okText: "Yes I'm sure!",
      okType: 'danger',
    });
  }

  return (
    <div>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>      
          <Typography.Title style={{margin: 0}} level={5} >List of Jobs</Typography.Title>
        </div>
      </PageHeader>
      <Table
        ref={tableRef}
        id="query"
        title="Jobs"
        scroll={{y: 'calc(100vh - 280px)'}}
        query={{
          select: `
            select
              *
            from (
              select 
                (select name from schedule where id = job.schedule_id) schedule_name,
                (select name from process where id = job.process_id) process_name,
                case 
                  when status != 'QUEUED' then -1
                  when (extract(epoch from date_trunc('seconds', now()) - sched_start) * priority) < 0 then 0 
                  else (extract(epoch from date_trunc('seconds', now()) - sched_start) * priority)
                end as calculated_priority,
                *
              from job
            ) as job 
          `,
          limit: 20,
          orderBy: {
            calculated_priority: 'desc'
          }
        }}
        columns={[
          { 
            title: 'Schedule Name', 
            sorter: true,
            name: 'schedule_name',
            type: Text,
            mobile: false,
            width: '200px',
          },
          { 
            title: 'Created At', 
            sorter: true,
            name: 'created_at',
            type: DateTime,
          },
          { 
            title: 'Status', 
            sorter: true,
            name: 'status',
            type: Text,
            width: '140px',
            filter: ['!=SUCCESS', '!=ERROR', '!=CANCELLED', '!=TIMEOUT']
          },
          { 
            title: 'Schedule Start', 
            sorter: true,
            name: 'sched_start',
            type: DateTime,
            mobile: false,
          },
          { 
            title: 'Start', 
            sorter: true,
            name: 'start',
            type: DateTime,
          },
          { 
            title: 'Finish', 
            sorter: true,
            name: 'finish',
            type: DateTime,
          },
          { 
            title: 'Timeout', 
            sorter: true,
            name: 'timeout',
            type: DateTime,
          },
          { 
            title: 'Duration',
            width: '120px',
            align: 'center',
            render: (record) => {
              if (record.start != null) {
                const start = new Date(record.start);
                const finish = record.status === 'RUNNING' ? new Date() : new Date(record.finish);
                const diff = (finish - start) / 1000;
                return diff.toFixed(3) + ' secs';
              }
              return '--'
            }
          }, {
            title: 'Priority',
            sorter: true,
            name: 'calculated_priority',
            width: '120px',
            type: Number,
            mobile: false,
            render: (row) => {
              const priority = parseInt(row.calculated_priority)
              return priority < 0 ? null : priority;
            }
          },
          {
            title: 'Actions',
            width: '300px',
            render: (record) => {
              return (
                <div>
                  {
                    ['STARTING', 'QUEUED', 'RUNNING'].includes(record.status) && 
                    <Button type='link' danger onClick={() => cancelJob(record)}>Cancel</Button>
                  }
                </div>
              )
            }
          }
        ]}
        
      />
    </div>
  );
};
export default Component;