

import { type } from '../system';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime, Attribute } = type;

export default Table({
    name: 'Config',
    table: 'config',
    columns: {
        name: Text({
            title: 'Name',
        }),
        value: Text({
            title: 'Value'
        }),
        parseValue: Attribute({
            persistent: false,
            onInitialize: (value, row) => {
                return () => {
                  if (row.type === 'Text') {
                    return value;
                  }
                  if (row.type === 'Number') {
                    return parseFloat(value)
                  }
                  if (row.type === 'Json') {
                    value = JSON.parse(value)
                  }
                  return row.value;
                }
            }
        }),
        type: Text({
            title: 'type'
        })
    }
})