import React, { useState, useRef, useEffect, useCallback, useMemo, memo } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Tabs, Checkbox, Progress, Button, Modal, Space, Tag, Alert, Menu, Statistic, Row, Col, Badge, Card, Spin, Select, Drawer, Tooltip, Transfer    } from 'antd';
import imageUtils, { stringToColor } from '../../utils/image';

import { SaveOutlined, EditOutlined, DeleteOutlined, SyncOutlined as RefreshIcon, 
  CloudDownloadOutlined as ImportIcon, 
  CloudUploadOutlined as ExportIcon  
} from '@ant-design/icons';

import sys from '../../system'
import SourceModel from '../../models/Source'

const SourceWarnings = (props) => {
  const { source } = props

  const [stats, setStats] = useState([])
  const [refresh, setRefresh] = useState(0)


  useAsyncEffect(async () => {
    const classes = await props.dataset.getClassesWhere()
    const tags = await props.dataset.getTagsWhere()
  
    let stats = await sys.orm(`
      select 
        s.id,
        s.name source_name,
        a1.name,
        (
          select 
            count(*) 
          from 
            image i 
            join annotation a2 on (i.id = a2.image_id) 
          where 
            s.id = i.source_id 
            and a1.name = a2.name
            and i.id in (select ref_id from tag where name in (${tags}))
        ) count,
        (
          select 
            count(*) 
          from 
            image i join annotation a2 on (i.id = a2.image_id) 
          where 
            s.id = i.source_id 
            and a1.name = a2.name
            and i.id in (select ref_id from tag where name = (${tags}))
            and i.id in (select ref_id from tag where name = 'VALIDATION')
        ) validation
      from 
        source s,
        (select distinct name from annotation where name in (${classes})) a1
      where
        s.id = $1
    `, [source.id])

    //stats = stats.filter(s => s.count != '0')
    stats.forEach((s) => {
      s.count = parseInt(s.count)
      s.validation = parseInt(s.validation)
      if (s.validation == 0) {
        s.percent = 0
      } else {
        s.percent = (s.validation / s.count)
      }
    })
    stats.forEach((s) => {

      let maxE = Math.ceil(s.count * 0.28)
      let minE = Math.floor(s.count * 0.12)
      if (s.count == 0 || s.count == 1) {
        maxE = 0;
        minE = 0
      }

      let maxW = Math.ceil(s.count * 0.25)
      let minW = Math.floor(s.count * 0.15)
      if (s.count == 0 || s.count == 1) {
        maxW = 0;
        minW = 0
      }

      if (minW === maxW) {
        s.title = `Target VALIDATION should be ${minW} and currently you have ${s.validation}`  
      } else {
        s.title = `Target VALIDATION should be between ${minW} and ${maxW} and currently you have ${s.validation}`
      }


      let type = 'success'
      if (s.validation > maxE || s.validation < minE) {
        type = 'error'
      } else
      if (s.validation > maxW || s.validation < minW) {
        type = 'warning'
      }
      s.type = type
    })
    //stats = stats.filter(s => s.type != 'success')
    stats.sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    

    setStats(stats)

    

  }, [refresh]);


  return (
    <Card 
      style={{width: 320}}
      styles={{
        body: {
          padding: 10
        }
      }}
      title={source.name}
      extra={<Button type='text' icon={<RefreshIcon/>} onClick={() => {
        setRefresh(refresh + 1)
      }}/>}
    >

      {
        stats.map((s, i) => {
          return <Alert
            key={`dtw-${i}`}
            style={{margin: 5}}
            message={
              <Tooltip title={s.title}>
              <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                <div style={{flex:'auto'}}>{s.name}</div>
                <div>{s.count}/{s.validation}</div>
                <div>{parseInt(s.percent * 100)}%</div>
              </div>
              </Tooltip>
            }
            type={s.type}
          />
        })
      }

    </Card>
  )
}



const Component = ({dataset}) => {
  
  const [sources, setSources] = useState([])
  
  useAsyncEffect(async () => {
    const s = await SourceModel.select("status = 'ACTIVE' order by name")
    setSources(s)
  }, []);
  

  return (
    <div style={{display: 'flex', flexWrap: 'wrap', gap: 5}}>
      {
        sources.map((s, i) => {
          return (<SourceWarnings dataset={dataset} source={s} key={i}/>)
        })
      }
    </div>
  );
}

Component.displayName = 'ValidationSetStats';
export default Component;
