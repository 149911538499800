import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import { BaseEdge, BezierEdge, EdgeLabelRenderer, EdgeText, getBezierPath, MarkerType  } from 'reactflow';

const Component = (props) => {

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: props.sourceX,
    sourceY: props.sourceY,
    sourcePosition: props.sourcePosition,
    targetX: props.targetX,
    targetY: props.targetY,
    targetPosition: props.targetPosition,
  });

  return (
    <>
      <BaseEdge 
        className="flow-edge"
        path={edgePath}
        animated={true}
        style={{
          animation: "dashdraw 0.4s linear infinite",
          strokeDasharray: 5,
          stroke: 'black',
          strokeWidth: 5,
        }}
      >
      </BaseEdge>
      {
        false && 
        <EdgeLabelRenderer>
          <div
            style={{
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${props.sourceX}px,${props.sourceY}px)`,
                background: '#ffcc00',
                padding: 4,
                borderRadius: 2,
                fontSize: 12,
                fontWeight: 500,
                zIndex: 1000,
              }}
              className="nodrag nopan"
            >
            {props.sourceHandleId}
          </div>
          <div
            style={{
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${props.targetX}px,${props.targetY}px)`,
                background: '#ffcc00',
                padding: 4,
                borderRadius: 2,
                fontSize: 12,
                fontWeight: 500,
                zIndex: 1000,
              }}
              className="nodrag nopan"
            >
              {props.targetHandleId}
          </div>
        </EdgeLabelRenderer>
      }
    </>
  );
}

Component.displayName = 'FlowEdge';
export default Component;
