import dayjs from "dayjs"


export const getRangeSunTimes = async (lat, lng, from, to) => {
  if (to === undefined) {
    to = from;
  }

  if (from instanceof Date || from instanceof dayjs) {
    from = dayjs(from).format('YYYY-MM-DD')
  }
  if (to instanceof Date || to instanceof dayjs) {
    to = dayjs(to).format('YYYY-MM-DD')
  }

  let r = await fetch(`https://api.sunrisesunset.io/json?lat=${lat}&lng=${lng}&date_start=${from}&date_end=${to}&timezone=UTC`)
  r = await r.json()
  r = r.results

  const format = 'YYYY-MM-DD h:mm:ss A Z'
  r = r.map(e => {
    const first_light = dayjs(`${e.date} ${e.first_light} Z`, format)
    const dawn = dayjs(`${e.date} ${e.dawn} Z`, format)
    const sunrise = dayjs(`${e.date} ${e.sunrise} Z`, format)
    const sunset = dayjs(`${e.date} ${e.sunset} Z`, format)
    const dusk = dayjs(`${e.date} ${e.dusk} Z`, format)
    const last_light = dayjs(`${e.date} ${e.last_light} Z`, format)
    return ({
        first_light,
        dawn,
        sunrise,
        sunset,
        dusk,
        last_light
    })
  })
  return r
}
