
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Tag, Space, Transfer, Tabs, theme, List, Menu, Input, Dropdown, Collapse, Select, InputNumber  } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import useAsyncEffect from 'use-async-effect';
import TagModel from '../models/Tag';

import { DownOutlined } from '@ant-design/icons'
const Component = (props) => {

  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const propagateChange = (width, height) => {
    setWidth(width);
    setHeight(height);
    
    if (typeof props.onChange === 'function') {
      props.onChange(width, height)
    }
      
  }


  
  useAsyncEffect(async () => {
    setWidth(props.width);
    setHeight(props.height);
  }, [props.width, props.height])
    
  
  const resolutions = [
  {
    width: 100,
    height: 100,
    ratio: '1:1',
  },
  {
    width: 640,
    height: 480,
    ratio: '4:3',    
  },
  {
    width: 640,
    height: 360,
    ratio: '16:9',
  },
  {
    type: 'divider'
  },
  {
    width: 640,
    height: 480,
    ratio: '4:3',
    name: 'SD'
  },
  {
    width: 1280,
    height: 720,
    ratio: '16:9',
    name: 'HD',
  },
  {
    width: 1920,
    height: 1080,
    ratio: '16:9',
    name: 'FHD',
  },
  {
    width: 2560,
    height: 1440,
    ratio: '16:9',
    name: 'QHD'
  },
  {
    width: 3840,
    height: 2160 ,
    ratio: '16:9',
    name: 'UHD'
  },
  ];
  

  const menu = {
    onClick: (item) => {
      const resolution = resolutions[item.key];
      propagateChange(resolution.width, resolution.height);
    },
    items: resolutions.map((r, i) => {
      if (r.type === 'divider') {
        return r;
      }
    
      return ({
          key: i,
          label: <div style={{display:'flex', flexDirection: 'row'}}>
            <div style={{width: 50}}>{r.name}</div>
            <div style={{width: 80}}>{r.width}x{r.height}</div>
            <div>{r.ratio}</div>
          </div>,
          disabled: width == r.width && height == r.height
        })
      }
    )
  };

  return (
    <div style={{width: 200}}>
      <Space.Compact block >
        <InputNumber
          min={1}
          step={1}
          value={width}
          placeholder='Width'
          onChange={width => {
            propagateChange(width, height);
          }}
        />
        <Button style={{padding: 0, paddingLeft: 4, paddingRight: 4}} disabled={true}>x</Button>
        <InputNumber
          min={1}
          step={1}
          value={height}
          placeholder='Height'
          onChange={height => {
            propagateChange(width, height);
          }}
        />
              
        
        <Dropdown menu={menu}>
          <Button icon={<DownOutlined />}></Button>
        </Dropdown>

      </Space.Compact>


      
    </div>
    )
  };

  
Component.displayName = 'ResolutionPicker';
export default Component;