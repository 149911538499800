import React, { useRef, useState } from 'react';
import { Button, Card, Checkbox, Form, Input, Layout, Row, Col, theme, Typography } from 'antd';
const { useToken } = theme;

import accessToken from '../store';

import sys from '../system.js';
import { useStore } from './../store';
import {
  useNavigate 
} from "react-router-dom";
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { BiLogIn } from 'react-icons/bi';

function Login(props) {
  const { token } = useToken();
  const navigate = useNavigate()



  const [errorMessage, setErrorMessage] = useState();

  const onSubmit = async (values) => {
    
    const username = values.username;
    const password = values.password;
 
    const loggedIn = await sys.login(username, password);
    if (!loggedIn) {
      sys.notify({
        type: 'error',
        message: "Login Error",
        description: "Invalid Username and Password",
        placement: 'top',
      })
      setErrorMessage('Invalid Username and Password');
    } else {
      setErrorMessage();
      if (props.navigate.current.pathname === '/login') {
        props.navigate.current.pathname = '/';
        //window.location.href = "/";
        navigate('/');
      } else if (props.navigate.current.pathname !== '/') {
        const n = props.navigate.current.pathname;
        props.navigate.current.pathname = '/';
        navigate(n);
        //window.location.href = n;
      } else {
        navigate(props.navigate.current.pathname);
        //window.location.href = props.navigate.current.pathname;
      }
    }
  }

  return (<div>
      <Row
        style={{ 
          backgroundColor: token.colorBgContainer,
          height: '100vh',
        }}
      >
        <Col flex="600px" style={{display: 'flex', alignItems:'center', justifyContent:'center', visibility: 0.1}}>
          <div style={{width:'50%', minWidth:'200px'}}>
            { 
            // <img style={{width: '320px'}} src="logoWide.png"/> 
            }

            <Typography.Text style={{width: '100%', display:'flex', justifyContent: 'center', fontSize: 32, whiteSpace: 'nowrap'}}>NEUROENGINE</Typography.Text>



            <Form
                initialValues={{ remember: true }}
                onFinish={onSubmit}
                autoComplete="off"
              >

                <center style={{height: 80, fontSize: 30, fontWeight: 'bold'}}></center>
                <Form.Item name="username" 
                  rules={[{ required: true, message: 'Please input your username!' }]}
                  validateStatus={errorMessage == null ? null : "error"}
                >
                  <Input prefix={<UserOutlined/>} placeholder='Username'/>
                </Form.Item>
        
                <Form.Item name="password" 
                  rules={[{ required: true, message: 'Please input your password!' }]}
                  validateStatus={errorMessage == null ? null : "error"}
                >
                    <Input.Password prefix={<LockOutlined/>} placeholder='Password'/>
                </Form.Item>
        
        
                <Form.Item
                  validateStatus={errorMessage == null ? null : "error"}
                  help={errorMessage}
                >
                  <Button type="primary" htmlType="submit" block style={{display:'flex', justifyContent: 'center'}}>
                      <div>Enter</div>
                      <BiLogIn size={18} style={{marginLeft: 5, marginTop: 2}}/>
                  </Button>
                </Form.Item>
                <Form.Item name="remember" valuePropName="checked" style={{display: 'flex', justifyContent: 'end'}}>
                    <Checkbox style={{color: 'lightgray'}}>Remember me</Checkbox>
                </Form.Item>

                <center style={{height: 120, fontSize: 30, fontWeight: 'bold'}}></center>

            </Form>
        
            <div 
              style={{
                position: 'absolute', 
                bottom: 10, 
                color: token.colorText 
              }}
              >
              NeuroEngine® 2023 - Build 20231103
            </div>
          </div>
        </Col>
        <Col flex="auto" style={{
          backgroundImage: 'url(background.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          boxShadow: '-3px 0px 5px rgb(50 50 50 / 50%)'
        }}/>
      </Row>
    </div>
  );
}

export default Login;