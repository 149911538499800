import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Tag, Space, Transfer, Tabs, List, Menu, Input, Collapse  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { EyeOutlined } from '@ant-design/icons'
import ReactImageAnnotate from "react-image-annotate";

import ImageModel from './../../models/Image';
import AnnotationModel  from './../../models/Annotation'
import imageUtils from './../../utils/image';
import sys from './../../system'
const { Transaction } = sys.type;

const Component = forwardRef((props, ref) => {

  const image = useRef()
  const [refresh, setRefresh] = useState();
  const [classes, setClasses] = useState(props.classes);

  
  const onRefresh = async () => {
    if (props.classes == null) {
      let classResult = await sys.orm('select distinct(name) from annotation where model_id is null order by name');
      setClasses(classResult.map(c => c.name));
    }
    setRefresh(sys.uuid());
  }

  useImperativeHandle(ref, () => ({
    getAnnotations: () => {
      return imageUtils.fromReactImageAnnotations(image.current.reactImageAnnotateRegions);
    },
    save: async () => {
      const oldAnnotations = await image.current.annotations;
      const newAnnotations = imageUtils.fromReactImageAnnotations(image.current.reactImageAnnotateRegions);
      const trx = new Transaction();
      trx.add(image.current);
      image.current.updated_at = new Date();
      oldAnnotations.forEach(a => {
          a.delete = true
          trx.add(a);
      });
      for (const annotation of newAnnotations) {
        const exists = oldAnnotations.find(a => a.id == annotation.id);
        if (exists != null) {
          exists.name = annotation.name;
          exists.points = annotation.points;
          exists.locked = annotation.locked;
          exists.visible = annotation.visible;
          exists.delete = false;
        } else {
          const a = AnnotationModel.create({
            id: annotation.id,
            image_id: image.current.id,
            type: annotation.type,
            name: annotation.name,
            locked: annotation.locked,
            visible: annotation.visible,
            points: annotation.points
          });
          trx.add(a);
          oldAnnotations.push(a)
        }
      }
      await trx.save();
    }
  }));


  useAsyncEffect(async () => {
    
    if (props.image != null) {
      image.current = props.image;
      image.current.url = sys.image.url(image.current.src);
      await sys.image.preload(image.current.url);
      image.current.reactImageAnnotateRegions = imageUtils.toReactImageAnnotations(await image.current.annotations);
      onRefresh()
    }
  
  }, [props.image]);


  console.log(refresh, image.current, props)
  return (
    <div 
      key={refresh}
      id={refresh}
      className='annotator'
      style={{ height: '100%'}}
    >
      {
        image.current &&
        <ReactImageAnnotate
          annotationType='image'
          regionClsList={classes}
          regionTagList={[]}
          showTags={true}
          maxRegions={props.maxRegions}
          selectedImage={image.current.url}
          selectedTool='select'
          enabledTools={props.enabledTools}
          hideClone={true}
          hidePrevous={true}
          onChange={(state) => {
            if (state && state.images[0] && state.images[0].regions) {
              image.current.reactImageAnnotateRegions = state.images[0].regions
            }
            if (typeof props.onChange === 'function') {
              props.onChange(state);
            }
          }}
          images={[{
            key: image.current.id,
            src: image.current.url,
            regions: image.current.reactImageAnnotateRegions,
          }]}
        />
      }
    </div>
    );
});

Component.displayName = 'Annotator';
export default Component;