import sys, { type } from '../system';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = type;





export default Table({
    name: 'ProcessCommit',
    table: 'process_commit',
    columns: {
        process_id: Uuid(),
        message: Text(),
        created_at: DateTime({
            defaultsTo: () => { return sys.now() }
        }),
        created_by: Uuid({
            defaultsTo: () => sys.getUser()?.id
        }),
        state: Json(),
    }
})