import { type } from '../system';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = type;


import User from './User.js';
import User_Token from './User_Token.js';

export default Table({
    name: 'Person',
    table: 'person',
    columns: {
        title: Text({
            title: "Title",
        }),
        first_name: Text({
            title: "First Name",
        }),
        last_name: Text({
            title: "Last Name",
        }),
        display_name: Text({
            title: "Display Name",
        }),
        email: Text({
            title: "Email",
        }),
        initials: Upper({
            onInitialize: (value, row) => {
                // field initials
                if (value != null) { 
                    return value;
                }

                // field first_name[0] + last_name[0]
                let initials = ''
                if (row.first_name != null) {
                    initials += row.first_name.at(0);
                }
                if (row.last_name != null) {
                    initials += row.last_name.at(0);
                }
                if (initials != '') {
                    return initials;
                }

                // field display_name[n]
                if (initials === '' && row.display_name != null) {
                    initials = row.display_name.split(' ').map(i => i.at(0)).join();
                }
                if (initials != '') {
                    return initials;
                }
                if (initials === '' && row.full_name != null) {
                    initials = row.full_name.split(' ').map(i => i.at(0)).join();
                }
                return initials;
            }
        }),
        status: type.Text({
            defaultsTo: 'ACTIVE',
            values: ['ACTIVE', 'INACTIVE']
        }),
        webpage: Text(),
        phone: Text(),
        birth_date: Date(),
        photo: Text(),
        date_format: Text(),
        time_format: Text(),
        date_time_separator: Text({
            trim: false
        }),
        first_day_of_week: Text(),
        firt_hour_of_day: Number(),
        decimal_symbol: Text({
            trim: false,
        }),
        grouping_symbol: Text({
            trim: false,
        }),
        timezone: Text(),
        users: Relation({
            to: 'User',
            cardinality: '*',
            where: `person_id = $id`,
        }),
        groups: Relation({
            to: 'Group',
            cardinality: '*',
            where: `id in (select group_id from person_group where person_id = $id)`
        }),
        tokens: Relation({
            to: 'UserToken',
            cardinality: '*',
            where: `1 = 1`
        }),
    }
})