import React, { memo, useState } from 'react';
import { Position } from 'reactflow';
import Handle from './../../../components/ProcessEditor/Handle'

import { Select, Space, Row, Col, Tabs, Form, Card, Button, InputNumber, Checkbox } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { Popover, Tooltip  } from 'antd';

import {
  EyeOutlined, EyeFilled, EyeTwoTone 
} from '@ant-design/icons';

import ModelModel from '../../../models/Model'
import NodeCard from '../../../components/ProcessEditor/NodeCard'


const occlusionMethods = [
  { value: 'contains', label: 'Contains'}, 
  { value: 'intersects', label: 'Intersects' },
  { value: 'covers', label: 'Covers' },
  { value: 'crosses', label: 'Crosses' },
  { value: 'disjoint', label: 'Disjoint' },
  { value: 'overlaps', label: 'Overlaps' },
  { value: 'touches', label: 'Touches' },
  { value: 'within', label: 'Within' },
]

const Component = props => {

  if (props.data.method == null) {
    props.data.method = 'intersects';
  }
  if (props.data.centroid == null) {
    props.data.centroid = false;
  }
  if (props.data.confidence == null) {
    props.data.confidence = 0.5;
  }
  if (props.data.occlusionMethod == null) {
    props.data.occlusionMethod = null;
  }
  if (props.data.occlusionIgnore == null) {
    props.data.occlusionIgnore = null;
  }
  if (props.data.occlusionBy == null) {
    props.data.occlusionBy = null;
  }
  if (props.data.batch == null) {
    props.data.batch = false;
  }


  const [models, setModels] = useState([]);
  const [model, setModel] = useState();
  const [modelId, setModelId] = useState(props.data.modelId);
  const [className, setClassName] = useState(props.data.className);
  const [confidence, setConfidence] = useState(props.data.confidence);
  const [method, setMethod] = useState(props.data.method);
  const [centroid, setCentroid] = useState(props.data.centroid);
  const [batch, setBatch] = useState(props.data.batch);

  const [occlusionMethod, setOcclusionMethod] = useState(props.data.occlusionMethod);
  const [occlusionIgnore, setOcclusionIgnore] = useState(props.data.occlusionIgnore);
  const [occlusionBy, setOcclusionBy] = useState(props.data.occlusionBy);


  useAsyncEffect(async () => {
    const models = await ModelModel.select("status = 'ACTIVE'");
    setModels(models);
    const model = models.find(m => m.id === modelId);
    setModel(model);
  }, []);
  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='1'
          name='activation'
          style={{ top: '40%' }}
          title="Activate"
          accepts={['any']}
          activation
        />,
        <Handle
          key='2'
          name='polyset'
          title="[Polyset] a optional region of the image to detect objects in"
          style={{ top: '66%', }}
          accepts={['polyset', 'poly']}
          maxConnections={1}
        />,
        <Handle
          key='3'
          name='image'
          style={{ top: '85%', }}
          title="[Image] in which to detect objects"
          accepts={['image']}
          maxConnections={1}
        />,
        <Handle
          key='4'
          name='found'
          style={{ top: '40%' }}
          title='Produces a [Polyset] with the detected objects'
          produces={['polyset']}
          activation
        />,
        <Handle
          key='5'
          name='not-found'
          style={{ top: '73%', backgroundColor: 'red' }}
          title='Produces [Activation] when no detections'
          produces={['any']}
          activation
        />
      ]}
    >
      <Tabs
        centered
        items={[{
          key: '1',
          label: 'Detection',
          children: 
            <Form
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              style={{width: 300}}
            >
              <Form.Item label="Model" required>
                <Select
                  allowClear={true}
                  placeholder="Please select a Model"
                  value={modelId}
                  onSelect={modelId => {
                    props.data.modelId = modelId;
                    const model = models.find(m => m.id === modelId);
                    setModel(model);
                    setModelId(props.data.modelId);
                    props.onChange();
                  }}
                  onClear={() => {
                    props.data.modelId = null;
                    setClassName(data.className);
                    setModel(null);
                    setModelId(props.data.modelId);
                    props.onChange();
                  }}
                  options={models?.map(e => ({value: e.id, label: e.name}))}
                />
              </Form.Item>
              <Form.Item label="Object" required>
                <Select
                  allowClear={true}
                  placeholder="Please select a Object"
                  value={className}
                  mode="multiple"
                  onChange={className => {
                    props.data.className = className;
                    setClassName(props.data.className);
                    props.onChange();
                  }}
                  onClear={() => {
                    props.data.className = null;
                    setClassName(props.data.className);
                    props.onChange();
                  }}
                  options={model?.config?.classNames?.map(e => ({value: e, label: e}))}
                />
              </Form.Item>
              <Form.Item label="Confidence" labelCol={{ span: 12, offset: 0 }} required>
                <InputNumber
                  style={{width: 80}}
                  value={confidence}
                  onChange={value => {
                    props.data.confidence = value;
                    setConfidence(props.data.confidence);
                    props.onChange();
                  }}
                  min={0}
                  max={1}
                  step={0.05}
                  formatter={(value) => `${(value * 100).toFixed(0)} %`}
                  parser={(value) => parseFloat(value.replace('%', '') / 100)}
                />
                </Form.Item>
              <Form.Item label="Batch" >
                <Checkbox
                  value={batch}
                  onChange={value => {
                    props.data.batch = value;
                    setBatch(props.data.batch);
                    props.onChange();
                  }}
                />
              </Form.Item>
            </Form>
        }, {
          key: '2',
          label: 'Region',
          children: 
          <Form style={{width: 300}}>
            <Form.Item label="Region Method" labelCol={{ span: 12 }} required>
              <Select
                style={{width: 128}} 
                value={method}
                onChange={value => {
                  props.data.method = value;
                  setMethod(props.data.method);
                  props.onChange();
                }}
                options={occlusionMethods}
              />
            </Form.Item>
            <Form.Item label="Use Detection Centroid" labelCol={{ span: 16 }} required>
              <Checkbox
                style={{width: 128}} 
                value={centroid}
                onChange={value => {
                  props.data.centroid = value;
                  setCentroid(props.data.centroid);
                  props.onChange();
                }}
                options={occlusionMethods}
              />
            </Form.Item>
          </Form>
        }, {
          key: '3',
          label: 'Occlusion',
          children: 
          <Form
            style={{width: 300}}
          >
            <Form.Item label="Method" labelCol={{ span: 8 }}>
              <Select
                style={{width: 128}} 
                value={occlusionMethod}
                onChange={value => {
                  props.data.occlusionMethod = value;
                  setOcclusionMethod(props.data.occlusionMethod);
                  props.onChange();
                }}
                options={[ 
                  { value: null, label: 'Inactive' }, 
                  ...occlusionMethods
                ]}
              />
            </Form.Item>
            <Form.Item label="By" labelCol={{ span: 4 }} >
              <Select
                disabled={occlusionMethod == null}
                allowClear={true}
                placeholder="Please select a Object"
                value={occlusionBy}
                mode="multiple"
                onChange={className => {
                  props.data.occlusionBy = className;
                  setOcclusionBy(props.data.occlusionBy);
                  props.data.occlusionIgnore = null;
                  setOcclusionIgnore(props.data.occlusionIgnore);
                  props.onChange();
                }}
                onClear={() => {
                  props.data.occlusionBy = null;
                  setOcclusionBy(props.data.occlusionBy);
                  props.onChange();
                }}
                options={model?.config?.classNames?.map(e => ({value: e, label: e}))}
              />
            </Form.Item>
            <Form.Item label="Ignore" labelCol={{ span: 4 }} >
              <Select
                disabled={occlusionMethod == null}
                allowClear={true}
                placeholder="Please select a Object"
                value={occlusionIgnore}
                mode="multiple"
                onChange={className => {
                  props.data.occlusionIgnore = className;
                  setOcclusionIgnore(props.data.occlusionIgnore);
                  props.data.occlusionBy = null;
                  setOcclusionBy(props.data.occlusionBy);
                  props.onChange();
                }}
                onClear={() => {
                  props.data.occlusionIgnore = null;
                  setOcclusionIgnore(props.data.occlusionIgnore);
                  props.onChange();
                }}
                options={model?.config?.classNames?.map(e => ({value: e, label: e}))}
              />
            </Form.Item>
          </Form>
        }      
      ]}/>
    </NodeCard>
  );
};

Component.displayName = 'DetectObjectNode';
export default Component;

