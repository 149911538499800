import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, PureComponent } from 'react';
import { BarChart, Treemap, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card } from 'antd';
import { useNavigate, useParams } from "react-router-dom";

import useAsyncEffect from 'use-async-effect';
import sys from '../../system'


import imageUtils, { stringToColor } from '../../utils/image';

class CustomizedContent extends PureComponent {
  
  render() {
    const { root, depth, x, y, width, height, index, payload, rank, name, count, dataset, navigate } = this.props;
    
    let g;
    if (depth === 1) {
      g = <g>
            <rect
              x={x}
              y={y}
              width={width}
              height={height}
              style={{
                fill: imageUtils.stringToColor(name.split("").reverse().join("")),
                stroke: '#fff',
                strokeWidth: 10 / (depth + 1e-10),
                strokeOpacity: 1 / (depth + 1e-10),
              }}
            />
              <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#000" fontSize={14}>
                {name}
              </text>
          </g>
    } else
    if (depth > 1) {
      g = <g>
            <rect
              onClick={() => navigate(`/dataset/${dataset.id}/${name}/view`)}
              x={x+10}
              y={y+10}
              width={width-20}
              height={height-20}
              style={{
                fill: imageUtils.stringToColor(name),
                opacity: 0.4,
                stroke: '#fff',
                strokeWidth: 4 / (depth + 1e-10),
                strokeOpacity: 1,
              }}
            >
              <title>{name}: {count}</title>
            </rect>
          </g>
    }

    return g;
  }
}

const Component = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState();

    
  useAsyncEffect(async () => {
  
    const dataset = props.dataset;
    if (dataset == null) {
      return;
    }
    let sourceClassCount = await dataset.getSourceClassCount()

    let data = {}
    sourceClassCount.forEach(r => {
      data[r.source_name] = data[r.source_name] || { name: r.source_name, children: [] }
      data[r.source_name].children.push({ name: r.class_name, count: r.count})
    })
    data = Object.values(data)

    setData(data)
  }, [props.dataset])



  return (
        <ResponsiveContainer >
          <Treemap
            data={data}
            dataKey="count"
            stroke="#fff"
            fill="#8884d8"
            aspectRatio={5 / 4}
            content={<CustomizedContent dataset={props.dataset} navigate={navigate}/>}
          >
           </Treemap>
        </ResponsiveContainer>
  )
};

export default Component;