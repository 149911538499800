import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Flex, Tag, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';


import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate, 
  useParams
} from "react-router-dom";
import { useStore } from '../../store';
import PageHeader from '../../components/PageHeader'
import BackButton from '../../components/AppHeader/BackButton'

import Table from '../../components/Table';

import DatasetModel from '../../models/Dataset'
import sys from '../../system'
import imageUtils from '../../utils/image';

import AnnotationViewer from './../../components/AnnotationViewer'


const Component = () => {
  
  const params = useParams();
  const [dataset, setDataset] = useState()
  const [annotations, setAnnotations] = useState()

  

  useAsyncEffect(async () => {
    const datasetId = params.datasetId;
    if (datasetId == null) {
      return;
    }
    const dataset = await DatasetModel.get(datasetId);
    setDataset(dataset);
    
    let annotations = await dataset.getAnnotations();
    annotations = annotations.filter(a => a.name === params.className);
    setAnnotations(annotations)
    
  }, [])

  return (
    <div style={{
      width: '100%',
      height: 'calc(100vh - 110px)'
    }}>
      <PageHeader>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', gap: 20}}>
          <BackButton/>
          <Typography.Title style={{margin: 0}} level={5} >{dataset?.name}</Typography.Title>
          <Tag color={imageUtils.stringToColor(params.className)}>{params.className}</Tag>
        </div>
      </PageHeader>

      <div style={{display: 'flex', flexDirection: 'row', gap: 16, flexWrap: 'wrap'}}>
      { 
        annotations?.map(a => {
          return (
            <div key={a.id} style={{width: 240, height: 240}}>
              <AnnotationViewer id={a.id}></AnnotationViewer>
            </div>
          )
        })
      }
      </div>
    </div>
  );
};
export default Component;