import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, Button, Modal, Tag, theme, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";
import { useStore } from '../store';

import Table from '../components/Table';

import ImageClass from './../components/ImageAnnotator/ImageClass';
import ImageTags from './../components/ImageTags';
import sys from '../system'

import ImageModel from './../models/Image'

import AppHeader from '../components/AppHeader'
import BackButton from '../components/AppHeader/BackButton'
import Uuid from '../components/Uuid'

import PageHeader from '../components/PageHeader'
const { DateTime, Text, Json } = sys.type; 

const { useToken } = theme;


import ImageViewer from '../components/ImageViewer'

const Component = (props) => {
  const params = useParams();

  const [image, setImage] = useState();

  
  useAsyncEffect(async () => {
    const image = await ImageModel.get(params.imageId);
    setImage(image);
  }, []);

  let title = '';
  if (image != null) {
    title += `Image created at ${sys.format.datetime(image?.created_at)}`

  }

  return (
    <div style={{width: '100%', height: '100%'}}>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>
          <BackButton/>
          
          <Typography.Title style={{margin: 0}} level={5}>Image <Uuid value={image?.id}/> created at {sys.format.datetime(image?.created_at)}</Typography.Title>
        </div>
      </PageHeader>
      <ImageViewer image={image}/>
    </div>
  );
};
export default Component;