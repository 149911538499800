
import  { React, useState, useEffect } from 'react';
import { Button, notification } from 'antd';
import { useStore, usePresistentStore } from './store';
import dayjs from 'dayjs';
import moment from 'moment';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

import { useImperativeHandle } from 'react';
import _, { zip } from 'lodash';
import * as math from 'mathjs'
import { uuidv7 } from "@kripod/uuidv7";

const host = '/api/';
const uploads = '/uploads/';


window.timers = {};
function startTimer(timerName, f, delay, activations) {
  stopTimer(timerName);

  if (activations === 0) {
    //console.log(`Timer ${timerName} not started because activations are zero.`)
    return;
  }
  if (delay == null) {
    //console.log(`Timer ${timerName} not started because delay is null or zero.`)
    return;
  }
  if (typeof f !== 'function') {
    //console.log(`Timer ${timerName} not started because f is not a function`)
    return;
  }
  
  const callback = () => {
    const timer = window.timers[timerName];
    if (timer == null) {
      return;
    }
    try {
      f();
      timer.success++;
    } catch (e) {
      timer.errors++;
      console.error(e);
    }
    if (timer.remaining != null) {
      timer.remaining--;
      if (timer.remaining === 0) {
        stopTimer(timerName);
        return;
      }
    }
    timer.timeoutId = setTimeout(callback, timer.delay);
  }
  
  window.timers[timerName] = {
    activations,
    remaining: activations,
    error: 0,
    success: 0,
    delay,
    timeoutId: setTimeout(callback, delay),
  };
  //console.log(`Timer ${timerName} started`);
}

function stopTimer(timerName) {
  const timer = window.timers[timerName];
  if (timer != null) {
    //console.log(`Timer ${timerName} stopped`)
    clearTimeout(timer.timeoutId);
    delete window.timers[timerName];
  }
}


export const equals = (a, b) => {
  if (!_.isEqualWith(a, b)) {
    return false;
  }
  return true;
};
export const clone = (o) => {
  return _.cloneDeep(o)
};


export const notify = (e) => {
  if (e instanceof Error) {
    e = {
      message: e.message,
      placement: 'top',
      type: 'error',
    }
  } else
  if (typeof e === 'string') {
    e = {
      message: e,
      placement: 'top'
    }
  } else if (typeof e === 'object') {
    e = {
      ...e,
      type: e.type || 'info',
      placement: e.placement || 'top',
    }
  }
  if (e.key !== undefined) {
    e.key = `notify ${_.uniqueId()}`;
  }
  if (e.report === true) {
    e = {
      ...e,
      btn: (
        <Button 
          type="link" 
          size="small" 
          onClick={async () => { 
            notification.destroy(e.key);
            sendEmail('error-report', { error: e });
          }}>
          Report
        </Button>
      )
    }
  }
  notification.open(e);
  return e.key;
}



export async function onLogin() {
  /*
  const ormData = await apiFetch('orm', {});
  const head = document.querySelector("head");
  const script = document.createElement("script");
  script.id = 'orm';
  script.innerHTML = 'console.log("teste script")';
  head.appendChild(script);
  */

  timers.start('refreshAccessToken', async () => {
    const accessToken = useStore.getState().accessToken;
    if (accessToken == null) {
      return;
    }
    let decodedJwt;
    try {
      decodedJwt = parseJwt(accessToken);
    } catch (e) {
      console.error(e);
    }
    if (decodedJwt != null) {
      const now = new Date().getTime() / 1000;
      const secondsRemaining = decodedJwt.exp - now;
      if (secondsRemaining < 100) {
        console.log('Refreshing Access Token')
        await refreshAccessToken();
      } else {
        console.log('Access Token expiring in', parseInt(secondsRemaining), 'seconds');
      }
    } else {
      useStore.getState().setAccessToken(null);
    }
  }, 60 * 1000);
}

export async function onLogout() {
  timers.stop('refreshAccessToken');
}


export const combineDateTime = (date, time) => {
  if (time == null) {
      return date;
  }
  if (date == null) {
      return null;
  }
  const dateTime = date.clone();
  dateTime.set({
      hour: time.hours(),
      minute: time.minutes(),
      second: time.seconds(),
      millisecond: time.milliseconds(),
  });
  return dateTime;
}

export const extractDate = (dateTime) => {
  if (dateTime == null) {
    return null;
  }
  const dt = dateTime.clone();
  dt.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
  });
  return dt;  
}

export const refreshUserFromToken = async () => {
  const accessToken = useStore.getState().accessToken;
  if (accessToken == null) {
    useStore.setState({ user: null })
    return;
  }
  const decodedJwt = parseJwt(accessToken);
  const username = decodedJwt.username;
  const user = await model.User.selectFirst(`username = $username`, { username });
  let person;
  if (user != null) {
    person = await user.person;
    person = person[0];
  }
  useStore.setState({ user, person });
}


export async function login(username, password) {
  const response = await fetch(`/api/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password
    })
  });
  if (response.ok) {
    const json = await response.json();
    const accessToken = json.accessToken;
    useStore.getState().setAccessToken(accessToken);
    await refreshUserFromToken();
    return true;
  }
  useStore.getState().setAccessToken(null);
  return false;
}


export async function logout() {
  await api(`logout`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    }
  });
  useStore.getState().setAccessToken(null);
  return true;
}



export function parseJwt(token) {
  try {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
}

export async function refreshAccessToken() {
  const response = await fetch(`/api/token`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });
  if (response.ok) {
    const json = await response.json();
    const accessToken = json.accessToken;
    useStore.getState().setAccessToken(accessToken)
    return true;
  } else {
    useStore.getState().setAccessToken(null);
    return false;
  }
}

export async function api(url, options = { method: 'GET'}) {
  url = url.replace(/\/+$/ig, '');
  options = {
    ...options,
  };
  const accessToken = useStore.getState().accessToken;
  if (accessToken != null) {
    if (options.headers == null) {
      options.headers = {};
    }
    options.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  if (options.body !== undefined && typeof options.body === 'object') {
    options.headers['Content-Type'] = 'application/json';
    options.headers['Accept'] = 'application/json';
    options.body = JSON.stringify(options.body);
  }
  const response = await fetch(`/api/` + url, options);
  return response;
};

export async function upload(file) {
  console.log('file', file)

  const accessToken = useStore.getState().accessToken;

  const data = new FormData()
  data.append('file', file)

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data; boundary=7ecdc4efed3681141cad676aaad67dcb'
    },
    body: {
      data
    }
  };
  
  const r = await fetch('/api/upload', options)

  console.log('response', r);
};

const showException = (exception) => {
  if (exception.exception != undefined) {
    exception = exception.exception;
  }
  if (exception.includes('violates foreign key constraint')){
    notify({
      type: 'error',
      message: "Reference Error",
      description: "You cannot change fields referenced in other records",
      placement: 'top',
    });
    throw new Error("You cannot change fields referenced in other records");
  }
  if (exception.includes('customer_first_name_last_name_unique')) {
    notify({
      type: 'error',
      message: "Duplicated error",
      description: "There's already a Customer with the same First and Last Name",
      placement: 'top',
    });
    throw new Error("There's already a Customer with the same First and Last Name");
  }
  if (exception.includes('vehicle_license_unique')) {
    notify({
      type: 'error',
      message: "Duplicated error",
      description: "There's already a Vehicle with the same license plate",
      placement: 'top',
    });
    throw new Error("There's already a Vehicle with the same license plate");
  }
  if (isEmpty(exception)) {
    exception = 'An unknown error has occurred';
  }
  notify({
    type: 'error',
    message: "Error",
    description: exception,
    placement: 'top',
    report: true
  });
  throw new Error(exception);
}

/**
 * (select)
 * (select, values)
 * ({ select, values })
 */
export const orm = async (body, values) => {
  if (typeof body === 'string') {
    body = {
      select: body
    }
    if (values != null) {
      body.values = values;
    }
  }

  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body)
  };
  const response = await api(`orm`, options);
  if (response.ok) {
    const json = await response.json();
    const rows = json.rows;
    if (body.first === true) {
      if (rows.length === 0) {
        return undefined
      } else {
        return Object.values(rows[0])[0];
      }
    }
    return rows;
  }
  const e = await response.text();
  throw type.Error(e);
};

/*
export async function pg(jsonQuery) {
  if (typeof jsonQuery === 'string') {
    jsonQuery = {
      sql: jsonQuery
    }
  }
  let response;
  try {
    response = await apiFetch('pg', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonQuery)
    });
  } catch (e) {
    return showException(e);
  }
  if (response.ok) {
    const text = await response.text();
    if (text === '') {
      return null;
    }
    return JSON.parse(text);
  } else {
    const e = await response.text();
    return showException(e);
  }
};
*/


export function download(fileName, blob) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}


export function isEmpty(obj) {
  if (obj == null) {
    return true;
  }
  if (Array.isArray(obj) && obj.length === 0) {
    return true;
  }
  if (typeof obj === 'string') {
    if (obj.trim() === '') {
      return true
    }
  }
  return false;
}


export function toStringWhere(str) {
  if (str == null) {
    return undefined;
  }
  if (typeof str !== 'string') {
    str = str.toString();
  }
  str = str.trim();
  if (str === '') {
    return undefined;
  }
  if (str[0] === '=') {
    return str.substring(1);
  }
  if (str.includes('%')) {
    return str;
  }
  return `%${str}%`;
}

export function toNumberWhere(str) {
  if (str == null) {
    return undefined;
  }
  if (typeof str !== 'string') {
    str = str.toString();
  }
  str = str.trim();
  if (str === '') {
    return undefined;
  }
  return parseFloat(str);
}


export function toDateTimeWhere(str) {


  const bestDateMatch = (text) => {
    const datesRegexp = [
      /^(?<year>\d{4})(-(?<month>\d{1,2}))?(-(?<day>\d{1,2}))?(\s+(?<hours>\d{1,2}))?(:(?<minutes>\d{1,2}))?(:(?<seconds>\d{1,2}))?(\.(?<milliseconds>\d{1,3}))?/,   // yyyy-MM-dd HH:mm:ss.SSS
      /^(?<day>\d{1,2})(-(?<month>\d{1,2}))?(-(?<year>\d{4}))?(\s+(?<hours>\d{1,2}))?(:(?<minutes>\d{1,2}))?(:(?<seconds>\d{1,2}))?(\.(?<milliseconds>\d{1,3}))?/,   // dd-MM-yyyy HH:mm:ss.SSS
      /^(?<year>\d{4})(\/(?<month>\d{1,2}))?(\/(?<day>\d{1,2}))?(\s+(?<hours>\d{1,2}))?(:(?<minutes>\d{1,2}))?(:(?<seconds>\d{1,2}))?(\.(?<milliseconds>\d{1,3}))?/, // yyyy/MM/dd HH:mm:ss.SSS
      /^(?<day>\d{1,2})(\/(?<month>\d{1,2}))?(\/(?<year>\d{4}))?(\s+(?<hours>\d{1,2}))?(:(?<minutes>\d{1,2}))?(:(?<seconds>\d{1,2}))?(\.(?<milliseconds>\d{1,3}))?/, // dd-MM-yyyy HH:mm:ss.SSS
    ];
    let match = null;
    let groups = 0;
    for (const regexp of datesRegexp) {
      const m = text.match(regexp);
      if (m == null || m.groups == null){
        continue;
      }
      const g = m.filter(e => e != null).length;
      if (match == null || g > groups) {
        match = m;
        groups = g;
      }
    }
    return match;
  }
  const parseDate = (text) => {
    const match = bestDateMatch(text);
    if (match == null || match.groups == null){
      return undefined;
    }
    let { year, month, day, hours, minutes, seconds, milliseconds} = match.groups;
    month = month-1;
    const start = new Date(year,month || 0,day || 0,hours || 0,minutes || 0,seconds || 0,milliseconds || 0);
    if (isNaN(start.getTime())){
      return undefined;
    }
    return start;
  }
  const calcDateInterval = (text) => {
    const match = bestDateMatch(text);
    if (match == null || match.groups == null){
      return { start: undefined, end: undefined };
    }
    let { year, month, day, hours, minutes, seconds, milliseconds} = match.groups;
    month = month-1;
    const start = new Date(year,month || 0,day || 0,hours || 0,minutes || 0,seconds || 0,milliseconds || 0);
    const end = new Date(year,month || 11,day || 1,hours || 23,minutes || 59,seconds || 59,milliseconds || 999);
    if (day === undefined){
      end.setMonth(end.getMonth()+1);
      end.setDate(end.getDate()-1);
    }
    return ({start, end});
  }




}

export function toFileName(fileName, def = `file${Date.now()}`) {
  if (fileName == null) {
    return def;
  }

  fileName = fileName.trim();
  fileName = fileName.replace(/\.+$/, '');

  if ([
    'CON', 'PRN', 'AUX', 'NUL', 'COM1', 'COM2', 'COM3', 'COM4', 
    'COM5', 'COM6', 'COM7', 'COM8', 'COM9', 'LPT1', 'LPT2', 
    'LPT3', 'LPT4', 'LPT5', 'LPT6', 'LPT7', 'LPT8', 'LPT9'
    ].includes(fileName)) {
    return def;
  }

  let newFileName = "";
  for(let i = 0; i < fileName.length; i++) {
    const char = fileName.charAt(i);
    const charCode = fileName.charCodeAt(i);
    if (charCode <= 31) {
      continue;
    }
    switch (char) {
      case '<':
      case '>':
      case ':':
      case '"':
      case '/':
      case '\\':
      case '|':
      case '?':
      case '*': continue;
    }
    newFileName += char;
  }
  return newFileName.length === 0 ? def : newFileName;
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const db = {
  pg: async (jsonQuery) => {
    let response;
    try {
      response = await api(`/pg`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonQuery)
      });
    } catch (e) {
      return showException(e);
    }
    if (response.ok) {
      const text = await response.text();
      if (text === '') {
        return null;
      }
      return JSON.parse(text);
    } else {
      const e = await response.text();
      return showException(e);
    }
  },
  first: (args) => {
    return db.query({
      ...args,
      first: true,
      count: false,
    })
  },
  datetime: (datetime) => {
    if (datetime == null) {
      return datetime;
    }
    if (datetime instanceof Date) {
      datetime = datetime.toISOString();
    }
    if (dayjs.isDayjs(datetime)) {
      datetime = datetime.toISOString();
    }
    return datetime;
  },
  date: (str) => {
    // todo
    return str;
  },
  time: (str) => {
    // todo
    return str;
  },
  id: (id) => {
    if (id == null) {
      return id;
    }
    id = parseInt(id);
    return id;
  },
  integer: (int) => {
    if (int == null) {
      return int;
    }
    int = parseInt(int);
    return int;
  },
  float: (float) => {
    if (float == null) {
      return float;
    }
    float = parseFloat(float);
    return float;
  },
  boolean: (bool) => {
    if (bool == null) {
      return bool;
    }
    if (typeof bool === 'boolean') {
      return bool;
    }
    if (bool === 1) {
      return true;
    }
    if (typeof bool === 'string') {
      bool = db.text(bool);
      if (bool === '1'
        || bool === 'true' || bool === 't' 
        || bool === 'yes' || bool === 'y'
      ) {
        return true;
      }
    }
    return false;
  },
  lower: (str) => {
    str = db.text(str);
    if (str == null) {
      return str;
    }
    return str.toLowerCase();
  },
  upper: (str) => {
    str = db.text(str);
    if (str == null) {
      return str;
    }
    return str.toUpperCase();
  },
  raw: (str) => {
    return str;
  },
  text: (str) => {
    if (str === undefined) {
      return undefined;
    }
    if (str === null) {
      return null;
    }
    if (typeof str !== 'string') {
      str = str.toString();
    }
    str = str.trim();
    if (str === '') {
      return null;
    }
    return str;
  },
  json: (str) => {
    if (str == null) {
      return str;
    }
    if (typeof str === 'object') {
      return JSON.stringify(str);
    }
    return str;
  },  
}



export const capitalize = (v) => { return _.capitalize(v) };

export const text = {
  compact: (str) => {
    if (str == null) {
      return null;
    }
    if (typeof str !== 'string') {
      str = str.toString();
    }
    str = str.trim();
    if (str === '') {
      return null;
    }
    return str;
  },
  simplifly: (str) => {
    if (str == null) {
      return str;
    }
    if (typeof str === 'string') {
      str = str.toLocaleLowerCase();
      str = str.replace(/\s/g, '');
    }
    return str;
  },
  isText: (value) => {
    return typeof value === 'string';
  }
}

export const sendEmail = async (templateName, context) => {
  templateName = db.upper(templateName);
  if (templateName == null) {
    notify({
      type: 'error',
      message: "Email",
      description: `E-mail template is not defined`,
      placement: 'top',
    })
    return false;
  }
  const emailTemplate = await pg({
    count: false,
    first: true,
    select: 'select * from email_template',
    where: {
      name: templateName
    }
  });
  if (emailTemplate == null) {
    notify({
      type: 'error',
      message: "Email",
      description: `E-mail template with name ${templateName} is not exists`,
      placement: 'top',
    })
    return false;
  }
  if (emailTemplate.status !== 'ACTIVE') {
    notify({
      type: 'error',
      message: "Email",
      description: `E-mail template with name ${templateName} is INACTIVE`,
      placement: 'top',
    })
    return false;
  }

  const templateOptions = {
    interpolate: /\${([\s\S]+?)}/g
  };

  let email;
  try {
    email = {
      subject: _.template(emailTemplate.subject, templateOptions)(context),
      to: _.template(emailTemplate.to, templateOptions)(context),
      cc: _.template(emailTemplate.cc, templateOptions)(context),
      bcc: _.template(emailTemplate.bcc, templateOptions)(context),
      body: _.template(emailTemplate.body, templateOptions)(context),
    }
  } catch (e) {
    showException(e);
    return false;
  }

  let response;
  try {
    response = await api('email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(email)
    });
  } catch (e) {
    showException(e);
    return false;
  }
  if (response.ok) {
    return true;
  } else {
    const e = await response.text();
    showException(e);
    return false;
  }
}

const useMyForms = (ref) => {
  const forms = {};
  useImperativeHandle(ref, () => ({
    ...ref?.current,
    getErrors: () => {
      return Object.values(forms).filter(f => f.error != null) || [];
    },
    warnings: () => {
      return Object.values(forms).filter(f => f.warning != null) || [];
    },
    getForms: () => {
      return forms;
    }
  }));
  return (formName) => {
    return (formRef) => {
      forms[formName] = formRef;
    }
  }
}

export const sql = {
  escape: (_type, value) => {
    if (value == null) {
      return null;
    }
    if (_type === type.Uuid) {
      return `'${value.replace(/'/ig,"''")}'`;
    } else if (_type === type.Text) {
      return `'${value.replace(/'/ig,"''")}'`;
    } else if (_type === type.Number || _type === type.Id) {
      return value;
    } else if (_type === type.DateTime) {
      return `'${value.toISOString()}'`;
    } else if (_type === type.Json) {
      return `'${value.replace(/'/ig,"''")}'`;
    }
  },  
  dateTimeToWhere: (column, _type, value) => {
    const bestDateMatch = (text) => {
      const datesRegexp = [
        /^(?<year>\d{4})(-(?<month>\d{1,2}))?(-(?<day>\d{1,2}))?(\s+(?<hours>\d{1,2}))?(:(?<minutes>\d{1,2}))?(:(?<seconds>\d{1,2}))?(\.(?<milliseconds>\d{1,3}))?/,   // yyyy-MM-dd HH:mm:ss.SSS
        /^(?<day>\d{1,2})(-(?<month>\d{1,2}))?(-(?<year>\d{4}))?(\s+(?<hours>\d{1,2}))?(:(?<minutes>\d{1,2}))?(:(?<seconds>\d{1,2}))?(\.(?<milliseconds>\d{1,3}))?/,   // dd-MM-yyyy HH:mm:ss.SSS
        /^(?<year>\d{4})(\/(?<month>\d{1,2}))?(\/(?<day>\d{1,2}))?(\s+(?<hours>\d{1,2}))?(:(?<minutes>\d{1,2}))?(:(?<seconds>\d{1,2}))?(\.(?<milliseconds>\d{1,3}))?/, // yyyy/MM/dd HH:mm:ss.SSS
        /^(?<day>\d{1,2})(\/(?<month>\d{1,2}))?(\/(?<year>\d{4}))?(\s+(?<hours>\d{1,2}))?(:(?<minutes>\d{1,2}))?(:(?<seconds>\d{1,2}))?(\.(?<milliseconds>\d{1,3}))?/, // dd-MM-yyyy HH:mm:ss.SSS
      ];
      let match = null;
      let groups = 0;
      for (const regexp of datesRegexp) {
        const m = text.match(regexp);
        if (m == null || m.groups == null){
          continue;
        }
        const g = m.filter(e => e != null).length;
        if (match == null || g > groups) {
          match = m;
          groups = g;
        }
      }
      return match;
    }
    const calcDate = (text) => {
      const match = bestDateMatch(text);
      if (match == null || match.groups == null){
        ;
      }
      let { year, month, day, hours, minutes, seconds, milliseconds} = match.groups;
      month = month-1;
      const start = new Date(year, month || 0, day || 1, hours || 0, minutes || 0, seconds || 0, milliseconds || 0);
      return start;
    }
    const calcDateInterval = (text) => {
      const match = bestDateMatch(text);
      if (match == null || match.groups == null){
        return { start: undefined, end: undefined };
      }
      let { year, month, day, hours, minutes, seconds, milliseconds} = match.groups;
      month = month-1;
      const start = new Date(year, month || 0, day || 1, hours || 0, minutes || 0, seconds || 0, milliseconds || 0);
      const end = new Date(year, month || 11, day || 0, hours || 23, minutes || 59, seconds || 59, milliseconds || 999);
      if (day === undefined){
        end.setMonth(end.getMonth()+1);
        end.setDate(end.getDate()-1);
      }
      return ({start, end});
    }
    if (value.startsWith('!=')) {
      value = value.substring(2);
      value = calcDate(value);
      return `(${column} <> ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('=')) {
      value = value.substring(1);
      value = calcDate(value);
      return `(${column} = ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('>=')) {
      value = value.substring(2);
      value = calcDate(value);
      return `(${column} >= ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('<=')) {
      value = value.substring(2);
      value = calcDate(value);
      return `(${column} <= ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('>')) {
      value = value.substring(1);
      value = calcDate(value);
      return `(${column} > ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('<')) {
      value = value.substring(1);
      value = calcDate(value);
      return `(${column} < ${sql.escape(_type, value)})`;
    }
    const {start, end} = calcDateInterval(value);
    return `(${column} between ${sql.escape(_type, start)} and ${sql.escape(_type, end)})`;
  },
  numberToWhere: (column, _type, value) => {
    if (value.startsWith('!=')) {
      value = value.substring(2);
      return `(${column} <> ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('=')) {
      value = value.substring(1);
      return `(${column} = ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('>=')) {
      value = value.substring(2);
      return `(${column} >= ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('<=')) {
      value = value.substring(2);
      return `(${column} <= ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('>')) {
      value = value.substring(1);
      return `(${column} > ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('<')) {
      value = value.substring(1);
      return `(${column} < ${sql.escape(_type, value)})`;
    }
    return `(${column} = ${sql.escape(_type, value)})`;
  },
  booleanToWhere: (column, _type, value) => {
    value = value.toLowerCase();
    if (['true','1', 'yes', 'y', 't'].includes(value)) {
      return `(${column} = true)`;
    }
    if (['false','0', 'no', 'n', 'f'].includes(value)) {
      return `(${column} = false)`;
    }
  },
  UuidToWhere: (column, _type, value) => {
    if (value.startsWith('!=')) {
      value = value.substring(2);
      return `(${column} <> ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('=')) {
      value = value.substring(1);
      return `(${column} = ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('>=')) {
      value = value.substring(2);
      return `(${column} >= ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('<=')) {
      value = value.substring(2);
      return `(${column} <= ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('>')) {
      value = value.substring(1);
      return `(${column} > ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('<')) {
      value = value.substring(1);
      return `(${column} < ${sql.escape(_type, value)})`;
    }
    return `(${column} = ${sql.escape(_type, value)})`;
  },
  textToWhere: (column, _type, value) => {
    if (value.startsWith('!=')) {
      value = value.substring(2);
      return `(${column} <> ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('=')) {
      value = value.substring(1);
      return `(${column} = ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('>=')) {
      value = value.substring(2);
      return `(${column} >= ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('<=')) {
      value = value.substring(2);
      return `(${column} <= ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('>')) {
      value = value.substring(1);
      return `(${column} > ${sql.escape(_type, value)})`;
    } else 
    if (value.startsWith('<')) {
      value = value.substring(1);
      return `(${column} < ${sql.escape(_type, value)})`;
    } else 
    if (value.includes('*') || value.includes('_')) {
      value = value.replace(/\*/ig,'%');
      return `(${column} ilike ${sql.escape(_type, value)})`;
    }
    value = `%${value}%`;
    return `(${column} ilike ${sql.escape(_type, value)})`;
  },
  jsonToWhere: (column, _type, value) => {
    value = value.toLowerCase();
    return `(${column} ? ${sql.escape(_type, value)})`;
  },
  toWhere: (column, _type, value) => {
    if (value === undefined) {
      return undefined;
    }
    value = text.compact(value);
    if (value === 'null' || value === null) {
      return `(${column} is null)`;
    }
    if (value === '!=null') {
      return `(${column} is not null)`;
    }
    if (_type === type.Uuid) {
      return sql.UuidToWhere(column, _type, value);
    } else if (_type === type.Text) {
      return sql.textToWhere(column, _type, value);
    } else if (_type === type.Id || _type === type.Number) {
      return sql.numberToWhere(column, _type, value);
    } else if (_type === type.DateTime) {
      return sql.dateTimeToWhere(column, _type, value);
    } else if (_type === type.Boolean) {
      return sql.booleanToWhere(column, _type, value);
    } else if (_type === type.Json) {
      return sql.jsonToWhere(column, _type, value);
    }
  }
}

export const format = (v, f) => {
  if (v == null) {
    return ''+v;
  }
  if (typeof v === 'number') {
    return v.toString();
  }
  if (typeof v === 'string') {
    const asDate = (str) => {
      if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
      const d = new Date(str); 
      return d instanceof Date && !isNaN(d) && d.toISOString() === str ? d : undefined; // valid date 
    }
    const dt = asDate(v);
    if (dt != null) {
      return format.datetime(dt, f);
    }
  }
  return v;
};
format.currency = v => {
  if (typeof v !== 'number') {
    v = Number.parseFloat(v);
  }
  if (isNaN(v)) {
    return '-.-- $';
  }
  return v.toFixed(2) + ' $';
};

format.boolean = v => {
  return v == true ? 'Y' : 'N';
}

format.percent = v => {
  if (typeof v !== 'number') {
    v = Number.parseFloat(v);
  }
  if (isNaN(v)) {
    return '-.--- %';
  }
  return v.toFixed(3) + ' %';
}
format.datetime = (datetime, format) => {
  if (datetime == null) {
    return '';
  }
  if (typeof datetime === 'string' || datetime instanceof Date) {
    datetime = dayjs(datetime);
  }
  if (format == null) {
    format = 'l';
    const user = useStore.getState().user;
    if (user != null) {
      const person = user.person;
      if (person != null) {
        const dateformat = person.date_format || 'l';
        const timeformat = person.time_format || 'LT';
        const separator = person.date_time_separator || ' ';
        format = `${dateformat}${separator}${timeformat}`;
      }
    }
  }
  return datetime.format(format);
}
format.date = (date) => {
  if (date == null) {
    return '';
  }
  if (typeof date === 'string') {
    date = dayjs(date)
  } else if (date instanceof Date) {
    date = dayjs(date);
  }
  let format = 'l';
  const user = useStore.getState().user;
  if (user != null) {
    const person = user.person;
    if (person != null) {
      format = person.date_format || 'l';
    }
  }
  return date.format(format);
}
format.time = (time) => {
  if (time == null) {
    return '';
  }
  if (time instanceof Date) {
    time = dayjs(time);
  }
  let format = 'l';
  const user = useStore.getState().user;
  if (user != null) {
    const person = user.person;
    if (person != null) {
      format = person.time_format || 'LT';
    }
  }
  return time.format(format);
}


export const openFile = (blob) => {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.target="_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const timers = {
  start: startTimer, 
  stop: stopTimer
}


export const evalMath = (expression, scope = {}, previous = undefined) => {
  if (expression == null) {
    return previous;
  }
  expression = expression.toString();
  try {
    const value = math.evaluate(expression, scope);
    if (isNaN(value)) {
      throw Error();
    }
    return value
  } catch (e) {
    console.error(`Error evaluating math expression: ${expression}`)
    return previous;
  }
}

export const uuid = () => {
  return uuidv7();
}


// ORM NOW ON

const escapeTableName = (tableName) => {
  return `"${tableName}"`;
}

const escapeUuid = (uuid) => {
  if (uuid == null) {
    return null;
  }
  return `'${uuid}'`;
}

export const model = {

};

const preloadRowValues = async (sql, values) => {
  const r = /(\$\w{1}\S*)/ig;
  const matches = sql.matchAll(r);
  for (const match of matches){
    const prop = match[0].substring(1);
    values[prop];
  }
}

export const type = {
  Transaction: function() {
    if (!(this instanceof type.Transaction)) {
      return new type.Transaction();
    }
  
    this.transactions = {};
    
  
    this.add = (transactable) => {
      if (transactable == null) {
        return;
      }
      if (transactable instanceof type.Row) {
        const id = transactable.id;
        this.transactions[id] = transactable;
      }
      if (Array.isArray(transactable)) {
        transactable.forEach(trx => {
          this.add(trx);
        });
        return;
      }
    }
  
    this.save = async () => {
      const trxs = Object.values(this.transactions);
      if (trxs.length === 0) {
        return;
      }
      
      const trxObjects = [];
      trxs.forEach(trx => {
        if (trx.meta.insert === true && trx.meta.delete === true) {
          return;
        }
        const trxObj = {};
        if (trx.meta.insert === true && trx.meta.inserted !== true) {
          trxObj.insert = trx.meta.config.table;
          trxObj.id = trx.id;
          trxObj.stamp = trx.stamp;
        } else if (trx.meta.delete === true && trx.meta.insert !== true) {
          trxObj.delete = trx.meta.config.table;
          trxObj.id = trx.id;
          trxObj.stamp = trx.stamp;
        } else if (trx.meta.update === true) {
          trxObj.update = trx.meta.config.table;
          trxObj.id = trx.id;
          trxObj.stamp = trx.stamp;
        } else {
          return;
        }

        Object.entries(trx.meta.changed).forEach(([k, v]) => {
          if (v !== true) {
            return;
          }
          const columnType = trx.meta.config.columns[k];
          if (columnType == null) {
            return;
          }
          if (columnType instanceof type.Attribute) {
            if (columnType.persistent === false) {
              return;
            }
          }
          trxObj[k] = columnType.toTransaction(trx[k]);
        })
        trxObjects.push({
          trx,
          sql: trxObj,
        });
      });

      // correr todos os beforeSaves
      for (const t of trxObjects) {
        if (typeof t.trx.meta.beforeSave === 'function') {
          t.trx.meta.beforeSave(t.trx);
        }
      }
  
      if (trxObjects.length > 0) {
        const response = await orm(trxObjects.map(t => t.sql));
      }
  
      // incrementar os stamps todos, atualizar flags, atualizar dbvalues
      trxObjects.forEach(t => {
        const trx = t.trx;
        if (trx.meta.insert != true) {  // incremente o stamp quando é update
          trx.stamp++; 
        }
        
        Object.entries(trx.meta.changed).forEach(([k, v]) => {
          if (v !== true) {
            return;
          }
          const columnType = trx.meta.config.columns[k];
          if (columnType == null) {
            return;
          }
          if (columnType instanceof type.Attribute) {
            if (columnType.persistent === false) {
              return;
            }
          }
          trx.meta.changed = {};
          trx.meta.dbValues[k] = _.cloneDeep(trx[k]);
        });
  
        // reset às flags
        if (trx.meta.delete === true) {
          delete trx.meta.delete;
          trx.meta.deleted = true;
        }
        if (trx.meta.insert === true) {
          delete trx.meta.insert;
          trx.meta.inserted = true;
        }
        delete trx.meta.update;
          
      });

      // correr todos os after saves
      for (const t of trxObjects) {
        if (typeof t.trx.meta.afterSave === 'function') {
          t.trx.meta.afterSave(t.trx);
        }
      }
      
      return trxObjects.map(t => t.trx);
    }
  },
  Table: function(config) {
    if (!(this instanceof type.Table)) {
      return new type.Table(config);
    }
    
    this.name = config.name;
    this.table = config.table;
    this.config = config;
    
    // as tabelas teem sempre um id e stamp
    if (this.config.columns) {
      config.columns.id = type.Uuid({
        title: 'Id'
      });
      this.config.columns.stamp = type.Stamp({
        title: 'Stamp'
      });
    }

    // dar forma a que as colunas saibam o proprio nome;
    Object.entries(this.config.columns).forEach(([k, v]) => { v['name'] = k; v['table'] = this; });

    this.from = (dbValues) => {
      return type.Row(config, dbValues);
    }

    this.get = async (id) => {
      if (id == null) {
        return undefined;
      }
      //const idType = config.columns['id'];
      const response = await orm({
        select: `select * from ${escapeTableName(config.table)} where id = ${escapeUuid(id)}`
      });
      if (response.length === 1) {
        return this.from(response[0]);
      }
      return undefined;
    }
  
    this.select = async (query, values) => {
      if (values instanceof type.Row) {
        await preloadRowValues(query, values);
      }
      let rows = await orm({
        select: `select * from ${escapeTableName(config.table)} where ${query}`,
        values
      });
      return rows.map(r => {
        return this.from(r);
      });
    }
  
    this.selectAll = async () => {
      let rows = await orm({
        select: `select * from ${escapeTableName(config.table)}`
      });
      rows = rows.map(r => {
        return this.from(r);
      })
      return rows;
    }
  
    this.selectFirst = async (query, values) => {
      if (values instanceof type.Row) {
        await preloadRowValues(query, values);
      }
      const rows = await orm({
        select: `select * from ${escapeTableName(config.table)} where ${query}`,
        values,
      });
      if (rows.length > 0) {
        return this.from(rows[0]);
      }
      return;
    }
  
    this.create = (init) => {
      const newRow = this.from({
        id: (init && init.id) || uuidv7(),
        stamp: (init && init.stamp) || 0,
      });
      newRow.meta.insert = true;
      Object.entries(config.columns).forEach(([k, v]) => {
        if (k === 'id' || k === 'stamp' || k === 'uuid') {
          return;
        }
        let value = null;
        if (v.defaultsTo !== undefined) {
          if (typeof v.defaultsTo === 'function') {
            value = v.defaultsTo();
          } else {
            value = v.defaultsTo;
          }
        }
        newRow[k] = value;
      });
      if (init != null) {
        Object.entries(init).forEach(([k, v]) => {
          newRow[k] = v;
        });
      }
      return newRow;
    }

    model[config.name] = this;
  },
  Uuid: function(options) {
    if (!(this instanceof type.Uuid)) {
      return new type.Uuid(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;
  
    this.toTransaction = (value) => {
      if (value == null) {
        return null;
      }
      if (typeof value !== 'string') {
        value = value.toString();
      }
      return value;
    }
    
    if (options == null) {
      return;
    }
  },
  Id: function(options) {
    if (!(this instanceof type.Id)) {
      return new type.Id(options);
    }
    Object.assign(this, type.Number(options));
    this.__proto__.__proto__ = type.Number.prototype;
  },
  Stamp: function(options) {
    if (!(this instanceof type.Stamp)) {
      return new type.Stamp(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;

    this.toTransaction = (value) => {
      if (value == null) {
        return null;
      }
      return value;
    }
  },
  Boolean: function(options) {
    if (!(this instanceof type.Boolean)) {
      return new type.Boolean(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;
    
    this.toTransaction = (value) => {
      if (value == null) {
        return null;
      }
      return value == true ? true : false;
    }
  },
  File: function(options) {
    if (!(this instanceof type.File)) {
      return new type.File(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;

    this.toTransaction = (value) => {
      if (value == null || (Array.isArray(value) && value.length === 0)) {
        return null;
      }
      return JSON.stringify(value);
    }
  },
  Text: function(options = {}) {
    if (!(this instanceof type.Text)) {
      return new type.Text(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;
    // converte objecto para string
    
    
    this.trim = options.trim === false ? false : true;
    this.nullWhenEmpty = options.nullWhenEmpty === false ? false : true;

    this.onChange.push((value, row) => {
      if (value == null) {
        return value;
      }
      if (typeof value !== 'string') {
        value = value.toString();
      }
      if (this.trim !== false) {
        value = value.trim();
      }
      if (this.nullWhenEmpty === true && value === '') {
        value = null;
      }
      return value;
    });

    this.toTransaction = (value) => {
      if (value == null) {
        return null;
      }
      if (typeof value !== 'string') {
        value = value.toString();
      }
      return value;
    }
  },
  Upper: function(options) {
    if (!(this instanceof type.Upper)) {
      return new type.Upper(options);
    }
    Object.assign(this, type.Text(options));
    this.__proto__.__proto__ = type.Attribute.prototype;

    this.onChange.push((value, row) => {
      if (value == null) {
        return null;
      }
      return value.toUpperCase();
    });

  },
  Lower: function(options) {
    if (!(this instanceof type.Lower)) {
      return new type.Lower(options);
    }
    Object.assign(this, type.Text(options));
    this.constructor.prototype = type.Text.prototype;

    this.onChange.push((value, row) => {
      if (value == null) {
        return null;
      }
      return value.toLowerCase();
    });
  },
  Number: function(options) {
    if (!(this instanceof type.Number)) {
      return new type.Number(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;

    this.toTransaction = (value) => {
      if (value == null) {
        return null;
      }
      return value;
    }
  },
  Json: function(options) {
    if (!(this instanceof type.Json)) {
      return new type.Json(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;

    this.toTransaction = (value) => {
      if (value == null) {
        return null;
      }
      return JSON.stringify(value);
    }
  },
  Integer: function(options) {
    if (!(this instanceof type.Integer)) {
      return new type.Integer(options);
    }
    Object.assign(this, type.Number(options));
    this.__proto__.__proto__ = type.Attribute.prototype;
  },
  Date: function(options) {
    if (!(this instanceof type.Date)) {
      return new type.Date(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;

    this.toTransaction = (value) => {
      if (value == null) {
        return null;
      }
      if (dayjs.isDayjs(value) || value instanceof Date) {
        value = value.toISOString().split('T')[0]
      }
      return value;
    }

  },
  DateTime: function(options) {
    if (!(this instanceof type.DateTime)) {
      return new type.DateTime(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;

    this.onInitialize.push((value, row) => {
      if (value == null) {
        return value;
      }
      return dayjs(value);
    });

    this.onChange.push((value, row) => {
      if (value == null) {
        return null;
      }
      return dayjs(value);
    });

    this.toTransaction = (value) => {
      if (value == null) {
        return null;
      }
      if (dayjs.isDayjs(value) || value instanceof Date) {
        value = value.toISOString()
      }
      return value;
    }
    

  },
  Duration: function(options) {
    if (!(this instanceof type.Duration)) {
      return new type.Duration(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;
    
    this.toTransaction = (value) => {
      if (value == null) {
        return null;
      }
      return value;
    }
  },
  Cron: function(options) {
    if (!(this instanceof type.Cron)) {
      return new type.Cron(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;
    
    this.toTransaction = (value) => {
      if (value == null) {
        return null;
      }
      return value;
    }
  },
  Relation: function(config = {}) {
    if (!(this instanceof type.Relation)) {
      return new type.Relation(config);
    }
    this.to = config.to;
    this.cardinality = config.cardinality;
    this.where = config.where;
  },
  Row: function(config, dbValues) {
    if (!(this instanceof type.Row)) {
      return new type.Row(config, dbValues);
    }
  
    this.meta = {
      dbValues,
      config,
      initialized: {},
      changed: {},
    }
    this.id = dbValues.id;
    this.stamp = dbValues.stamp;

    this.toJSON = () => {
      for (const column in this.meta.config.columns) {
        this[column];
      }
      return this;
    }

    this.getValue = (row, proxedRow, prop, receiver) => {
      //console.log('getValue.'+prop);
      const columnType = row.meta.config.columns[prop];
      if (columnType == null) {
        return row[prop];
      }
      if (row.meta.initialized[prop] === true) {
        return row[prop];
      }

      let value = _.cloneDeep(row.meta.dbValues[prop]);
      if (columnType instanceof type.Attribute){
        // initializing
        for (const onInitialize of columnType.onInitialize) {
          value = onInitialize.call(null, value, proxedRow);
        }
        if (!equals(value, row.meta.dbValues[prop])) {
          this.meta.changed[prop] = true;
          if (row.meta.insert !== true) {
            row.meta.update = true;
          }
        }
      }
      if (columnType instanceof type.Relation) {
        const to = model[columnType.to];
        if (['?', '1'].includes(columnType.cardinality)) {
          value = to.selectFirst(columnType.where, proxedRow);
        } else {
          value = to.select(columnType.where, proxedRow);
        }
      }

      row[prop] = value;

      row.meta.initialized[prop] = true;
      return value;
    },
    this.setValue = (row, proxedRow, prop, value) => {
      //console.log('setValue.'+prop+' = '+value+'; old value = ' + row.meta.dbValues[prop] + ', isEquals=' + _.isEqual(value, row.meta.dbValues[prop]));
      
      if (prop === 'delete') {
        if (value === true) {
          row.meta.delete = true;
        } else {
          delete row.meta.delete;
        }
        return true;
      }
  
      
      const columnType = row.meta.config.columns[prop];
      if (columnType == null) {
        row[prop] = value;
        return true;
      }
  
      // initializing
      if (row.meta.initialized[prop] !== true) {
        proxedRow[prop];
      }
      
      // on Change
      if (columnType instanceof type.Attribute) {
        for (const onChange of columnType.onChange) {
          value = onChange.call(null, value, proxedRow);
        }

        if (!equals(value, row.meta.dbValues[prop])) {
          row.meta.changed[prop] = true;
          if (row.meta.insert !== true) {
            row.meta.update = true;
          }
        }
      }

      row[prop] = value;

      // atualiza as subscriptions
      if (row != null && row.meta != null && row.meta.subscriptions != null && row.meta.subscriptions[columnType.name]) {
        Object.values(row.meta.subscriptions[columnType.name]).forEach(v => v(value));
      }
      
      return true;
    }
  
    this.save = async () => {
      const trx = type.Transaction();
      trx.add(this);
      await trx.save();
    }
  

    this.clone = (deep = true) => {
      const cloned = _.cloneDeep(this)

      cloned.toJSON = this.toJSON.bind(cloned);
      cloned.save = this.save.bind(cloned);
      cloned.getValue = this.getValue.bind(cloned);
      cloned.setValue = this.setValue.bind(cloned);
      return this;
    }


    /*
    Object.defineProperty(this, 'changed', { enumerable: false, writable: false });
    Object.defineProperty(this, 'initialized', { enumerable: false, writable: false });
    */
    Object.defineProperty(this, 'setValue', { enumerable: false, writable: false });
    Object.defineProperty(this, 'getValue', { enumerable: false, writable: false });  
    Object.defineProperty(this, 'meta', { enumerable: false, writable: false });
  
    let proxy = new Proxy(this, {
      get: (target, prop, receiver) => {
        return target.getValue(this, proxy, prop, receiver);
      },
      set: (target, prop, value) => {
        return target.setValue(this, proxy, prop, value);
      }
    });
    return proxy;
  },
  Attribute: function(options = {}) {
    if (!(this instanceof type.Attribute)) {
      return new type.Attribute(options);
    }
    this.name = options.name;
    this.title = options.title;
    this.persistent = options.persistent;
    this.description = options.description;
    this.values = options.values;

    this.onInitialize = [];
  
    if (Array.isArray(options.onInitialize)) {
      this.onInitialize = this.onInitialize.concat(options.onInitialize);
    } else if (typeof options.onInitialize === 'function') {
      this.onInitialize.push(options.onInitialize);
    }
    
    this.onChange = [];
    if (Array.isArray(options.onChange)) {
      this.onChange = this.onChange.concat(options.onChange);
    } else if (typeof options.onChange === 'function') {
      this.onChange.push(options.onChange);
    }
 
    this.defaultsTo = options.defaultsTo;

    this.toTransaction = (value) => {
      if (value == null) {
        return 'null';
      }
      return value;
    }

  }, 
  Id: function(options) {
    if (!(this instanceof type.Id)) {
      return new type.Id(options);
    }
    Object.assign(this, type.Attribute(options));
    this.__proto__.__proto__ = type.Attribute.prototype;
  },
  Error: function(options) {
    if (!(this instanceof type.Error)) {
      return new Error(options);
    }
    Object.assign(this, new Error(options));
    this.__proto__.__proto__ = Error.prototype;
  }
}

export const now = () => {
  return dayjs();
}

export const image = {
  preload: (src) => {
    if (src == null) {
      return null;
    }
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = function() {
        resolve(img)
      }
      img.onerror = img.onabort = function() {
        reject(src)
      }
      img.src = src
    })
  },
  url: (src, resize) => {
    if (src == null) {
      return src;
    }
    let url = '/s3/' + src;
    if (resize != null && (resize.width != null || resize.height != null || resize.quality != null)) {
      let params = [];
      if (resize.width != null && resize.width !== '100%') {
        const width = parseInt(resize.width);
        if (!isNaN(width) && width !== 0) {
          params.push(`width=${width}`);
        }
      }
      if (resize.height != null && resize.height !== '100%') {
        const height = parseInt(resize.height);
        if (!isNaN(height) && height !== 0) {
          params.push(`height=${height}`);
        }
      }
      if (resize.quality != null) {
        params.push(`quality=${parseFloat(resize.quality).toFixed(2)}`);
      }
      if (params.length > 0) {
        url += '?' + params.join('&');
      }
    }
    return url;
  }
}

const timeToMs = (time) => {
  let offset = 0;
  if (isEmpty(time) 
    || time === '00' || time === '00:00' || time === '00:00:00' 
    || time === '-00' || time === '-00:00' || time === '-00:00:00') {
    return 0;
  }

  let negative = false;
  if (time.startsWith('-')) {
    negative = true;
    time = time.substring(1);
  }
  
  const parts = time.split(':');
  if (parts.length === 3) {
    offset = parseInt(parts[0]) * 3600 + parseInt(parts[0]) * 60 + parseInt(parts[2]);
  } else if (parts.length === 2) {
    offset = parseInt(parts[0]) * 60 + parseInt(parts[1]);
  } else if (parts.length === 1) {
    offset = parseInt(parts[0]);
  }
  return (negative ? -offset : offset) * 1000;
}

const screen = {
  getOrientation: () => {
    if (window.innerHeight > window.innerWidth) {
      return 'portrait';
    }
    return 'landscape';
  },
  isPortrait: () => {
    return screen.getOrientation() === 'portrait';
  },
  isLandscape: () => {
    return screen.getOrientation() === 'landscape';
  },
  isMobile: () => {
    return screen.isPortrait()
  }
}

export default {
  image,
  clone,
  models: model,
  now,
  type,
  uuid,
  evalMath,
  format,
  notify,
  useMyForms,
  combineDateTime,
  extractDate,
  sendEmail,
  login,
  onLogin,
  onLogout,
  capitalize,
  timers,
  openFile,
  download,
  api: api,
  text,
  db,
  sql,
  isEmpty,
  logout,
  sleep,
  refreshAccessToken,
  refreshUserFromToken,
  orm,
  toFileName,
  host,
  uploads,
  upload,
  timeToMs,
  screen,
  getUser: () => {
    return useStore.getState().user
  },
  getPerson: () => {
    return useStore.getState().person
  },
}