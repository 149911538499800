
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Card, Checkbox, Form, Layout, Row, Col, Typography, Tag, Space, Transfer, Tabs, theme, List, Menu, Input, Dropdown, Collapse, Select, InputNumber  } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import useAsyncEffect from 'use-async-effect';
import TagModel from '../models/Tag';
import {
  useNavigate,
  Link
} from "react-router-dom";

import { DownOutlined } from '@ant-design/icons'
const Component = (props) => {

  let value = props.value;
  let splitted = [];
  if (typeof value === 'string') {
    splitted = value.split('-');
  }

  return (
    <Tag
      style={{
        display: 'inline-block',
        cursor: 'pointer',
        verticalAlign: 'middle',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: "'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace",
        lineHeight: '2.4ch',
        fontWeight: 'lighter',
        paddingTop: 2,
        paddingBottom: 2,
        paddingRight: 6,
        paddingLeft: 6,
        marginRight: 10,
        marginLeft: 10,
      }}
      onClick={() => {
        const win = window.open(props.link, '_blank');
        win.focus();
      }}
    >
      <div>
        {splitted[0]}-{splitted[1]}-{splitted[2]}
      </div>
      <div>
        {splitted[3]}-{splitted[4]}
      </div>    
    </Tag>
    )
  };

  
Component.displayName = 'UUid';
export default Component;