import { type } from '../system.js';
const { Text, Integer, Table, Uuid, DateTime, Relation, Json, Number, Boolean } = type;
import sys from './../system.js'

export default Table({
    name: 'Annotation',
    table: 'annotation',
    columns: {
        name: Text({
            onChange: (value, row) => {
                if (value == null || value.trim() === '') {
                    return 'UNDEFINED';
                }
                return value;
            }
        }),
        confidence: Number({
            title: 'Confidence',
            defaultsTo: 0,
        }),
        type: Text({
            title: 'Type',
        }),
        points: Json({
            title: 'Points'
        }),
        visible: Boolean({
            defaultsTo: true,
        }),
        locked: Boolean({
            defaultsTo: false,
        }),
        model_id: Uuid(),
        model: Relation({
            to: 'Model',
            cardinality: '1',
            where: "id = $model_id"
        }),
        image_id: Uuid(),
        created_by: Uuid({
            defaultsTo: () => sys.getUser()?.id
        }),
        image: Relation({
            to: 'Image',
            cardinality: '1',
            where: '$image_id = id'
        }),
        reviews: Relation({
            to: 'Review',
            cardinality: '*',
            where: "annotation_id = $id"
        })
    }
});
