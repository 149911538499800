import sys, { type } from '../system';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = type;


export default Table({
    name: 'Source',
    table: 'source',
    columns: {
        name: Text({
            title: 'Name',
            onChange: (value, row) => {
                if (value != null) {
                   value = value.replace(/[^a-z0-9\-\._]/ig,'');
                }
                return value;
            }
        }),
        description: Text({
            title: 'Description'
        }),
        config: Json({
            title: 'Config'
        }),
        status: type.Text({
            defaultsTo: 'ACTIVE',
            values: ['ACTIVE', 'INACTIVE']
        }),
        created_at: DateTime({
            title: 'Created at',
            defaultsTo: () => {
                return sys.now();
            }
        }),
        config: Json(),
    }
})