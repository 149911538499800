import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, Button, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate 
} from "react-router-dom";
import { useStore } from '../store';

import Table from './../components/Table';
import PageHeader from '../components/PageHeader'
import PersonForm from '../forms/Person';
import sys from '../system'


const Component = () => {
  const table = useRef();
  const personForm = useRef();
  const [personId, setPersonId] = useState();


  return (
    <div>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>      
          <Typography.Title style={{margin: 0}} level={5} >List of Users</Typography.Title>
        </div>
      </PageHeader>
      <Table
        ref={table}
        title="Users"
        query={{
          count: 'select count(*) from person',
          select: `
            select 
              * 
            from 
              person
          `,
          limit: 20,
        }}
        columns={[
          { 
            title: 'Name',
            name: 'display_name', 
            type: sys.type.Text
          },
          { 
            title: 'First Name',
            name: 'first_name', 
            type: sys.type.Text,
            width: '270px',
          },
          { 
            title: 'Last Name', 
            name: 'last_name',
            type: sys.type.Text,
            width: '270px',
          },
          { 
            title: 'Status', 
            name: 'status',
            sorter: true,
            type: Text,
            width: '140px',
          },
          {
            title: 'Created At',
            name: 'created_at',
            sorter: true,
            type: sys.type.DateTime,
          },
          {
            title: 'Actions',
            width: '300px',
            render: (record) => {
              return <div>
                <Button type='link' onClick={() => {
                  setPersonId(record.id);
                }}>Edit</Button>
              </div>
            }
          }
        ]}
        footer={
          <Button type='primary' onClick={() => setPersonId(null)}>New User</Button>
        }
      />

    <Modal 
      title="Person"
      open={personId !== undefined} 
      width={'800px'}
      onOk={async () => {
        await personForm.current.save();
        setPersonId(undefined);
        table.current.refresh();
      }}
      onCancel={() => {
        setPersonId(undefined);
        table.current.refresh();
      }}
      destroyOnClose={true}
    >
      <PersonForm ref={personForm} id={personId}></PersonForm>
    </Modal>
    </div>
  );
};
export default Component;