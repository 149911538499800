import React, { useState, useRef, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Form, Skeleton, Checkbox, Button, Modal, Tag, theme, Space, Alert, Menu, Row, Col, Badge, Card, Spin    } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined  } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate
} from "react-router-dom";
import { useStore } from '../store';

import Table from '../components/Table';

import Input from '../components/Input';

import ImageClass from './../components/ImageAnnotator/ImageClass';
import ImageTags from './../components/ImageTags';
import ImageViewer from './../components/ImageViewer3';
import sys from '../system'
import ImageModel from '../models/Image'

import AppHeader from '../components/AppHeader'

import PageHeader from '../components/PageHeader'
const { DateTime, Text, Json } = sys.type; 

const { useToken } = theme;


const UploadModal = (props) => {

  const [images, setImages] = useState([]);

  useAsyncEffect(async () => {
    if (props.open) {
      const image = await ImageModel.create();
      setImages([image]);
    }
  }, [props.open]);

  return (
    <Modal
      title="Upload Image"
      open={props.open}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText="Close"
      onCancel={() => {
        if (typeof props.onCancel === 'function') {
          props.onCancel();
        }
      }}
    >
      {
        images.map((image, i) => {
          return <Input
            key={`image-${i}`}
            type={sys.type.File}
            model={image}
            column='src'
            onChange={async (value) => {
              setImages([...images, await ImageModel.create()]);
            }}
          />
        })
      }
    </Modal>
  )
}

const Component = () => {
  const navigate = useNavigate()
  const tableRef = useRef();
  const [classes, setClasses] = useState([]);
  const { token } = useToken();

  const [showUploadModal, setShowUploadModal] = useState(false);

  return (
    <div>
      <PageHeader>
        <div style={{display: 'flex', alignItems: 'center', height: '100%', gap: 10}}>
          <Typography.Title style={{margin: 0}} level={5} >Images List</Typography.Title>
        </div>
      </PageHeader>
      <Table
        ref={tableRef}
        title="Images"
        scroll={{y: 'calc(100vh - 280px)'}}
        footer={
          <div style={{display: 'flex', gap: 10, justifyContent: 'flex-end'}}>
            <Button
              type='primary'
              onClick={() => setShowUploadModal(true)}
            >
              Upload Image
            </Button>
            <Button
              type='primary'
              onClick={async () => {
                const select = await tableRef.current.getSelect();
                const images = await sys.orm(select);
                const ids = images.map(row => row.id);
                navigate(`/annotate/${ids.join(',')}`)
              }}
            >
              Annotate All
            </Button>
          </div>
        }
        query={{
          select: `
            select * from
              (
                select 
                  image.id,
                  image.width,
                  image.height,
                  image.created_at,
                  source.name source_name,
                  (select json_agg(distinct(name))::text from annotation where image_id = image.id) annotations
                from
                  image
                  join source on (source.id = image.source_id)
                where
                  exists (select * from annotation where image_id = image.id)
                  or exists (select * from tag where ref_id = image.id)
              )
            as image
          `,
          where: {},
          orderBy: { created_at: 'desc' },
          limit: 20,
        }}
        columns={[
          { 
            title: 'Image', 
            name: 'src',
            width: '270px',
            render: (record) => {
              return (
                <div style={{width:240, height:136}}>
                  <ImageViewer 
                    quality={75} 
                    imageId={record.id} 
                    showBoxes={true} 
                    showPolygons={true}
                    showAnnotations={true}
                    enableSettings={false}
                    enableEdit={false}
                  />
                </div>
              )
            },
          },
          { title: 'Classes', name: 'annotations',
            render: (record) => {
              return (
              <Row gutter={[0,8]}>
                { 
                  (JSON.parse(record.annotations) || []).sort().map((t,i) => <ImageClass key={`class-${i}`} name={t}/>)
                }
              </Row>)
            },
            type: Text,
            filter: true,
            options: async () => {
              const rows = await sys.orm('select distinct name from annotation where model_id is null');
              return rows.sort((a, b) => a.name > b.name).map(r => r.name);
            },
            toWhere: (value) => {
              return `exists(select * from annotation where image_id = image.id and ${sys.sql.toWhere('name', Text, value)})`
            }
          },
          { title: 'Tags', name: 'tags',
            render: (record) => <ImageTags style={{maxWidth: '300px'}} id={record.id}/>,
            type: Text,
            filter: true,
            options: async () => {
              const rows = await sys.orm('select distinct name from tag');
              return rows.map(r => r.name);
            },
            toWhere: (value) => {
              return `exists(select * from tag where ref_id = image.id and ${sys.sql.toWhere('name', Text, value)})`
            }
          },
          { 
            title: 'Resolution',
            width: '120px',
            align: 'center',
            mobile: false,
            render: (record) => {
              if (record.width && record.height) {
              return <>
                  <div>{record.width} x {record.height}</div>
                </>
              }
              return null
            }
          },
          { 
            title: 'Source',
            name: 'source_name', 
            type: Text,
            width: '120px',
            align: 'center',
            filter: true,
            sorter: true,
          },
          { 
            title: 'Created At', 
            name: 'created_at', 
            align: 'center',
            type: DateTime,
            filter: true,
            sorter: true,
            mobile: false,
          },
          {
            title: 'Actions',
            width: '180px',
            render: (record) => {
              return (
                <div>
                  <Link to={`/annotate/${record.id}`}>Annotate</Link>
                </div>
              )
            }
          }

        ]}      
      />
      <UploadModal 
        open={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
      />
    </div>
  );
};
export default Component;