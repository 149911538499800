import sys, { type } from '../system';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = type;





export default Table({
    name: 'ProcessRun',
    table: 'process_run',
    columns: {
        process_id: Uuid(),
        process: Relation({
            to: 'Process',
            cardinality: '1',
            where: `id = $process_id`
        }),
        created_at: DateTime(),
        state: Json({
            title: 'State',
            defaultsTo: () => {
                return ({
                    subprocess: [],
                    variables: {},
                });
            }
        }),
        draft_state: Json({
            title: 'Draft State',
            onInitialize: (value, row) => {
                return row.state;
            }
        }),
        carry: Json({
            title: 'Carry',
        }),
        options: Json({
            title: 'Options'
        }),
        start: DateTime(),
        finish: DateTime(),
        events: Relation({
            to: 'ProcessEvent',
            cardinality: '*',
            where: `process_run_id = $id`
        }),
    }
})