import { Button, Card, Checkbox, Form, Layout, Select, Steps, Row, Col, Typography, Space, Transfer, Tabs, List, Menu } from 'antd';
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import useAsyncEffect from 'use-async-effect';

import moment from 'moment';
import _ from 'lodash';
import { useStore }from '../store';

import Input from '../components/Input'

import sys from '../system';

import TicketModel from '../models/Ticket';
import UserModel from '../models/User';

const statusList = ['New', 'Analysis', 'Approved', 'In Progress', 'Complete', 'Closed'];

import UserAvatar from '../components/UserAvatar';
import UserSelect from '../components/UserSelect';

const Component = forwardRef((props, ref) => {
 
  const [status, setStatus] = useState();
  const [ticket, setTicket] = useState();
  const [assign, setAssign] = useState([]);

  useImperativeHandle(ref, () => ({
    save: async () => {
      await ticket.save(); 
    }
  }));
  

  useAsyncEffect(async () => {
    let ticket;
    if (props.id == null) {
      ticket = TicketModel.create();
    } else {
      ticket = await TicketModel.get(props.id);
    }
    setTicket(ticket);
    setAssign(ticket.assign);
    setStatus(statusList.indexOf(ticket.status));
  }, []);

  return (
    <div>
      <Row gutter={10}>
        <Col span={10}>
          <Input model={ticket} column={'description'} required></Input>
        </Col>
        <Col span={3}></Col>
        <Col span={3}>
          <Input model={ticket} column={'urgency'}></Input>
        </Col>
        <Col span={3}>
          <Input model={ticket} column={'impact'}></Input>
        </Col>
        <Col span={3}>
          <Input model={ticket} column={'priority'} disabled></Input>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={5}>
          <div>Created By</div>
          <UserSelect 
            mode='multiple' 
            ids={ticket?.created_by} 
            disabled
          />
        </Col>
        <Col span={5}>
        <div>Assignees</div>
          <UserSelect
            mode='multiple' 
            ids={assign} 
            onChange={(ids) => {
              ticket.assign = ids;
              setAssign(ids);
            }}
          />
        </Col>
        <Col span={3} offset={3}>
          <Input model={ticket} column={'sched_finish'}></Input>
        </Col>
      </Row>
      <Row style={{padding: 40}}>
        <Col span={24}>
          <Steps
            current={status}
            onChange={(value) => {
                ticket.status = statusList[value];
                setStatus(value);
            }}
            items={[{
                title: 'New',
              }, {
                title: 'Analysis',
              }, { 
                title: 'Approved'
              }, {
                title: 'In Progress',
              }, {
                title: 'Complete',
              }, {
                title: 'Closed',
              }
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Input model={ticket} column={'summary'} html style={{height: '50vh', maxHeight: '60vh'}}></Input>
        </Col>
      </Row>
    </div>
  );
});

Component.displayName = 'Component';
export default Component;