import sys, { type } from '../system.js';
const { Table, Uuid, Text, Date, Upper, Number, Relation, Json, DateTime } = type;


export default type.Table({
    name: 'UserToken',
    table: 'user_token',
    columns: {
        user_id: Uuid(),
        user: Relation({
            to: 'User',
            cardinality: '1',
            where: "id = $user_id",
        }),
        token: type.Text({
            title: 'Token'
        }),
        expires_in: type.DateTime({
            title: 'Expires In'
        }),
        issued_at: type.DateTime({
            title: 'Issued At'
        }),
    }
})