import { type } from '../system.js';
const { Text, Integer, Table, Uuid, DateTime, Relation } = type;


export default Table({
    name: 'Review',
    table: 'review',
    columns: {
        result: Text({
            values: ['OK', 'NOT OK', 'CHANGED' ]
        }),
        comment: Text(),
        annotation_id: Uuid(),
        annotation: Relation({
            to: 'Annotation',
            cardinality: '?',
            where: "id = $annotation_id"
        }),
        user_id: Uuid(),
        user: Relation({
            to: 'User',
            cardinality: '?',
            where: "id = $user_id"
        }),
        created_at: DateTime({
            defaultsTo: () => {
                return new Date();
            }
        })
    }
})