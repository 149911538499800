import { type } from '../system.js';
const { Text, Integer, Table, Uuid, DateTime, Relation, Json, Number, Boolean, Upper } = type;
import sys from '../system.js'


export default Table({
    name: 'GeometryStyle',
    table: 'geometry_style',
    columns: {
        visible: Boolean({
            title: 'Visible',
            defaultsTo: true
        }),
        name: Upper({
            title: 'Name',
        }),
        style: Json({
            title: 'Style'
        })
    }
});
